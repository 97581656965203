import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import bannerLogin from "../../assets/black/bannerLogin-peca.png";
import bannerCampannhaPremiada from "../../assets/img/banners/servico-premiado/logo.png";
import ApiServices from "../../services/apiServices";
import Mask from "../../services/mask";
import Utils from "../../services/serviceUtils"; 
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  showMsgS,
  showMsgW,
  showMsgE,
} from "../../store/actions/snackbarActions.js";
import qs from "qs"
import { useHistory } from "react-router-dom";
import verSenha from '../../assets/img/icons/ver.png'
import naoVerSenha from '../../assets/img/icons/naover.png'



function RecuperarSenha(props) {
  const history = useHistory();
  const [revelar1, setRevelar1] = useState(false)
  const [revelar2, setRevelar2] = useState(false)

  const queryString = qs.parse(props.location.search, {
    ignoreQueryPrefix: true

  });
  // const queryparams = new URLSearchParams(window.location.search)
  const [etapaControleRecriar, serEtapaControleRecriar] = useState([
    false,
    false,
    false,
    false,
  ]);
  const [clienteRecriarSenha, setClienteRecriarSenha] = useState({
    email: queryString.email,
    senha: "",
    confirmarSenha: "",
  });

  const [count, setCount] = useState(0);

  const [alertaCampo, setAlertaCampo] = useState({

    confirmarSenha: {
      status: "d-none",
      alerta: "Senha invalida",
    },
  });

  function fnEtapaSenha() {
    if (clienteRecriarSenha.senha.length >= 8) {
      etapaControleRecriar[0] = true;
    } else {
      etapaControleRecriar[0] = false;
    }

    if (clienteRecriarSenha.senha.replace(/[^0-9]/g, "").length > 0) {
      etapaControleRecriar[1] = true;
    } else {
      etapaControleRecriar[1] = false;
    }

    if (
      clienteRecriarSenha.senha.replace(/[^a-z]/g, "").length > 0 &&
      clienteRecriarSenha.senha.replace(/[^A-Z]/g, "").length > 0
    ) {
      etapaControleRecriar[2] = true;
    } else {
      etapaControleRecriar[2] = false;
    }

    if (clienteRecriarSenha.senha.replace(/[a-zA-Z0-9]/g, "").length > 0) {
      etapaControleRecriar[3] = true;
    } else {
      etapaControleRecriar[3] = false;
    }

    var c = 0;
    for (var x = 0; x <= etapaControleRecriar.length; x++) {
      if (etapaControleRecriar[x] == true) c++;
    }
    setCount(c);
  }

  function fnBtConfirmar() {


    if (
      clienteRecriarSenha.senha !== clienteRecriarSenha.confirmarSenha ||
      clienteRecriarSenha.senha.length < 8
    ) {
      setAlertaCampo((prev) => ({
        ...prev,
        confirmarSenha: { status: "", alerta: "Senha inválida" },
      }));
    }

    if (

      clienteRecriarSenha.password === clienteRecriarSenha.confirmPassword &&
      clienteRecriarSenha.senha.length >= 8
    ) {
      const init = {
        userName: queryString.email,
        token: queryString.token,
        senha: clienteRecriarSenha.senha,
      };



      ApiServices.apiPost("participantes/confirmar/senha", init)
        .then((res) => {

          if (res.success == true) { history.push('/') }


        }
        );
    }
  }

  useEffect(() => {
    fnEtapaSenha();
    



  }, [clienteRecriarSenha.senha]);

  function msg(tipo, text) {
    //S W E
    if (tipo == "s") props.showMsgS(text);
    if (tipo == "w") props.showMsgW(text);
    if (tipo == "e") props.showMsgE(text);
  }
  function fnRevelar1() {
    if (revelar1 == false) { setRevelar1(true) }
    if (revelar1 == true) { setRevelar1(false) }
  }
  function fnRevelar2() {
    if (revelar2 == false) { setRevelar2(true) }
    if (revelar2 == true) { setRevelar2(false) }
  }

  return (
    <>
      <div className="container ">
        <div className="row d-flex align-items-center justify-content-space-between">
          <div className="col-md-7 col-sm-12">
            <img className="responsive" src={bannerLogin}></img>
          </div>
 

          <div className="col-md-5 col-sm-12">
            <h4 className="col-12 pad0 mt20">Esqueceu a senha?</h4>

            <label className="mb-0">E-mail</label>
            <input
              type="text"
              className="col-12 form-control"
              id="login"
              maxLength="200"

              value={queryString.email}
              disabled

            />


            <label className="col-12 mb-0 mt-3">Senha</label>
            <input
              type={revelar1 ? 'text' : 'password'}
              className="col-12 form-control"
              id="senha"
              maxLength="200"
              placeholder="Informe a nova senha"
              onChange={(e) => {
                e.persist();
                setClienteRecriarSenha((prev) => ({
                  ...prev,
                  senha: e.target.value,
                }));
              }}
            />


            <div className="olhoVerSenha" onClick={() => fnRevelar1()}>

              {!revelar1 && <img src={verSenha} width='100%' alt='ver senha' />}
              {revelar1 && <img src={naoVerSenha} width='100%' alt='ver senha' />}


            </div>


            <div className="row">
              <p className="col-12 mt-4">
                <strong>Força da sua senha </strong>
              </p>
              <div className="col-12 d-flex justify-content-between">
                <div
                  className={
                    count >= 1 ? "forcaSenha1 forcaSenhaAtiva" : "forcaSenha1"
                  }
                ></div>
                <div
                  className={
                    count >= 2 ? "forcaSenha2 forcaSenhaAtiva" : "forcaSenha2"
                  }
                ></div>
                <div
                  className={
                    count >= 3 ? "forcaSenha3 forcaSenhaAtiva" : "forcaSenha3"
                  }
                ></div>
                <div
                  className={
                    count >= 4 ? "forcaSenha4 forcaSenhaAtiva" : "forcaSenha4"
                  }
                ></div>
              </div>

              <div className="container">

                <br />
                <p className="mb-0">• Conter no mínimo 8 caracteres</p>
                <p className="mb-0 mt-0">• Conter números</p>
                <p className="mb-0 mt-0">
                  • Conter letras maiúsculas e minúsculas
                </p>
                <p className="mt-0">• Conter caracteres especiais</p>
                <br />
              </div>
            </div>

            <label className="mb-0 mt-3">Confirmar senha</label>
            <input
              type={revelar2 ? 'text' : 'password'}
              className="col-12 form-control"
              id="confirmarSenha"
              maxLength="200"
              placeholder="Confirme a nova senha"
              onChange={(e) => {
                alertaCampo.confirmarSenha.status = "d-none";
                e.persist();
                setClienteRecriarSenha((prev) => ({
                  ...prev,
                  confirmarSenha: e.target.value,
                }));
              }}
            />
            <div className="olhoVerSenha" onClick={() => fnRevelar2()}>

              {!revelar2 && <img src={verSenha} width='100%' alt='ver senha' />}
              {revelar2 && <img src={naoVerSenha} width='100%' alt='ver senha' />}


            </div>
            <p className={alertaCampo.confirmarSenha.status}>
              <em>{alertaCampo.confirmarSenha.alerta}</em>
            </p>

            <button
              className="col-12 btn btn-primary mt-5 pad10 "
              onClick={() => {
                fnBtConfirmar();
              }}
            >
              {" "}
              CONFIRMAR{" "}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (store) => ({
  snackbarState: store.snackbarState,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ showMsgS, showMsgW, showMsgE }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RecuperarSenha);
