import React from "react";
import { useState } from "react";
import { Link, Redirect, Redirectc } from "react-router-dom";
import bannerLogin from "../../assets/black/bannerLogin-peca.png";
import bannerCampannhaPremiada from "../../assets/img/banners/servico-premiado/logo.png"; 
import ApiServices from "../../services/apiServices";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import verSenha from '../../assets/img/icons/ver.png'
import naoVerSenha from '../../assets/img/icons/naover.png'
import './Login.scss'
import Storage from "../../services/storage";

import {
  showMsgS,
  showMsgW,
  showMsgE,
} from "../../store/actions/snackbarActions.js";

import { useEffect } from "react";
import { LaptopWindows } from "@material-ui/icons";

function Login(props) {
  const [login, setLogin] = useState([
    {
      userName: "",
      password: "",
    },
  ]);

  const [loginValido, setLoginValido] = useState([]);
  const [usuarioLogado, setUsuarioLogado] = useState([]);
  const [revelar, setRevelar] = useState(false)
  function fnBtEntrar() {
    const init = {
      userName: login.userName,
      password: login.password,
    };

    function postFetch() {
      ApiServices.apiPost("participantes/entrar", init).then((data) => {
        setUsuarioLogado(data);

        if (data.success === false) {
          msg("w", "Usuário ou senha inválidos");
        } else {
          msg("s", "Conectado com sucesso");
          Storage.set("DEVP_USER_INFOS", data.data);
          window.location.reload();
        }
      });
    }
    postFetch();
  }

  function msg(tipo, text) {
    //S W E
    if (tipo == "s") props.showMsgS(text);
    if (tipo == "w") props.showMsgW(text);
    if (tipo == "e") props.showMsgE(text);
  }
  function fnRevelar() {
    if (revelar == false) { setRevelar(true) }
    if (revelar == true) { setRevelar(false) }
  }
  return (
    <>
      <div className="container ">
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-md-8 d-flex justify-content-center responsive">
            <img className=" " width={'100%'} src={bannerLogin} style={{maxWidth:500}}></img>
          </div>

          <div className="col-md-4">
            <div className="col-12">
              <h4 className="col-12 pad0 mt20">Faça seu login</h4>
              <label className="mb-0">Login</label>
              <input
                type="text"
                className="form-control"
                id="login"
                maxLength="200"
                placeholder="Informe seu e-mail"
                onChange={(e) => {
                  e.persist();
                  setLogin((prev) => ({ ...prev, userName: e.target.value }));
                }}
              />

              <label className="mb-0 mt-3">Senha</label>
              <input

                type={revelar ?  'text'  :'password' }
                className="form-control"
                id="senha"
                maxLength="200"
                placeholder="Informe a senha"
                onChange={(e) => {
                  e.persist();
                  setLogin((prev) => ({ ...prev, password: e.target.value }));
                }}
              />
              <div className="olhoVerSenha" onClick={() => fnRevelar()}>

                {!revelar && <img src={verSenha} width='100%' alt='ver senha' />}
                {revelar && <img src={naoVerSenha} width='100%' alt='ver senha' />}


              </div>

 
              <Link to="/esqueci-senha">
                <span className="link d-flex justify-content-end">
                  Esqueci minha senha
                </span>
              </Link>

              <button
                className="col-12 btn btn-primary mt-5 pad10 "
                onClick={() => {
                  fnBtEntrar();
                }}
              >
              
                ENTRAR
              </button>

              {usuarioLogado.success == true && <Redirect to="/" />}

              {/* <p className="text-center mt-4"> Primeira vez aqui? </p>

              <Link to="/cadastro">
                <button className="col-12 btn btn-outline-primary mb30 pad10">
                  FAÇA SEU CADASTRO{" "}
                </button>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (store) => ({
  snackbarState: store.snackbarState,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ showMsgS, showMsgW, showMsgE }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
