import React from 'react';

import { saveAs } from 'file-saver';

import imgRegA from '../../../assets/img/docs/regulamento-1.PNG';
import print from '../../../assets/img/icons/print.png';
import fileRegulamento from '../../../assets/img/docs/Regulamento-Sorteio.pdf';
import pdfRegulamentoSorteio from '../Texto/sorteio2023.pdf'
import { Viewer, Worker} from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";


const RegulamentoConteundoSorteio = () => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();


    const downloadReg = () => {
        saveAs(fileRegulamento, 'regulamento-sorteio.pdf')
    }
    
    return (
        
<>
{/* <embed width='100%' height='500px' src={pdfRegulamentoSorteio}/>  */}

<div style={{height:'700px', width:'100%'}} >
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
          <Viewer
            fileUrl={pdfRegulamentoSorteio}
            wrap={false}
            plugins={[defaultLayoutPluginInstance]}
          ></Viewer>
        </Worker>
      </div>


</>


//         <div className="row">
//             <div className="col-12">
//                 <h3>
//                     REGULAMENTO/PLANO DE OPERAÇÃO DA PROMOÇÃO
//                 </h3>
//                 <h4>
//                     "Seu Chevrolet Seguro - Sorteio”<br/>
//                     CERTIFICADO DE AUTORIZAÇÃO SECAP/ME Nº 04.012270/2021
//                 </h4>

//                 <br/>
//                 <p className="bold">1. EMPRESAS PROMOTORAS:</p>
//                 <p>
//                     <strong>1.1. - Empresa Mandatária:</strong>
//                 </p>
//                 <p>
//                     Razão Social: <strong>GENERAL MOTORS DO BRASIL LTDA.</strong> <br/>
//                     Endereço: Avenida Goiás, nº 1805, <br/>
//                     Bairro: Santa Paula <br/>
//                     Município: São Caetano do Sul UF: SP <br/>
//                     CEP: 09.550-050 <br/>
//                     CNPJ/ME nº: 59.275.792/0001-50
//                 </p>


//                 <br/>
//                 <p className="bold">2. MODALIDADE DA PROMOÇÃO:</p>
//                 <p>
//                 Assemelhada a sorteio.
//                 </p>


//                 <br/>
//                 <p className="bold">3. ÁREA DE ABRANGÊNCIA: </p>
//                 <p>
//                 Todo o território nacional.
//                 </p>

//                 <br/>
//                 <p className="bold">4. PERÍODO DA PROMOÇÃO: </p>
//                 <p>
//                 16/04/2021 a 14/01/2022
//                 </p>


//                 <br/>
//                 <p className="bold">5. PERÍODO DE PARTICIPAÇÃO: </p>
//                 <p>
//                 16/04/2021 a 31/12/2021
//                 </p>

//                 <br/>
//                 <p className="bold">6. CRITÉRIO DE PARTICIPAÇÃO: </p>
//                 <p>
//                     <strong>6.1.</strong> Podem participar desta Promoção todas as pessoas físicas, maiores de 18 (dezoito) anos, inscritas e com cadastro ativo e regular no Cadastro de Pessoas Físicas (CPF) do Ministério da Economia, residentes e domiciliadas no território nacional, que sejam proprietárias dos seguintes veículos no ato da realização do Serviço do Recall abaixo definido:
//                 </p>
//                 <p>
//                     <strong>(a)</strong>	 Chevrolet Celta, ano de fabricação: 2013 a 2016, com Chassis de DG124288 até GG100849; e/ou <br/> 
//                     <strong>(b)</strong>	 Chevrolet Classic, ano de fabricação: 2012 a 2016, com Chassis de CC174331 até GR160004. 
//                 </p>

//                 <p className="ml30">
//                     <strong>6.1.1.</strong>	 Os veículos descritos nos itens “a” e “b” serão a seguir denominados “Veículos Participantes”. 
//                 </p>

//                 <p>
//                     <strong>6.2.</strong>	 Todos os proprietários dos Veículos Participantes deverão dirigir-se às concessionárias da Rede Chevrolet ou às Oficinas Autorizadas Chevrolet para realizar o serviço de troca do airbag de tais Veículos Participantes, do lado do motorista (“Serviço do Recall”). Todos os que realizarem o Serviço do Recall até 31/12/2021 estarão elegíveis à participação nesta Promoção.
//                 </p>

//                 <p className="ml30">
//                     <strong>6.2.1.</strong>	 Nesta oportunidade e após a realização do Serviço do Recall, o funcionário da Rede Chevrolet ou da Oficina Autorizada Chevrolet, validará o(s) número(s) do(s) Chassi(s) do(s) Veículo(s) Participante(s) no sistema, para que o interessado, proprietário do(s) respectivo(s) veículo(s) no momento da realização do Serviço, possa participar desta Promoção.
//                 </p>
//                 <p className="ml30">
//                     <strong>6.2.2.</strong>	 Não podem participar desta Promoção:
//                 </p>
//                 <p className="ml30">
//                 <strong>a)</strong>	Pessoas jurídicas;
//                 <strong>b)</strong>	Pessoas físicas que, embora tenham levado os Veículos Participantes para realizar o Serviço do Recall, não sejam os proprietários de tais Veículos Participantes; e
//                 <strong>c)</strong>	Pessoas físicas que não atenderem aos requisitos exigidos neste Regulamento.
//                 </p>
//                 <p className="ml30">
//                     <strong>6.2.3.</strong>	 A verificação do disposto no item acima é de responsabilidade da <strong>MANDATÁRIA</strong>, e será realizada no momento da apuração dos resultados da Promoção. Caso, por qualquer motivo, a despeito dos esforços da <strong>MANDATÁRIA</strong> para impedir a atribuição de Números da Sorte a pessoas impedidas de participar da Promoção, verifique-se que algum dos potenciais contemplados estava impedido de participar, ou ainda, verifique-se que o potencial contemplado não cumpriu com os requisitos e regras previstos neste Regulamento, este será desclassificado e será aplicada a regra de aproximação constante no item 11 abaixo para identificação do ganhador do prêmio em questão. 
//                 </p>

//                 <p>
//                     <strong>6.3.</strong>	Assim, após o cumprimento do disposto no item 6.2, as pessoas elegíveis e interessadas a participar deverão, durante o Período de Participação, compreendido entre 00h00min (horário de Brasília/DF) de 16 de abril de 2021 até as 23h59min de (horário de Brasília/DF) 31 de Março de 2022 (“Período de Participação”):

//                     <br/>(a)	acessar o website da Promoção www.seuchevroletseguro.com.br, disponibilizando eventuais dados solicitados para a realização de seu cadastro; e
//                     <br/>(b)	validar a sua participação por meio da manifestação do aceite a este regulamento e às demais políticas da <strong>MANDATÁRIA</strong>, incluindo a manifestação de seu consentimento do uso de seus dados pessoais, na forma prevista neste Regulamento.
//                 </p>
                
//                 <p className="ml30">
//                     <strong>6.3.1.</strong>	 Ao acessar o website da Promoção, o interessado deverá preencher a sua ficha cadastral, 
//                     com o fornecimento dos seguintes dados <strong>do proprietário do(s) Veículo(s) Participante(s)</strong> no ato da 
//                      realização do Serviço do Recall, para viabilização de sua participação: (i) nome completo; (ii) e-mail; (iii) 
//                      telefone com DDD; (iv) número do CPF; e (v) número do Chassi do Veículo Participante.
//                 </p>
//                 <p className="ml30">
//                     <strong>6.3.1.1.</strong>	Outras informações, necessárias para participação nesta Promoção ou para a entrega da premiação, poderão ser solicitadas aos interessados, a qualquer tempo, a exclusivo critério da <strong>MANDATÁRIA</strong>, sendo estas de caráter obrigatório, quando assim sinalizado.
//                 </p>

//                 <p className="ml30">
//                     <strong>6.3.2.</strong>	 Após o preenchimento dos dados pessoais, os interessados deverão ler este Regulamento e manifestar o seu respectivo entendimento, aceite e concordância com todos os termos, declarando e garantindo: (i) que são elegíveis à participação nesta Promoção; (ii) possuem capacidade jurídica para tanto, bem como que todas as informações prestadas são verdadeiras; (iii) que estão de acordo com todos os termos do Regulamento da Promoção; e (iv) que estão cientes de que os dados fornecidos poderão ser utilizados para os fins dispostos neste Regulamento.
//                 </p>

//                 <p className="ml30">
//                     <strong>6.3.3.</strong>	 Após o aceite e opt-in para participação, o interessado, automaticamente, será considerado “Participante” da Promoção e receberá via e-mail o seu respectivo número da sorte (“Número da Sorte”) pelo Veículo Participante cadastrado, para concorrer ao prêmio dos sorteios correspondentes à data de sua geração, observado o Período de Participação de cada apuração.
//                 </p>
//                 <p className="ml30">
//                     <strong>6.3.3.1.</strong> Cada Participante poderá receber nesta Promoção, no máximo, 1 (um) Número da Sorte por Veículo Participante que tiver realizado o Serviço do Recall.
//                 </p>

//                 <p className="ml30">
//                     <strong>6.3.4.</strong> Na hipótese do interessado ser proprietário de mais de um Veículo Participante, para cada Veículo Participante que tenha realizado o Serviço do Recall, o proprietário deverá realizar um novo cadastro e manifestar, mais uma vez, o seu aceite e concordância com todos os termos deste regulamento para fazer jus ao recebimento de seu número da sorte.
//                 </p>

//                 <p>
//                     <strong>6.4.</strong> Somente farão jus ao recebimento de Números da Sorte os efetivos proprietários dos Veículos Participantes no momento da realização do Serviço do Recall, de modo que tal propriedade deverá ser comprovada, a qualquer tempo, mediante solicitação da <strong>MANDATÁRIA</strong>, por meio da apresentação do Certificado de Registro e Licenciamento de Veículo e do Certificado de Registro com a autorização de transferência do veículo (que corresponde ao documento de compra e venda), que são os documentos capazes de comprovar tais informações.
//                 </p>
//                 <p className="ml30">
//                     <strong>6.4.1.</strong> Caso o Serviço do Recall seja eventualmente realizado no Veículo Participante a pedido de terceiro, que não o proprietário do Veículo Participante, tal terceiro não fará jus à participação nesta Promoção. 
//                 </p>
//                 <p className="ml30">
//                     <strong>6.4.2.</strong> Na eventualidade do cadastro para participação nesta Promoção ter sido 
//                     realizado em nome de terceiro, que não seja o proprietário do Veículo Participante no ato da realização 
//                     do Serviço do Recall, o efetivo proprietário deverá entrar em contato com o SAC da Promoção através do 
//                     e-mail recallchevrolet@gm.com para solicitar a exclusão do cadastro realizado por terceiro que não é elegível. 
//                     Nesse momento, o efetivo proprietário deverá apresentar o Certificado de Registro e Licenciamento de Veículo e/ou 
//                     do Certificado de Registro com a autorização de transferência do veículo (que corresponde ao documento de compra e 
//                     venda) para comprovar que era o proprietário do Veículo Participante no momento da realização do Serviço do Recall, 
//                     além de documento que comprove a sua identidade (RG e CPF/CNH). 
//                 </p>
//                 <p className="ml30">
//                     <strong>6.4.3.</strong> Comprovado que o cadastro do Veículo Participante foi realizado por terceiro que não é o proprietário no momento da realização do Serviço do Recall, tal cadastro será excluído, o(s) Número(s) da Sorte enviado(s) por e-mail para o terceiro serão invalidados e o efetivo proprietário poderá realizar um novo cadastramento do Chassi do Veículo Participante nesta Promoção e fazer jus ao recebimento de um novo Número da Sorte para concorrer aos prêmios dos sorteios desta Promoção.

//                 </p>

//                 <p className="ml30">
//                     <strong>6.4.4.</strong> Caso o Veículo Participante seja vendido e, no momento da contemplação, estiver na propriedade de outra pessoa, fará jus à participação e, consequentemente ao recebimento de Número da Sorte e do prêmio, o proprietário do Veículo Participante no momento da realização do Serviço do Recall.

//                 </p>

//                 <p >
//                     <strong>6.5.</strong> Não serão válidas as Participações dos Participantes que: 
//                     <br/>(i)	Não sejam os efetivos proprietários dos Veículos Participantes, quando da realização do Serviço do Recall; e 
//                     <br/>(ii)	Tenham realizado o serviço de troca do airbag, do lado do motorista, em concessionárias que não sejam da Rede Chevrolet e Oficinas Não Autorizadas pela Chevrolet, independentemente da data em que o serviço tenha sido realizado.

//                 </p>

//                 <p>
//                     <strong>6.6.</strong> Cada Veículo Participante dará o direito ao recebimento de apenas 1 (um) Número da Sorte. Cada Participante poderá participar com quantos Veículos Participantes for de sua propriedade, no momento da realização do Serviço do Recall. Cada Número da Sorte poderá ser contemplado nesta Promoção apenas 1 (uma) vez.
//                 </p>
//                 <p className="ml30">
//                     <strong>6.6.1.</strong> O Número da Sorte pelo cadastro realizado nesta Promoção serão enviados via e-mail e os Participantes deverão guardar consigo tais e-mails recebidos da MANDATÁRIA, em razão de sua participação, para que possam checar os seus respectivos Números da Sorte com os quais estão concorrendo nesta Promoção.
//                 </p>


//                 <p>
//                     <strong>6.7.</strong> Caso o Participante não consiga realizar o seu 
//                     cadastro no website para participar da promoção, por quaisquer motivos ou para esclarecimentos adicionais e eventuais dúvidas, deverá entrar em contato através do e-mail recallchevrolet@gm.com, ou do fale conosco, disponível no website www.seuchevroletseguro.com.br. Todas as dúvidas encaminhadas serão respondidas em até 05 (cinco) dias úteis pela MANDATÁRIA. Os Participantes ficam cientes de que as conversas realizadas para o esclarecimento de dúvidas poderão ser gravadas pela <strong>MANDATÁRIA</strong>, a seu único e exclusivo critério.
//                 </p>
//                 <p>
//                     <strong>6.8.</strong> Os Participantes deverão manter todos os documentos que possam evidenciar a sua propriedade sobre o(s) Veículo(s) Participante(s) quando da realização do Serviço do Recall, e apresentá-los, sempre que solicitado, comprovando assim as informações indicadas no momento do cadastro. A não apresentação dos documentos aqui indicados, no prazo fixado pela MANDATÁRIA, ou apresentação de imagem que não comprove as informações ou indique se tratar de fraude à presente Promoção, ensejará a desclassificação do Participante. A <strong>MANDATÁRIA</strong> poderá recusar os documentos que não sejam capazes de demonstrar a veracidade, legalidade e conformidade dos requisitos aqui estabelecidos ou, ainda, que apresentem indícios de fraude, não cabendo qualquer direito de reclamação ou reivindicação dos consumidores, salvo se estes efetivamente comprovarem a sua propriedade sobre o Veículo Participante, quando da realização do Serviço do Recall. Neste caso, a comprovação deverá ser realizada no prazo de 3 (três) dias úteis, do efetivo contato, contados da solicitação da MANDATÁRIA.

//                 </p>
//                 <p>
//                     <strong>6.9.</strong> Os Participantes estão cientes que, ao participar desta Promoção, haverá  tratamento e compartilhamento, pela <strong>MANDATÁRIA</strong> e os parceiros envolvidos na Promoção, das informações e dos dados pessoais fornecidos quando da realização do Serviço do Recall para as finalidades de operacionalização, execução, divulgação da própria Promoção e dos participantes contemplados, entrega do prêmio, procedimentos de auditoria e, ainda, de fiscalização, nos termos do item 15.10 abaixo, e prestação de contas junto à Secretaria de Avaliação, Planejamento, Energia e Loteria do Ministério da Economia – SECAP/ME, nos limites da legislação aplicável, sendo que os dados não serão utilizados para qualquer outra finalidade sem a prévia autorização do participante.

//                 </p>
//                 <p className="ml30">
//                     <strong>6.9.1.</strong> Assim, a <strong>MANDATÁRIA</strong> expressamente declara que os dados obtidos 
//                     em razão desta Promoção não serão de qualquer forma comercializados ou cedidos a terceiros, ainda que a título 
//                     gratuito, e serão utilizados apenas nos limites acima indicados <strong>e nos termos da Política de Privacidade da 
//                         MANDATÁRIA</strong>, o qual o Participante deverá manifestar o seu aceite para participar da Promoção, disponível 
//                         em https://www.chevrolet.com.br/ajuda/politica-de-privacidade.

//                 </p>
//                 <p className="ml30">
//                     <strong>6.9.2.</strong> Os Participantes que não mais concordarem com o tratamento dos seus dados para as finalidades descritas nos itens 6.9. e 6.9.1. acima, deverão deixar de participar desta promoção, solicitando a exclusão dos seus respectivos dados através do e-mail recallchevrolet@gm.com a qualquer tempo, durante o Período da Promoção, ficando o Participante ciente que a exclusão de seus dados pessoais do cadastro da promoção acarretará sua imediata exclusão e desclassificação da promoção.

//                 </p>
//                 <p className="ml30">
//                     <strong>6.9.3.</strong> Ademais, o Participante poderá desistir de participar da Promoção a qualquer tempo, independentemente do motivo, mesmo após o Participante ter manifestado o seu aceite em participar desta Promoção. E não poderá voltar a participar com o mesmo Chassi.

//                 </p>
//                 <p className="ml30">
//                     <strong>6.9.3.1.</strong> A desistência desta Promoção dependerá única e exclusivamente da vontade do Participante e deverá ser solicitada, por meio do pedido de exclusão do cadastro, disponível no canal de atendimento do website da Promoção ou através do e-mail recallchevrolet@gm.com.

//                 </p>


//                 <br/>
//                 <p className="bold">7. QUANTIDADE DE SÉRIES </p>
//                 <p>5 </p>

//                 <br/>
//                 <p className="bold">8. QUANTIDADE DE ELEMENTOS SORTEÁVEIS POR SÉRIE: </p>
//                 <p>100.000</p>


//                 <br/>
//                 <p className="bold">9. APURAÇÃO E DESCRIÇÃO DE PRÊMIOS: </p>
//                 <p>
//                     <strong>DATA:</strong> 30/07/2021, 12:00
//                     <br/><strong>PERÍODO DE PARTICIPAÇÃO DA APURAÇÃO:</strong> 16/04/2021 00:00 a 26/07/2021 23:59
//                     <br/><strong>DATA DO SORTEIO DA LOTERIA FEDERAL:</strong> 28/07/2021
//                     <br/><strong>ENDEREÇO DA APURAÇÃO:</strong> Avenida Barão do Rego Barros, nº 17, Campo Belo, São Paulo/SP, CEP 04612-040.
//                     <br/><strong>LOCAL DA APURAÇÃO:</strong> Sede da empresa “DEVPARTNER”.
//                     <br/>
//                     <br/><strong>PRÊMIOS:</strong> 
//                 </p>

//                 <div className='table-responsive'>
//                     <table className="table table-bordered">
//                         <thead>
//                             <tr>
//                                 <th> Quantidade </th>
//                                 <th> Descrição </th>
//                                 <th> Valor R$ </th>
//                                 <th> Valor Total R$ </th>
//                                 <th> Série Inicial </th>
//                                 <th> Série Final </th>
//                                 <th> Ordem </th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             <tr>
//                                 <td>1</td>
//                                 <td>1 (um) Novo Onix LT Turbo 5B48HM (MY21), configuração R7G, na cor Preto Ouro Negro - 
// @70, 0km (inclusos: emplacamento, licenciamento, documentação e IPVA do ano da entrega do veículo).	</td>
//                                 <td>R$ 74.060,00 (setenta e quatro mil e sessenta reais)</td>
//                                 <td>R$ 74.060,00 (setenta e quatro mil e sessenta reais)</td>
//                                 <td>0</td>
//                                 <td>4</td>
//                                 <td>1</td>
//                             </tr>
//                         </tbody>
//                     </table>
//                 </div>

//                 <br/>
//                 <p>
//                     <strong>DATA:</strong> 29/10/2021, 12:00
//                     <br/><strong>PERÍODO DE PARTICIPAÇÃO DA APURAÇÃO:</strong> 16/04/2021 00:00 a 25/10/2021 23:59
//                     <br/><strong>DATA DO SORTEIO DA LOTERIA FEDERAL:</strong> 27/10/2021
//                     <br/><strong>ENDEREÇO DA APURAÇÃO:</strong> Avenida Barão do Rego Barros, nº 17, Campo Belo, São Paulo/SP, CEP 04612-040.
//                     <br/><strong>LOCAL DA APURAÇÃO:</strong> Sede da empresa “DEVPARTNER”.
//                     <br/>
//                     <br/><strong>PRÊMIOS:</strong> 
//                 </p>


//                 <div className='table-responsive'>
//                     <table className="table table-bordered">
//                         <thead>
//                             <tr>
//                                 <th> Quantidade </th>
//                                 <th> Descrição </th>
//                                 <th> Valor R$ </th>
//                                 <th> Valor Total R$ </th>
//                                 <th> Série Inicial </th>
//                                 <th> Série Final </th>
//                                 <th> Ordem </th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             <tr>
//                                 <td>1</td>
//                                 <td>1 (um) Novo Onix LT Turbo 5B48HM (MY21), configuração R7G, na cor Preto Ouro Negro - @70, 0km 
// (inclusos: emplacamento, licenciamento, documentação, IPVA do ano da entrega do veículo).	</td>
//                                 <td>R$ 74.060,00 (setenta e quatro mil e sessenta reais)</td>
//                                 <td>R$ 74.060,00 (setenta e quatro mil e sessenta reais)</td>
//                                 <td>0</td>
//                                 <td>4</td>
//                                 <td>1</td>
//                             </tr>
//                         </tbody>
//                     </table>
//                 </div>
	

//                 <br/>
//                 <p>
//                     <strong>DATA:</strong> 14/01/2022, 12:00
//                     <br/><strong>PERÍODO DE PARTICIPAÇÃO DA APURAÇÃO:</strong> 16/04/2021 00:00 a 31/12/2021 23:59
//                     <br/><strong>DATA DO SORTEIO DA LOTERIA FEDERAL:</strong> 12/01/2022
//                     <br/><strong>ENDEREÇO DA APURAÇÃO:</strong> Avenida Barão do Rego Barros, nº 17, Campo Belo, São Paulo/SP, CEP 04612-040.
//                     <br/><strong>LOCAL DA APURAÇÃO:</strong> Sede da empresa “DEVPARTNER”.
//                     <br/>
//                     <br/><strong>PRÊMIOS:</strong> 
//                 </p>

//                 <div className='table-responsive'>
//                     <table className="table table-bordered">
//                         <thead>
//                             <tr>
//                                 <th> Quantidade </th>
//                                 <th> Descrição </th>
//                                 <th> Valor R$ </th>
//                                 <th> Valor Total R$ </th>
//                                 <th> Série Inicial </th>
//                                 <th> Série Final </th>
//                                 <th> Ordem </th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             <tr>
//                                 <td>1</td>
//                                 <td>1 (um) Novo Onix LT Turbo 5B48HM (MY21), configuração R7G, na cor Preto Ouro Negro - 
// @70, 0km (inclusos: emplacamento, licenciamento, documentação, IPVA do ano da entrega do veículo).	</td>
//                                 <td>R$ 74.060,00 (setenta e quatro mil e sessenta reais)</td>
//                                 <td>R$ 74.060,00 (setenta e quatro mil e sessenta reais)</td>
//                                 <td>0</td>
//                                 <td>4</td>
//                                 <td>1</td>
//                             </tr>
//                         </tbody>
//                     </table>
//                 </div>

//                 <br/>
//                 <p className="bold">10. PREMIAÇÃO TOTAL: </p>
//                 <br/>

//                 <div className='table-responsive'>
//                     <table className="table table-bordered">
//                         <thead>
//                             <tr>
//                                 <th> Quantidade </th>
//                                 <th> Total de Prêmios	Valor total da Promoção R$ </th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             <tr>
//                                 <td>3</td>
//                                 <td>R$ 222.180,00</td>
//                             </tr>
//                         </tbody>
//                     </table>
//                 </div>

//                 <br/>
//                 <p className="bold">11. FORMA DE APURAÇÃO:</p>

//                 <p>Regra de Formação dos Elementos Sorteáveis.</p>
//                 <p><strong>ELEMENTO SORTEÁVEL:</strong> Serão gerados 100.000 Elementos sorteáveis de 00.000 a 99.999 por série;</p>
//                 <p><strong>NÚMERO DA SORTE:</strong> Será formado por 6 números, sendo que o primeiro número corresponde à série e os últimos números ao ELEMENTO SORTEÁVEL, conforme exemplo abaixo:</p>
//                 <p>Exemplo: 2/12.345 = 2(série); 12.345(elemento sorteável).</p>

//                 <p><strong>Data do Sorteio da Loteria Federal:</strong> Caso a Extração da Loteria Federal não venha a ocorrer na data prevista, por qualquer motivo, será considerada para efeitos de apuração do resultado desta Promoção, a data da Extração da Loteria Federal subsequente, sendo a apuração, neste caso, realizada em até 2 (dois) dias úteis subsequentes à data da Extração da Loteria Federal, às 14h.</p>
//                 <p><strong>Regra de Apuração da série:</strong> </p>

//                 <p>A definição da série contemplada em cada sorteio se dará a partir dos prêmios da Extração da Loteria Federal, lidos de cima para baixo, por meio leitura da dezena simples do primeiro prêmio.</p>
//                 <p><strong>NOTA:</strong> Caso o número de série encontrado (parágrafo anterior) seja superior à maior série da apuração, deverá ser subtraída a quantidade de séries da apuração, do número de série encontrado, tantas vezes quantas forem necessárias, até que o número obtido esteja dentro do intervalo de séries da apuração. Caso o número de série encontrado seja inferior à menor série da apuração, deverá ser adicionada a quantidade de séries da apuração, do número de série encontrado, tantas vezes quantas forem necessárias, até que o número obtido esteja dentro do intervalo de séries da apuração.</p>
//                 <p>Regra de Apuração do elemento sorteável: </p>
//                 <p>A definição do elemento sorteável se dará a partir dos prêmios da Extração da Loteria Federal, lidos de cima para baixo, por meio da combinação das unidades simples do primeiro ao quinto prêmio. Dessa forma, por exemplo, caso o resultado de um sorteio da Loteria Federal seja: </p>
	

//                 <p>
//                     1º prêmio 13.351
//                     2º prêmio 06.444
//                     3º prêmio 16.539
//                     4º prêmio 04.323
//                     5º prêmio 18.464
//                 </p>

//                 <p>
//                 O elemento sorteável apurado seria o <strong>14934</strong>.
//                 </p>
//                 <p>
//                 <strong>Número da sorte contemplado: </strong>
//                 </p>
//                 <p>
//                 Série apurada seguida do elemento sorteável apurado. 
//                 </p>

//                 <p>
//                 <strong>Regra de Apuração dos Contemplados: </strong>
//                 </p>
//                 <p>
//                 Para determinação do participante contemplado, este deve possuir o “Número da Sorte” que coincide exatamente com o “Número da Sorte contemplado”, ou o mais próximo ao Número da Sorte contemplado conforme regra de aproximação abaixo, e atender aos critérios de participação. Um mesmo participante não poderá ser contemplado mais de uma vez nessa Promoção com o mesmo Número da Sorte. 
//                 </p>

//                 <p>
//                 <strong>Aproximação: </strong>
//                 </p>

//                 <p>
//                 No caso de não ter sido distribuído o “Número da Sorte” apurado ou, ainda, caso seu titular não atenda aos critérios de participação (ou seja, deva ser desclassificado), a determinação do participante contemplado será o “Elemento Sorteável” imediatamente superior, dentro da mesma série, repetindo-se tal procedimento até que se encontre um “Elemento Sorteável” distribuído mais próximo ao apurado com base no resultado da loteria federal. No caso de se alcançar o número sequencial final, a busca permanecerá pelos números imediatamente superiores partindo do número sequencial inicial. 
//                 </p>
//                 <p>
//                 Caso não tenha sido distribuído nenhum “Elemento Sorteável” na série apurada ou não tenha sido distribuída quantidade suficiente de “Elementos Sorteáveis” para identificar todos os contemplados na série apurada, deve-se repetir o procedimento descrito nos parágrafos anteriores para todas as séries que compõem a apuração, alternadamente para a série imediatamente superior, ou, na falta desta, para a imediatamente inferior, verificando a eventual distribuição do “Elemento Sorteável Apurado”, em tal série, e, se necessário, o procedimento de aproximação descrito no parágrafo anterior.
//                 </p>
//                 <p>
//                 <strong>Distribuição dos números da sorte:</strong>
//                 </p>
//                 <p>
//                 A geração e distribuição dos números da sorte devem ser feitas de forma aleatória.
//                 </p>
//                 <p>
//                 Caso todos os Números da Sorte disponíveis sejam distribuídos antes da data prevista para o término da Promoção, o Período de Participação será encerrado antecipadamente, permanecendo inalterada a data do sorteio e da apuração, concorrendo ao sorteio todos os Participantes que já tiverem recebido números da sorte.
//                 </p>


 
//                 <br/>
//                 <p className="bold">12. CRITÉRIOS DE DESCLASSIFICAÇÃO E ISENÇÃO DE RESPONSABILIDADE:</p>

//                 <p>
//                 <strong>12.1.</strong>	Os Participantes serão excluídos e desclassificados automaticamente da Promoção, a qualquer momento, em caso de indício de fraude, a critério exclusivo da <strong>MANDATÁRIA</strong>, tentativa ou fraude comprovada, manipulação de resultados ou não preenchimento dos requisitos e das disposições previstas neste Regulamento  ou não comprovação satisfatória da propriedade do Veículo Participante no momento da realização dos Serviços do Recall, de acordo com as regras deste Regulamento. Também serão desclassificadas as pessoas impedidas de participar da Promoção, nos termos do item 6.2.2 acima.
//                 </p>
//                 <p>
//                 <strong>12.2.</strong>	Serão considerados nulos e serão imediatamente desclassificados e impedidos de concorrer aos prêmios, os cadastros para os quais tenham sido utilizados quaisquer meios tecnológicos ou haja indícios de sua utilização, sejam eles eletrônicos, informáticos, digital, robótico, repetitivo, automático, mecânico e/ou análogo, com intuito de reprodução automática e/ou repetitiva de cadastros, idênticos ou não, o que importará na nulidade também, de todos os cadastros efetuadas pelo Participante que tenha se utilizado de um dos referidos meios ou com um dos referidos fins, ainda que nem todos os cadastros tenham resultado do uso de tais meios e/ ou sido realizadas com tal finalidade. 
//                 </p>
//                 <p>
//                 <strong>12.3.</strong>	Caso a constatação do indício, tentativa ou fraude comprovada ou não preenchimento dos requisitos previstos neste Regulamento seja identificada no momento da apuração, o participante será desclassificado e será aplicada a regra de aproximação constante no item 11 acima para identificação do ganhador do prêmio em questão.
//                 </p>
//                 <p>
//                 <strong>12.4.</strong>	Nos casos de indício de fraude, tentativa ou fraude comprovada na participação nesta Promoção ou, ainda, não preenchimento das condições previstas neste Regulamento, identificados após a conclusão da apuração, o Participante, ainda que contemplado, será automaticamente desclassificado e a <strong>MANDATÁRIA</strong> poderá aplicar a regra de apuração constante no item 11 acima para identificação do ganhador do prêmio em questão. Caso não exista tempo hábil para a aplicação da regra constante em tais itens, o valor do respectivo prêmio será recolhido aos cofres da União, no prazo de 10 (dez) dias da prescrição.
//                 </p>
//                 <p>
//                 <strong>12.5.</strong>	Toda e qualquer participação realizada que não preencher todas as condições estabelecidas neste Regulamento não será considerada válida para os efeitos desta Promoção, podendo o participante ser desclassificado da Promoção, a único e exclusivo critério da <strong>MANDATÁRIA</strong>. 
//                 </p>


//                 <p>
//                 <strong>12.6.</strong>	 O Participante é o único responsável pelas informações e dados pessoais informados quando da realização do cadastro no website da Promoção, responsabilidade que abrange, também, a precisão e a veracidade de tais informações e dados. Para fins de participação e entrega da premiação serão considerados apenas os dados constantes no cadastro realizado pelo interessado nesta Promoção. A <strong>MANDATÁRIA</strong> está isenta de quaisquer responsabilidades em caso de incorreção, desatualização ou não veracidade das informações e dados informados pelo Participante, que poderá ser desclassificado.
//                 </p>
//                 <p>
//                 <strong>12.7.</strong>	A <strong>MANDATÁRIA</strong> não se responsabilizará por eventuais prejuízos que os Participantes venham a sofrer em decorrência da Promoção, oriundos de fatos alheios à MANDATÁRIA. Outrossim, a <strong>MANDATÁRIA</strong> não será responsável por inscrições e/ou cadastros não efetivados por problemas na transmissão de dados no servidor do Participante, tais como: problemas de acesso à rede de Internet, intervenção de hackers, vírus, manutenção, queda de energia, falhas de software ou hardware, problemas operacionais com as empresas de telefonia que possam, direta ou indiretamente, afetar o acesso à Internet e, consequentemente, a participação na presente Promoção, bem como por casos fortuitos ou de força maior, em provedores de acessos dos Participantes ou ainda por falta de energia elétrica. 
//                 </p>
//                 <p className="ml30">
//                 <strong>12.7.1.</strong>	A <strong>MANDATÁRIA</strong> e/ou as pessoas e empresas envolvidas na Promoção não serão responsáveis, ainda, no caso de vazamento/disseminação das imagens, nome, voz, textos e vídeos captados por terceiros não autorizados, bem como fica isenta de responsabilidade pela disseminação dos vídeos, imagens, textos e todo e qualquer conteúdo em qualquer canal de internet e mídia digital e/ou social, tais como, mas não se limitando a Youtube, Facebook, Instagram, entre outros, que forem feitos por terceiros não autorizados durante ou após o término da Promoção.  
//                 </p>


//                 <p>
//                 <strong>12.8.</strong>	A <strong>MANDATÁRIA</strong> e/ou as pessoas e empresas envolvidas na Promoção também não se responsabilizarão por eventuais e/ou quaisquer prejuízos diretos ou indiretos que os consumidores participantes possam ter, oriundos da participação na Promoção, da aceitação do prêmio, ou ainda de situações que estejam fora do controle da <strong>MANDATÁRIA</strong> e/ou das pessoas e empresas envolvidas na Promoção, contanto que não fique demonstrada a responsabilidade única, exclusiva e comprovada judicialmente da mesma. 
//                 </p>
//                 <p>
//                 <strong>12.9.</strong>	Se, por eventuais ocorrências de saúde pública, tais como, exemplificativamente, pandemias, endemias e epidemias, o contemplado se recusar a receber o prêmio ou o prêmio estiver eventualmente indisponível por situação alheia ao controle da <strong>MANDATÁRIA</strong>, o tema será submetido à análise da SECAP-ME para solução e eventuais alternativas, podendo o prazo de entrega se estender ou, até mesmo, o prêmio ser alterado para viabilizar a sua entrega ao contemplado. Poderá, ainda, a <strong>MANDATÁRIA</strong>, para a segurança dos consumidores, de todos os participantes e da população em geral, preservando os interesses coletivos em detrimento de interesses individuais, optar pelo cancelamento da Promoção ou outra alternativa, como a alteração da premiação, sem que isso represente qualquer forma de violação de direitos ou imposição de prejuízos aos Participantes, situação que será dividida e apresentada à SECAP-ME para autorização.
//                 </p>
//                 <p>
//                 <strong>12.10.</strong>	Em momento algum poderá a <strong>MANDATÁRIA</strong> ser responsabilizada por cadastros perdidos, atrasados, enviados erroneamente, incompletos, incorretos, inválidos ou imprecisos. A <strong>MANDATÁRIA</strong> não será responsável por problemas, falhas ou funcionamento técnico, de qualquer tipo, em redes de computadores, servidores ou provedores, equipamentos de computadores, hardware ou software, erro, interrupção, defeito, atraso ou falha em operações ou transmissões para o correto processamento de cadastros, em razão de problemas técnicos, congestionamento na Internet, vírus, falha de programação (bugs) ou violação por terceiros (hackers), dos quais não detenha qualquer controle. Tais falhas não implicam em qualquer tipo de obrigação por parte das PROMOTORAS em prorrogar o Período de Participação.
//                 </p>
//                 <p>
//                 <strong>12.11.</strong>	Também não será de responsabilidade da <strong>MANDATÁRIA</strong> qualquer dano que os Contemplados possam vir a causar e/ou sofrer, por sua culpa e/ou dolo, após o recebimento do prêmio, respondendo os mesmos, cível e criminalmente nos termos da lei. 
//                 </p>

//                 <p>
//                 <strong>12.12.</strong>	O Prêmio será entregue pela <strong>MANDATÁRIA</strong> livre e desembaraçado de qualquer ônus ao Contemplado, ficando, desde já estabelecido, que a partir do momento da entrega do Prêmio, a responsabilidade por tal prêmio é única e exclusiva do Contemplado.
//                 </p>
//                 <p>
//                 <strong>12.13.</strong>	O Contemplado isenta a <strong>MANDATÁRIA</strong> de qualquer responsabilidade e/ou dano patrimonial e moral, ou incidentes causados, decorrente do uso indevido ou não do prêmio, ou de quaisquer danos verificados, por motivo de caso fortuito ou força maior, que possam impossibilitar o ganhador de usufruir seu prêmio.
//                 </p>
//                 <p>
//                 <strong>12.14.</strong>	Não cabe ao Participante contemplado e/ou seus responsáveis/representantes legais discutir ou redefinir as condições e premissas da Promoção ou do prêmio.
//                 </p>

//                 <p>
//                 <strong>12.15.</strong>	O prêmio a ser distribuído destina-se ao Participante Contemplado e será entregue em nome do mesmo.
//                 </p>
//                 <p>
//                 <strong>12.16.</strong>	Caso algum contemplado não seja localizado por meio dos dados por ele cadastrados nesta Promoção por motivo de incorreção, sua participação será desconsiderada a qualquer tempo, a critério da <strong>MANDATÁRIA</strong>, aplicando-se a regra estabelecido no item 11 para identificação de um participante elegível e potencialmente contemplado. 
//                 </p>
//                 <p>
//                 <strong>12.17.</strong>	O prêmio não poderá ser distribuído ou convertido, total ou parcialmente em dinheiro de acordo com o art. 15, §5°, Decreto nº 70.951/72. O prêmio não poderá ser trocado, alterado, complementado ou substituído por outro prêmio.
//                 </p>


//                 <br/>
//                 <p className="bold">13. FORMA DE DIVULGAÇÃO DO RESULTADO:</p>

//                 <p>
//                 <strong>13.1.</strong>	A divulgação da relação dos Participantes potencialmente contemplados e de seus respectivos números da sorte se fará por meio do website www.seuchevroletseguro.com.br, no prazo de até 10 (dez) dias úteis da data do sorteio, permanecendo tal website disponível para consulta por pelo menos 30 (trinta) dias, contados do término do período de participação.
//                 </p>

//                 <p>
//                 <strong>13.2.</strong>	 A <strong>MANDATÁRIA</strong> entrará em contato com o Participante contemplado por telefone e/ou e-mail, observados os dados do cadastro, no prazo de até 10 (dez) dias úteis da divulgação do resultado.
//                 </p>
//                 <p>
//                 <strong>13.3.</strong>	A <strong>MANDATÁRIA</strong> tentará contato com o potencial contemplado considerando os dados informados no seu cadastro, por telefone e e-mail (não obrigatoriamente nesta ordem), com intervalos de 01 (um) dia entre uma e outra tentativa, observadas as hipóteses de desclassificação em caso de não apresentação da documentação no prazo estabelecido. Caso a <strong>MANDATÁRIA</strong> não localize ou consiga contato, em decorrência do cadastramento de informações errôneas pelo participante, o potencial contemplado será desclassificado aplicando-se a regra de aproximação descrita no item 11 para identificação do próximo participante potencial contemplado, observadas, ainda, as hipóteses de desclassificação em caso de não apresentação da documentação no prazo estabelecido.
//                 </p>
//                 <p>
//                 <strong>13.4.</strong>	A <strong>MANDATÁRIA</strong> divulgará os números da sorte contemplados juntamente com os nomes dos participantes contemplados no website da Promoção.
//                 </p>



//                 <br/>
//                 <p className="bold">14. ENTREGA DOS PRÊMIOS:</p>

//                 <p>
//                 <strong>14.1.</strong>	O prêmio é pessoal, intransferível e será entregue, livre e desembaraçado de qualquer ônus, na Concessionária mais próxima da residência do contemplado, no prazo máximo de 30 (trinta) dias, a contar da data da apuração, de acordo com o artigo 5º do Decreto nº 70.951/72, desde que atendidos todos os requisitos apresentados neste Regulamento, mediante agendamento junto à MANDATÁRIA.
//                 </p>
//                 <p className="ml30">
//                 <strong>14.2.1.1.</strong>	A <strong>MANDATÁRIA</strong> ressalta que, por motivo de caso fortuito ou força maior, em decorrência da situação atual de pandemia motivada pela COVID-19, podem ocorrer atrasos para a entrega do prêmio ao Contemplado por situações alheias ao controle da <strong>MANDATÁRIA</strong>. Em assim ocorrendo, o contemplado será sempre comunicado para acompanhamento da entrega de sua premiação. 
//                 </p>
//                 <p className="ml30">
//                 <strong>14.2.1.2.</strong>	Em caso de renúncia formal do Participante inicialmente contemplado, poderá a <strong>MANDATÁRIA</strong> identificar novo Contemplado para o recebimento do prêmio, utilizando, neste caso, a regra de aproximação constante no item 11, para identificar o novo ganhador do prêmio em questão.
//                 </p>

//                 <p>
//                 <strong>14.2.</strong>	Para recebimento do prêmio, obrigatoriamente, o Participante potencialmente contemplado deverá enviar para a <strong>MANDATÁRIA</strong>: (i) documentos que comprovem a efetiva propriedade do Veículo Participante quando da realização do Serviço do Recall, tais como o Certificado de Registro e Licenciamento de Veículo e/ou do Certificado de Registro com a autorização de transferência do veículo (que corresponde ao documento de compra e venda); (ii) cópia legível do seu RG/CNH; (iii) cópia legível do CPF/ME; e (iv) cópia legível de seu comprovante de residência, com data de no máximo 180 (cento e oitenta) dias anteriores à data da divulgação dos resultados, em seu nome ou em nome de algum parente ou cônjuge, acompanhado de outros documentos que comprovem seu grau de parentesco, conforme instruções que serão fornecidas pela <strong>MANDATÁRIA</strong>. Se necessário, a <strong>MANDATÁRIA</strong> poderá solicitar documentos ou informações complementares que atestem o cumprimento dos requisitos previstos neste Regulamento, documentos estes que também deverão ser apresentados pelo Participante potencialmente contemplado no prazo disposto abaixo.
//                 </p>
//                 <p>
//                 <strong>14.3.</strong>	A documentação listada no item 14.2 deverá ser apresentada <strong> em 3 dias úteis, contados do 
//                     efetivo contato com os contemplados pela MANDATÁRIA</strong>. Caso algum potencial contemplado não apresente ou se recuse a apresentar os documentos elencados no item 14.2, no prazo de 3 (três) dias úteis do efetivo contato ou, ainda, os apresente de forma divergente do cadastro ou de forma insuficiente para a validade de sua contemplação, ele será automaticamente e imediatamente desclassificado e o prêmio correspondente será destinado a outro participante, de acordo com a regra prevista no item 11, passando tal novo participante potencialmente contemplado pelos procedimentos descritos acima.
//                 </p>
//                 <p>
//                 <strong>14.4.</strong>	Caso determinado contemplado não esteja presente para recebimento do prêmio, deverá outorgar poderes a procuradores, constituídos por procuração pública ou particular com reconhecimento de firma em Cartório, autorizando-os a receber o prêmio e a dar recibo da quitação da obrigação da <strong>MANDATÁRIA</strong> e entrega de prêmio. 
//                 </p>
//                 <p>
//                 <strong>14.5.</strong>	O recibo da quitação da obrigação da <strong>MANDATÁRIA</strong> constituirá prova de entrega do prêmio e será mantido sob guarda da <strong>MANDATÁRIA</strong> pelo prazo legal.
//                 </p>

//                 <br/>
//                 <p className="bold">15. DISPOSIÇÕES GERAIS:</p>

//                 <p>
//                 <strong>15.1.</strong>	No caso de o Contemplado falecer antes da entrega do prêmio, os respectivos herdeiros o receberão por si, de acordo com a legislação vigente, desde que exercido tal direito, observados os prazos previstos neste regulamento, e desde que apresentada a devida documentação que os legitime. As Promotoras não serão responsabilizadas caso o prêmio não possa ser gozado em virtude dos procedimentos legais decorrentes do próprio inventário.
//                 </p>
//                 <p>
//                 <strong>15.2.</strong>	Caso o contemplado se encontre relativamente incapaz, nos termos do inciso II, III e IV do artigo 4º do Código Civil (Lei 10.406/2002), deverá ser representado por seus responsáveis legais para recebimento do prêmio, o qual, não obstante, será emitido em nome do relativamente incapaz, conforme o caso.
//                 </p>
//                 <p>
//                 <strong>15.3.</strong>	A Promoção será divulgada pela <strong>MANDATÁRIA</strong> por meio do website www.seuchevroletseguro.com.br, entre outros, sendo todas as informações relacionadas a esta promoção disponibilizadas neste local.
//                 </p>
//                 <p>
//                 <strong>15.4.</strong>	O Número do Certificado de autorização desta Promoção constará sempre neste regulamento e no website da Promoção estando a <strong>MANDATÁRIA</strong> dispensada da aposição em todos os materiais de comunicação.
//                 </p>

//                 <p>
//                 <strong>15.5.</strong>	O Participante contemplado ou seu responsável concorda em ceder gratuitamente neste ato os direitos de uso de sua imagem, nome e som de voz, sem qualquer ônus para a MANDATÁRIA, para uso exclusivo na divulgação desta Promoção, por meio de cartazes, filmes e/ou spots, jingles e/ou vinhetas, bem como em qualquer tipo de mídia e/ou peças promocionais, inclusive em televisão, rádio, jornal, cartazes, faixas, outdoors, mala-direta e na Internet, pelo período de 12 (doze) meses, sem limite de território, contados da data de apuração dos resultados.
//                 </p>
//                 <p>
//                 <strong>15.6.</strong>	As autorizações descritas no item 15.5 não implicam em qualquer obrigação de divulgação ou de pagamento de qualquer quantia adicional por parte da MANDATÁRIA, desde que não se utilize de direitos do Participante contemplado após a expiração do prazo mencionado. Caso desejarem, a <strong>MANDATÁRIA</strong> e o Participante contemplado, poderão, de mútuo acordo, firmar contratos específicos para licença de tais direitos por prazo superior a 12 (doze) meses contados da apuração dos resultados.
//                 </p>
//                 <p>
//                 <strong>15.7.</strong>	Visando a comprovação de regularidade, o Participante deverá guardar consigo os documentos que possam atestar a propriedade do Veículo Participante no momento da realização do Serviço do Recall, apresentando-os sempre que solicitado. O Participante sorteado poderá perder, a critério da MANDATÁRIA, o direito ao prêmio se deixar de apresentar, no prazo estabelecido de 03 (três) dias úteis, a contar do efetivo contato da MANDATÁRIA, todos os documentos comprobatórios da regularidade de sua participação.
//                 </p>

//                 <p>
//                 <strong>15.8.</strong>	Presume-se que o contemplado não tenha qualquer embaraço fiscal, legal ou outro que o impeça de receber e/ou usufruir do prêmio distribuído.
//                 </p>
//                 <p>
//                 <strong>15.9.</strong>	O Participante contemplado concorda que deverá se abster de utilizar as marcas da <strong>MANDATÁRIA</strong> sem a prévia e expressa autorização.
//                 </p>
//                 <p>
//                 <strong>15.10.</strong>	A empresa deverá encaminhar à SECAP/ME a Lista de Participantes contendo nomes e números da sorte distribuídos, após o término de cada período de participação e antes da extração da Loteria.
//                 </p>

//                 <p className="ml30">
//                 <strong>15.10.1.</strong>	O Participante declara estar plena e irrestritamente ciente e de acordo com o exposto no item 15.10, de modo que a sua participação na Promoção mediante aceitação ao presente Regulamento, será interpretada como manifestação inequívoca de sua ciência e concordância com tal compartilhamento junto ao órgão autorizador.
//                 </p>

//                 <p>
//                 <strong>15.11.</strong>	Fica desde já eleito o foro da Comarca de domicílio do Participante para solução de quaisquer questões referentes ao Regulamento desta Promoção.
//                 </p>


//                 <br/>
//                 <p className="bold">16. TERMO DE RESPONSABILIDADE</p>

//                 <p>
//                 Poderá participar da promoção qualquer consumidor que preencha os requisitos estipulados no regulamento da campanha autorizada;
//                 </p>

//                 <p>
//                 Os prêmios não poderão ser convertidos em dinheiro;
//                 </p>
//                 <p>
//                 É vedada a apuração por meio eletrônico;
//                 </p>
//                 <p>
//                 Os prêmios serão entregues em até 30 dias da data da apuração/sorteio, sem qualquer ônus aos contemplados
//                 </p>

//                 <p>
//                 Quando o prêmio sorteado, ganho em concurso ou conferido mediante vale-brinde, não for reclamado no prazo de cento e oitenta (180) dias, contados, respectivamente, da data do sorteio, da apuração do resultado do concurso ou do término do prazo da promoção, caducará o direito do respectivo titular e o valor correspondente será recolhido, pela empresa autorizada, ao Tesouro Nacional, como renda da União, no prazo de quarenta e cinco (45) dias;
//                 </p>
//                 <p>
//                 Em caso de promoções com participação de menor de idade, sendo este contemplado, deverá, no ato da entrega do prêmio, ser representado por seu responsável legal, à exceção das promoções comerciais realizadas por concessionária ou permissionária de serviço de radiodifusão, nos termos do artigo 1º-A, § 3º, da Lei 5.768, de 20 de dezembro de 1971;
//                 </p>
//                 <p>
//                 A divulgação da imagem dos contemplados poderá ser feita até um ano após a apuração da promoção comercial;
//                 </p>
//                 <p>
//                 As dúvidas e controvérsias oriundas de reclamações dos participantes serão, primeiramente, dirimidas pela promotora, persistindo-as, estas deverão ser submetidas à Secap/ME;
//                 </p>

//                 <p>
//                 Os órgãos locais de defesa do consumidor receberão as reclamações devidamente fundamentadas;
//                 </p>
//                 <p>
//                 A prestação de contas deverá ser realizada no prazo máximo de trinta dias após a data de prescrição dos prêmios sob pena de descumprimento do plano de distribuição de prêmios;
//                 </p>
//                 <p>
//                 O regulamento deverá ser afixado em lugar de ampla visibilidade e se apresentar em tamanho e em grafia que viabilizem a compreensão e visualização por parte do consumidor participante da promoção comercial;
//                 </p>


//                 <p>
//                 Além dos termos acima, a promoção comercial deverá obedecer às condições previstas na Lei nº 5.768, de 1971, no Decreto nº 70.951, de 1972, Portaria MF nº 41, de 2008, Portaria MF nº 67, de 2017, Portaria MF nº 422 de 2013, Portaria Seae/MF nº 88 de 2000, e em atos que as complementarem.
//                 </p>
//                 <p>
//                 Para as modalidades "Sorteio" e "Assemelhada a Sorteio" a empresa deverá anexar a Lista de Participantes na aba "Apurações", contendo nomes e números da sorte distribuídos, após o término de cada período de participação e antes da extração da Loteria. O arquivo deverá ser no formato .csv e cada arquivo poderá ter até 15MB.
//                 </p>
//                 <p>
//                 A infringência às cláusulas do Termo de Responsabilidade e do Regulamento constituem descumprimento do plano de operação e ensejam as penalidades previstas no artigo 13 da Lei nº. 5.768, de 1971
//                 </p>


//             </div>

//             <div className="col-12 text-center">
//                 <button className="btn btn-primary" onClick={downloadReg}>
//                     <img src={print} alt="icon download" style={{width: "30px"}}/> Download
//                 </button>
//             </div>
//         </div>

    )
}

export default RegulamentoConteundoSorteio;