import React, { Fragment, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import bannerComoFunciona from "../../assets/black/bannerPrincipal.png";
import bannerMobile from "../../assets/black/bannerMobilePrincipal.png";
import "./comoFunciona.scss";

import bannerImgSorteioV2 from "../../assets/img/banners/sorteio-004.png";
import bannerImgSorteioMobileV2 from "../../assets/img/banners/sorteio-004b.png";

function ComoFunciona(props) {
  return (
    <Fragment>
      {/* <Banner imgTitulo={bannerTitle} imgMain={bannerImg} igmBg={bannerBg} type={'car'} />  */}
      {/* <a target="_blank" rel="noopener noreferrer" ><img src={bannerImgSorteioV2} alt="banner" className="d-none d-md-block d-lg-block responsive mAuto" /></a>
                <a target="_blank" rel="noopener noreferrer" > <img src={bannerImgSorteioMobileV2} alt="banner" className="d-block d-md-none d-lg-none responsive mAuto" /></a> */}

      <div className="container contentDefault pb-4">
        <h1 className="col-12  pl-0">Como funciona</h1>
        <div className="bordaTitulo"></div>
      </div>

      <div className="bgMainFeature">
        <a target="_blank" rel="noopener noreferrer">
          <img
            src={bannerComoFunciona}
            alt="banner"
            className="d-none d-md-block responsive mAuto banner-home"
          />
        </a>
        <img
          src={bannerMobile}
          alt="banner"
          className="d-md-none d-block responsive mAuto"
        />
      </div>

      <div className="container contentDefault">
        <div className="row">
          <div className="col-12 mt30">
            <div className="col-12">
              <h2 className="tituloComoFunciona">
                Promoção “Peça Premiada Chevrolet”
              </h2>
            </div>

            <div className="col-12 text-justify">
              <h2> Passo a Passo para participar:</h2>
              <p>
                <strong>1. </strong>Adquira Peças que são GM{" "}
                <i>“Genuine Parts”</i> Chevrolet nos canais permitidos (conforme
                opções disponíveis em regulamento).
              </p>
              <br></br>
              <p>
                <strong>2.</strong> Abra sua caixa de e-mails e salve o arquivo
                da NFe (Nota Fiscal Eletrônica) em PDF que a concessionária terá
                enviado para o e-mail disponibilizado no ato da compra.
                Verifique na sua caixa de e-mail (verifique sempre a caixa de
                spam/lixo eletrônico) o e-mail enviado pela concessionária,
                referente a compra realizada. Salve o arquivo PDF da NFe (Nota
                Fiscal Eletrônica). Para efetuar o Cadastro da NF no Hotsite da
                Promoção, é Importante que o arquivo seja eletrônico. Em alguns
                casos, será necessário inserir a chave de acesso da nota. OBS:
                Imagens/fotografias no formato JPG e PNG podem demorar mais
                tempo para aprovação.
              </p>

              <br></br>
              <p>
                <strong>3.</strong> Realize o seu cadastro no Hotsite da
                campanha Promoção{" "}
                <a
                  href="https://www.pecapremiada.com.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  www.pecapremiada.com.br
                </a>, ao seguir o fluxo disponível, dê o “aceite” nas normas e
                regulamento da Promoção e realize o upload da(s) sua(s) NFe(s)
                ou Cupom(ns) Fiscal(is) referente(s) a(s) Peça(s) adquirida(s).
              </p>
              <br></br>
              <p>
                <strong>4.</strong> Finalizado o procedimento de upload da sua
                NF ou Cupom Fiscal, a Empresa Promotora realizará o processo de
                validação da Nota Fiscal ou Cupom Fiscal cadastrado e,
                finalizado este processo de validação, você receberá um e-mail
                informando o status final da Nota ou Cupom Fiscal.
              </p>
              <br></br>
              <p>
                <strong>5.</strong> Após a validação da(s) NF(s) ou Cupom
                Fiscal, você recebe sua(s) chance(s) – na aba de “Peça
                Premiada”, em sua área logada, clique em “Testar Minha Sorte”,
                onde você poderá consultar suas chances, girar a roleta e
                verificar se foi contemplado ou não com 1 (um) dos brindes
                disponíveis, conforme exemplificado na FAQ e regras disponíveis
                no Regulamento.
              </p>
              <br></br>
              <p>
                <strong>6.</strong> Confira todos os detalhes na FAQ e
                Regulamento assemelhado a Vale-Brinde desta Promoção,
                disponíveis no Hotsite, na aba “Regulamento”.
              </p>
              <br></br>
              <p>
                <strong>7.</strong> Você poderá visualizar todas as informações
                e acompanhar a sua participação dentro do ambiente logado no
                Hotsite. Caso seja contemplado, além de visualizar na aba “Peça
                Premiada” também receberá todas as informações e orientações por
                e-mail.
              </p>
              <br></br>
              <p>
                <strong>8.</strong> Boa sorte!
              </p>
            </div>
          </div>

          {/* <div className="col-12">
            <div></div>
            <div className="col-12 text-center ">
              <div className="mt30">
                <Link to="/cadastro">
                  <button className="btn btn-primary btn-lg">
                    FAZER CADASTRO
                  </button>
                </Link>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </Fragment>
  );
}

export default connect()(ComoFunciona);
