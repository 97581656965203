import React, { useState } from "react";
import "./cadastro.scss";
import Checkbox from "@material-ui/core/Checkbox";

import Mask from "../../services/mask";
import Utils from "../../services/serviceUtils";
import { useEffect } from "react";
import ApiServices from "../../services/apiServices";
import * as moment from "moment";
import Regulamento from "../../components/Docs/RegulamentoBrinde";
import RegulamentoSorteio from "../../components/Docs/RegulamentoSorteio";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  showMsgS,
  showMsgW,
  showMsgE,
} from "../../store/actions/snackbarActions.js";
import Storage from "../../services/storage";
import { Redirect } from "react-router-dom";
import verSenha from "../../assets/img/icons/ver.png";
import naoVerSenha from "../../assets/img/icons/naover.png";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

function Cadastro(props) {
  const [codigoInput, setCodigoInput] = useState("");
  const [codigoValido, setCodigoValido] = useState(false);
  const [open, setOpen] = useState(0);
  const [revelar1, setRevelar1] = useState(false);
  const [revelar2, setRevelar2] = useState(false);
  const [count, setCount] = useState(0);
  const [etapaControle, setEtapaControle] = useState([
    false,
    false,
    false,
    false,
  ]);
  const [liberarBtSalvar, setLiberarBtSalvar] = useState(false);
  const [ufs, setUfs] = useState([]);
  const [cepValidado, setCepValidado] = useState(false);
  const [cidades, setCidades] = useState([]);
  const [usuarioCadastrado, setUsuarioCadastrado] = useState(false);
  const [usuarioAtualizado, setUsuarioAtualizado] = useState(false);
  const [infoLogado, setInfoLogado] = useState([]);
  const [conheceuCampanha, setConheceuCampanha] = useState([]);

  const [alertaCampo, setAlertaCampo] = useState({
    cpf: {
      status: "d-none",
      alerta: "CPF inválido",
    },
    email: {
      status: "d-none",
      alerta: "E-mail inválido",
    },
    celular: {
      status: "d-none",
      alerta: "Número de celular inválido",
    },
    dataNascimento: {
      status: "d-none",
      alerta: "Data invalida",
    },
    cep: {
      status: "d-none",
      alerta: "CEP inválido",
    },
    confirmPassword: {
      status: "d-none",
      alerta: "Senha invalida",
    },
  });

  function msg(tipo, text) {
    //S W E
    if (tipo == "s") props.showMsgS(text);
    if (tipo == "w") props.showMsgW(text);
    if (tipo == "e") props.showMsgE(text);
  }

  const [clienteNovo, setClienteNovo] = useState({
    nome: "",
    cpf: "",
    email: "",
    phoneNumber: "",
    dataNascimento: "",
    cep: "",
    logradouro: "",
    numero: "",
    complemento: "",
    bairro: "",
    cidadeId: "",
    cidadeNome: "",
    ufId: "",
    sigla: "",
    password: "",
    confirmPassword: "",
    regulamento: false,
    // regulamentoSorteio: false,
    voltagem: "",
    comoConheceuCampanha: "",
  });

  function fnBtProsseguir() {
    setCodigoInput("");
    const initAlterar = {
      id: clienteNovo.id,
      nome: clienteNovo.nome,
      cpf: Mask.unMask(clienteNovo.cpf),
      email: clienteNovo.email,
      phoneNumber: Mask.unMask(clienteNovo.phoneNumber),
      dataNascimento: moment(clienteNovo.dataNascimento, "DD-MM-YYYY").format(
        "YYYY-MM-DD"
      ),
      cep: Mask.unMask(clienteNovo.cep),
      logradouro: clienteNovo.logradouro,
      numero: clienteNovo.numero,
      complemento: clienteNovo.complemento,
      bairro: clienteNovo.bairro,
      cidadeId: clienteNovo.cidadeId,
      ufId: clienteNovo.ufId,
      password: clienteNovo.password,
      confirmPassword: clienteNovo.confirmPassword,
      regulamento: clienteNovo.regulamento,
      preferenciaContemplado: clienteNovo.voltagem,
      conheceuCampanhaId: clienteNovo.comoConheceuCampanha,
      // regulamentoSorteio: clienteNovo.regulamentoSorteio,
    };

    ApiServices.apiPut(
      "participantes/eumeusdadoscomcodigo/" + codigoInput,
      initAlterar
    ).then((res) => {
      if (res.status == 200) {
        msg("s", "Cadastro atualizado com sucesso!");
        setUsuarioAtualizado(true);

        setCodigoValido(true);
        fnBtSalvar();
      } else {
        setOpen(2);
      }
    });
  }

  function fnBtSalvar() {
    const initNovo = {
      nome: clienteNovo.nome,
      cpf: Mask.unMask(clienteNovo.cpf),
      email: clienteNovo.email,
      phoneNumber: Mask.unMask(clienteNovo.phoneNumber),
      dataNascimento: moment(clienteNovo.dataNascimento, "DD-MM-YYYY").format(
        "YYYY-MM-DD"
      ),
      cep: Mask.unMask(clienteNovo.cep),
      logradouro: clienteNovo.logradouro,
      numero: clienteNovo.numero,
      complemento: clienteNovo.complemento,
      bairro: clienteNovo.bairro,
      cidadeId: clienteNovo.cidadeId,
      ufId: clienteNovo.ufId,
      password: clienteNovo.password,
      confirmPassword: clienteNovo.confirmPassword,
      regulamento: clienteNovo.regulamento,
      preferenciaContemplado:
        clienteNovo.voltagem === false || clienteNovo.voltagem === "false"
          ? false
          : clienteNovo.voltagem === true || clienteNovo.voltagem === "true"
          ? true
          : null,
      conheceuCampanhaId: clienteNovo.comoConheceuCampanha,
      // regulamentoSorteio: clienteNovo.regulamentoSorteio,
    };

    function postFetch() {
      if (!moment(clienteNovo.dataNascimento, "DD-MM-YYYY").isValid()) {
        msg("w", "Data de nascimento inválida.");
        return;
      }

      const initAlterar = {
        id: clienteNovo.id,
        nome: clienteNovo.nome,
        cpf: Mask.unMask(clienteNovo.cpf),
        email: clienteNovo.email,
        phoneNumber: Mask.unMask(clienteNovo.phoneNumber),
        dataNascimento: moment(clienteNovo.dataNascimento, "DD-MM-YYYY").format(
          "YYYY-MM-DD"
        ),
        cep: Mask.unMask(clienteNovo.cep),
        logradouro: clienteNovo.logradouro,
        numero: clienteNovo.numero,
        complemento: clienteNovo.complemento,
        bairro: clienteNovo.bairro,
        cidadeId: clienteNovo.cidadeId,
        ufId: clienteNovo.ufId,
        password: clienteNovo.password,
        confirmPassword: clienteNovo.confirmPassword,
        regulamento: clienteNovo.regulamento,
        preferenciaContemplado:
          clienteNovo.voltagem === false || clienteNovo.voltagem === "false"
            ? false
            : clienteNovo.voltagem === true || clienteNovo.voltagem === "true"
            ? true
            : null,
        conheceuCampanhaId: clienteNovo.comoConheceuCampanha,
        // regulamentoSorteio: clienteNovo.regulamentoSorteio,
      };

      if (Storage.getUser() == null) {
        ApiServices.apiPost("participantes/cliente", initNovo).then((data) => {
          if (data.success === true) {
            msg("s", data.data);
            setUsuarioCadastrado(data.success);
          } else {
            msg("w", data.errors[0]);
          }
        });
      }

      if (Storage.getUser() != null) {
        if (!codigoValido)
          ApiServices.apiPutReturnJson(
            "participantes/eumeusdados",
            initAlterar
          ).then(function (data) {
            if (data.success) setOpen(1);
            else msg("e", data.errors.join("\r\n"));
          });

        if (codigoValido) {
          ApiServices.apiPutReturnJson(
            "participantes/cliente/alterar",
            initAlterar
          ).then((data) => {
            if (data.success === true) {
              msg("s", "Cadastro atualizado com sucesso!");

              const init = {
                userName: clienteNovo.email,
                password: clienteNovo.password,
              };

              ApiServices.apiPost("participantes/entrar", init).then((data) => {
                Storage.del("DEVP_USER_INFOS");
                Storage.set("DEVP_USER_INFOS", data.data);
                setLiberarBtSalvar(false);
                window.location.reload();
              });
            } else {
              msg("w", data.errors[0]);
              setLiberarBtSalvar(false);
            }
          });
        }
      }
    }

    if (
      Utils.validaCPF(clienteNovo.cpf) == true &&
      clienteNovo.email.includes("@") == true &&
      Mask.unMask(clienteNovo.phoneNumber).length >= 10 &&
      Mask.unMask(clienteNovo.dataNascimento).length === 8 &&
      Mask.unMask(clienteNovo.cep).length === 8 &&
      clienteNovo.password === clienteNovo.confirmPassword
    ) {
      postFetch();
    }

    if (Utils.validaCPF(clienteNovo.cpf) == false) {
      setAlertaCampo((prev) => ({
        ...prev,
        cpf: { status: "", alerta: "CPF inválido" },
      }));
    }
    if (clienteNovo.email.includes("@") == false) {
      setAlertaCampo((prev) => ({
        ...prev,
        email: { status: "", alerta: "E-mail inválido" },
      }));
    }
    if (Mask.unMask(clienteNovo.phoneNumber).length < 10) {
      setAlertaCampo((prev) => ({
        ...prev,
        celular: { status: "", alerta: "Número de celular inválido" },
      }));
    }
    if (Mask.unMask(clienteNovo.dataNascimento).length !== 8) {
      setAlertaCampo((prev) => ({
        ...prev,
        dataNascimento: { status: "", alerta: "Data de nascimento inválida" },
      }));
    }
    if (Mask.unMask(clienteNovo.cep).length !== 8) {
      setAlertaCampo((prev) => ({
        ...prev,
        cep: { status: "", alerta: "CEP inválido" },
      }));
    }
    if (clienteNovo.password !== clienteNovo.confirmPassword) {
      setAlertaCampo((prev) => ({
        ...prev,
        confirmPassword: { status: "", alerta: "Senha inválida" },
      }));
    }
  }

  function getFetch() {
    ApiServices.apiGetCidade(
      "localizacoes/cep/" + clienteNovo.cep.replace("-", "")
    ).then((data) => {
      if (data.success === true) {
        setCepValidado(true);
        setClienteNovo((prev) => ({
          ...prev,

          logradouro: data.data.logradouro,
          bairro: data.data.bairro,
          cidadeId: data.data.cidade.cidadeId,
          cidadeNome: data.data.cidade.nome,
          ufId: data.data.uf.ufId,
          sigla: data.data.uf.sigla,
        }));
      } else {
        setCepValidado(false);
      }
    });
  }

  function getConheceuCampanha() {
    ApiServices.apiGetSemToken("participantes/conheceu/campanha").then(
      (data) => {
        setConheceuCampanha(data.data);
      }
    );
  }
  useEffect(() => {
    getConheceuCampanha();
  }, []);
  function getFetchCidade() {
    ApiServices.apiGetCidade("localizacoes/cidades/" + clienteNovo.ufId).then(
      (data) => {
        setCidades(data.data);
      }
    );
  }

  function fnEtapaSenha() {
    if (clienteNovo.password.length >= 8) {
      etapaControle[0] = true;
    } else {
      etapaControle[0] = false;
    }

    if (clienteNovo.password.replace(/[^0-9]/g, "").length > 0) {
      etapaControle[1] = true;
    } else {
      etapaControle[1] = false;
    }

    if (
      clienteNovo.password.replace(/[^a-z]/g, "").length > 0 &&
      clienteNovo.password.replace(/[^A-Z]/g, "").length > 0
    ) {
      etapaControle[2] = true;
    } else {
      etapaControle[2] = false;
    }

    if (clienteNovo.password.replace(/[a-zA-Z0-9]/g, "").length > 0) {
      etapaControle[3] = true;
    } else {
      etapaControle[3] = false;
    }

    var c = 0;
    for (var x = 0; x <= etapaControle.length; x++) {
      if (etapaControle[x] == true) c++;
    }
    setCount(c);
  }

  useEffect(() => {
    if (
      clienteNovo.nome.length > 0 &&
      clienteNovo.email.length > 0 &&
      clienteNovo.cpf.length > 0 &&
      clienteNovo.phoneNumber.length > 0 &&
      clienteNovo.dataNascimento.length > 0 &&
      clienteNovo.cep.length > 0 &&
      clienteNovo.regulamento === true &&
      // clienteNovo.regulamentoSorteio === true &&
      (clienteNovo.voltagem == false || clienteNovo.voltagem == true) &&
      clienteNovo.comoConheceuCampanha != ""
    ) {
      setLiberarBtSalvar(false);
    } else {
      setLiberarBtSalvar(true);
    }
  }, [clienteNovo]);

  useEffect(() => {
    if (clienteNovo.ufId != "") {
      getFetchCidade();
    }
  }, [clienteNovo.ufId]);

  useEffect(() => {
    ApiServices.apiGetCidade("localizacoes/ufs").then((data) => {
      setUfs(data.data);
    });
  }, []);

  useEffect(() => {
    fnEtapaSenha();
  }, [clienteNovo.password]);

  useEffect(() => {
    if (clienteNovo.cep.replace("-", "").length === 8) {
      getFetch();
    }
  }, [clienteNovo.cep, clienteNovo.ufId]);

  useEffect(() => {
    if (Storage.getUser() != null) {
      ApiServices.apiGetWithIdAndToken(
        "participantes/cliente",
        Storage.getUser().userClaims.id
      ).then((res) => {
        setInfoLogado(res.data);
        setClienteNovo({
          id: res.data.id,
          nome: res.data.nome,
          cpf: res.data.cpf,
          email: res.data.email,
          phoneNumber: res.data.phoneNumber,
          dataNascimento: moment(res.data.dataNascimento).format("DD/MM/YYYY"),
          cep: res.data.cep,
          logradouro: res.data.logradouro,
          numero: res.data.numero,
          complemento: res.data.complemento,
          bairro: res.data.bairro,
          cidadeId: res.data.cidadeId,
          cidadeNome: "",
          ufId: res.data.ufId,
          sigla: "",
          password: "",
          confirmPassword: "",
          regulamento: true,
          voltagem:
            res.data.preferenciaContemplado === true ||
            res.data.preferenciaContemplado === "true"
              ? "true"
              : res.data.preferenciaContemplado === false ||
                res.data.preferenciaContemplado === "false"
              ? "false"
              : "",
          comoConheceuCampanha: res.data.conheceuCampanhaId,
          // regulamentoSorteio: true,
        });
      });
    }
  }, []);
  function fnRevelar1() {
    if (revelar1 == false) {
      setRevelar1(true);
    }
    if (revelar1 == true) {
      setRevelar1(false);
    }
  }
  function fnRevelar2() {
    if (revelar2 == false) {
      setRevelar2(true);
    }
    if (revelar2 == true) {
      setRevelar2(false);
    }
  }
  // useEffect(() => {
  //   if (clienteNovo.email.includes("naotem")) {
  //     setClienteNovo((prev) => ({
  //       ...prev,
  //       email: "",
  //     }));
  //     msg("w", "Favor inserir e-mail válido");
  //   }
  // }, [clienteNovo.email]);
  return (
    <div className="container">
      <div className="row">
        <Dialog
          open={open == 1}
          maxWidth={"sm"}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Confirmação de cadastro
            <>
              <IconButton
                aria-label="close"
                onClick={() => {
                  setOpen(0);
                  setLiberarBtSalvar(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </>
          </DialogTitle>
          <DialogContent className="d-flex align-items-center flex-column text-center">
            <h5>Enviamos um código de validação para o e-mail cadastrado. </h5>
            <h5>Confirme para prosseguirmos com a atualização:</h5>
            <br />
            <input
              type="text"
              onChange={(e) => setCodigoInput(e.target.value)}
              value={codigoInput}
            />
            <br />
            <button
              className="btn btn-primary"
              onClick={() => {
                setOpen(0);
                fnBtProsseguir();
              }}
            >
              Prosseguir
            </button>
          </DialogContent>
        </Dialog>

        <Dialog
          open={open == 2}
          maxWidth={"sm"}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Dados inválidos
            <IconButton
              aria-label="close"
              onClick={() => {
                setOpen(0);
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className="d-flex align-items-center flex-column text-center">
            <h5>
              Código de validação inválido. Clique abaixo para enviar um novo
              código.
            </h5>
            <br />

            <button
              className="btn btn-primary"
              onClick={() => {
                fnBtSalvar();
              }}
            >
              Enviar e-mail novamente
            </button>
          </DialogContent>
        </Dialog>

        <div className="row justify-content-center">
          <div className="col-11">
            <br />
            <h1>Cadastro</h1>
            <br />

            <h4 className="">Dados pessoais</h4>
            <p className="mt-4 mb-4  textos-complementares">
              Verifique atentamente os dados preenchidos.
            </p>

            <div className="row">
              <div className=" col-12 form-outline mb-3">
                <label className="form-label m0">Nome Completo</label>
                <input
                  className="form-control"
                  type="text"
                  id="nome"
                  value={clienteNovo.nome}
                  onChange={(e) => {
                    e.persist();
                    setClienteNovo((prev) => ({
                      ...prev,
                      nome: e.target.value,
                    }));
                  }}
                />
              </div>

              <div className=" col-12 form-outline mb-3">
                <div className="d-flex flex-wrap  align-items-end  ">
                  <label className="form-label  mr-5 ">E-mail</label>
                  <button
                    className="m-2 btn btn-outline-secondary btn-sm"
                    disabled={
                      clienteNovo.email.includes("@gmail.com") ||
                      clienteNovo.email.includes("@yahoo.com") ||
                      clienteNovo.email.includes("@hotmail.com") ||
                      clienteNovo.email.includes("@outlook.com")
                    }
                    onClick={() => {
                      setClienteNovo((prev) => ({
                        ...prev,
                        email: clienteNovo.email + "@gmail.com",
                      }));
                    }}
                  >
                    @gmail.com
                  </button>
                  <button
                    className="m-2 btn btn-outline-secondary btn-sm"
                    disabled={
                      clienteNovo.email.includes("@gmail.com") ||
                      clienteNovo.email.includes("@yahoo.com") ||
                      clienteNovo.email.includes("@hotmail.com") ||
                      clienteNovo.email.includes("@outlook.com")
                    }
                    onClick={() => {
                      setClienteNovo((prev) => ({
                        ...prev,
                        email: clienteNovo.email + "@yahoo.com",
                      }));
                    }}
                  >
                    @yahoo.com
                  </button>
                  <button
                    className="m-2 btn btn-outline-secondary btn-sm"
                    disabled={
                      clienteNovo.email.includes("@gmail.com") ||
                      clienteNovo.email.includes("@yahoo.com") ||
                      clienteNovo.email.includes("@hotmail.com") ||
                      clienteNovo.email.includes("@outlook.com")
                    }
                    onClick={() => {
                      setClienteNovo((prev) => ({
                        ...prev,
                        email: clienteNovo.email + "@hotmail.com",
                      }));
                    }}
                  >
                    @hotmail.com
                  </button>
                  <button
                    className="m-2 btn btn-outline-secondary btn-sm"
                    disabled={
                      clienteNovo.email.includes("@gmail.com") ||
                      clienteNovo.email.includes("@yahoo.com") ||
                      clienteNovo.email.includes("@hotmail.com") ||
                      clienteNovo.email.includes("@outlook.com")
                    }
                    onClick={() => {
                      setClienteNovo((prev) => ({
                        ...prev,
                        email: clienteNovo.email + "@outlook.com",
                      }));
                    }}
                  >
                    @outlook.com
                  </button>
                </div>
                <input
                  className="form-control"
                  type="text"
                  id="email"
                  value={clienteNovo.email}
                  onChange={(e) => {
                    alertaCampo.email.status = "d-none";
                    e.persist();
                    setClienteNovo((prev) => ({
                      ...prev,
                      email: e.target.value,
                    }));
                  }}
                />
                <p className={alertaCampo.email.status}>
                  <em>{alertaCampo.email.alerta}</em>
                </p>
              </div>

              <div className="col-md-4 col-12 form-outline mb-3">
                <label className="form-label m0">CPF</label>

                <input
                  className="form-control"
                  type="text"
                  id="cpf"
                  placeholder="000.000.000-00"
                  value={Mask.cpf(clienteNovo.cpf)}
                  onChange={(e) => {
                    alertaCampo.cpf.status = "d-none";
                    e.persist();
                    setClienteNovo((prev) => ({
                      ...prev,
                      cpf: Mask.cpf(e.target.value),
                    }));
                  }}
                />

                <p className={alertaCampo.cpf.status}>
                  <em>{alertaCampo.cpf.alerta}</em>
                </p>
              </div>

              <div className="col-md-4 col-12 form-outline mb-3">
                <label className="form-label m0">Celular</label>
                <input
                  className="form-control"
                  type="text"
                  id="phoneNumber"
                  placeholder="(00) 00000-0000"
                  value={Mask.cel(clienteNovo.phoneNumber)}
                  onChange={(e) => {
                    alertaCampo.celular.status = "d-none";
                    e.persist();
                    setClienteNovo((prev) => ({
                      ...prev,
                      phoneNumber: Mask.cel(e.target.value),
                    }));
                  }}
                />
                <p className={alertaCampo.celular.status}>
                  <em>{alertaCampo.celular.alerta}</em>
                </p>
              </div>

              <div className="col-md-4 col-12 form-outline mb-3">
                <label className="form-label m0">Data de nascimento</label>
                <input
                  className="form-control"
                  type="text"
                  id="dataNascimento"
                  placeholder="dd/mm/aaaa"
                  value={clienteNovo.dataNascimento}
                  onChange={(e) => {
                    alertaCampo.dataNascimento.status = "d-none";
                    e.persist();
                    setClienteNovo((prev) => ({
                      ...prev,
                      dataNascimento: Mask.date(e.target.value),
                    }));
                  }}
                />
                <p className={alertaCampo.dataNascimento.status}>
                  <em>{alertaCampo.dataNascimento.alerta}</em>
                </p>
              </div>
            </div>

            <p className="mt-4 mb-4 textos-complementares">
            Para cadastro de empresas, o CNPJ será solicitado apenas no momento de envio de NFs.
            </p>
            <div className="row">
              <div className="col-md-4">
                <p className="mt-4 textos-complementares">
                  Preferência caso seja contemplado
                </p>
                <div className="radioTensao">
                  <RadioGroup
                    value={
                      clienteNovo.voltagem === true ||
                      clienteNovo.voltagem === "true"
                        ? "true"
                        : clienteNovo.voltagem === false ||
                          clienteNovo.voltagem === "false"
                        ? "false"
                        : ""
                    }
                    onChange={(e) => {
                      setClienteNovo((prev) => ({
                        ...prev,
                        voltagem: e.target.value === "true" ? true : false,
                      }));
                    }}
                    row
                  >
                    <FormControlLabel
                      className="ml-0"
                      value="true"
                      control={<Radio />}
                      disabled={window.location.href.includes("meus-dados")}
                      label="127v"
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      disabled={window.location.href.includes("meus-dados")}
                      label="220v"
                      labelPlacement="start"
                    />
                  </RadioGroup>
                </div>
              </div>

              <div className=" mt-4 col-md-4 form-outline">
                <label className="form-label m0">
                  Como conheceu a campanha?
                </label>

                <select
                  style={{ border: "2px solid  #A6A6A6" }}
                  className="form-control col-12"
                  value={clienteNovo.comoConheceuCampanha}
                  disabled={window.location.href.includes("meus-dados")}
                  onChange={(e) => {
                    e.persist();
                    setClienteNovo((prev) => ({
                      ...prev,
                      comoConheceuCampanha: e.target.value,
                    }));
                  }}
                >
                  <option value={""}>Selecione</option>
                  {conheceuCampanha.map((e, i) => (
                    <option key={i} value={e.id}>
                      {e.nome}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <h4 className="mt-4">Endereço</h4>

            <p className="mt-4 mb-4 textos-complementares">
              Atenção ao preenchimento dos campos abaixo, pois esse será o
              endereço que utilizaremos para envio de brinde da campanha, caso
              seja contemplado. OBS: Endereço não poderá ser alterado
              futuramente (somente solicitando ao administrador).
            </p>
            <div className="row">
              <div className="col-md-4 col-12 form-outline mb-3">
                <label className="form-label m0">CEP</label>
                <input
                  className="form-control"
                  type="text"
                  id="cep"
                  placeholder="00000-000"
                  value={clienteNovo.cep}
                  disabled={window.location.href.includes("meus-dados")}
                  onChange={(e) => {
                    Mask.cep(e.target.value).length <= 8 &&
                      setCepValidado(false);

                    e.persist();
                    alertaCampo.cep.status = "d-none";
                    setClienteNovo((prev) => ({
                      ...prev,
                      cep: Mask.cep(e.target.value),
                    }));
                  }}
                />
                <p className={alertaCampo.cep.status}>
                  <em>{alertaCampo.cep.alerta}</em>
                </p>
              </div>

              <div className="col-md-8 col-12 form-outline mb-3">
                <label className="form-label m0">Logradouro</label>
                <input
                  className="form-control"
                  type="text"
                  id="logradouro"
                  value={clienteNovo.logradouro}
                  disabled={window.location.href.includes("meus-dados")}
                  onChange={(e) => {
                    e.persist();
                    setClienteNovo((prev) => ({
                      ...prev,
                      logradouro: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-2 col-12 form-outline mb-3">
                <label className="form-label m0">Número</label>
                <input
                  className="form-control"
                  type="text"
                  id="numero"
                  value={clienteNovo.numero}
                  disabled={window.location.href.includes("meus-dados")}
                  onChange={(e) => {
                    e.persist();
                    setClienteNovo((prev) => ({
                      ...prev,
                      numero: e.target.value,
                    }));
                  }}
                />
              </div>

              <div className="col-md-10 col-12 form-outline mb-3">
                <label className="form-label m0">Complemento</label>
                <input
                  className="form-control"
                  type="text"
                  id="complemento"
                  value={clienteNovo.complemento}
                  disabled={window.location.href.includes("meus-dados")}
                  onChange={(e) => {
                    e.persist();
                    setClienteNovo((prev) => ({
                      ...prev,
                      complemento: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-5 col-12 form-outline mb-3">
                <label className="form-label m0">Bairro</label>
                <input
                  className="form-control"
                  type="text"
                  autoComplete="off"
                  id="bairro"
                  value={clienteNovo.bairro}
                  disabled={window.location.href.includes("meus-dados")}
                  onChange={(e) => {
                    e.persist();
                    setClienteNovo((prev) => ({
                      ...prev,
                      bairro: e.target.value,
                    }));
                  }}
                />
              </div>

              <div className="col-md-3 col-sm-12 form-outline mb-3">
                <label className="form-label m0">UF</label>

                <select
                  style={{ border: "2px solid  #A6A6A6" }}
                  className="form-control col-12  "
                  disabled={window.location.href.includes("meus-dados")}
                  onChange={(e) => {
                    getFetchCidade();

                    e.persist();
                    setClienteNovo((prev) => ({
                      ...prev,
                      ufId: e.target.value,
                    }));
                  }}
                >
                  <option value={cepValidado === false ? "" : clienteNovo.ufId}>
                    {cepValidado === false
                      ? ufs.map((e) => e.id == clienteNovo.ufId && e.sigla)
                      : clienteNovo.sigla}
                  </option>

                  {cepValidado === false
                    ? ufs.map((e, i) => (
                        <option key={i} value={e.id}>
                          {e.sigla}{" "}
                        </option>
                      ))
                    : null}
                </select>
              </div>

              <div className="col-md-4 col-sm-12 form-outline mb-3">
                <label className="form-label m0">Municipio</label>

                <select
                  style={{ border: "2px solid  #A6A6A6" }}
                  className="form-control col-12"
                  disabled={window.location.href.includes("meus-dados")}
                  onChange={(e) => {
                    e.persist();
                    setClienteNovo((prev) => ({
                      ...prev,
                      cidadeId: e.target.value,
                    }));
                  }}
                >
                  <option
                    value={cepValidado === false ? "" : clienteNovo.cidadeId}
                  >
                    {cepValidado === false
                      ? cidades.map(
                          (e) => e.id == clienteNovo.cidadeId && e.nome
                        )
                      : clienteNovo.cidadeNome}
                  </option>

                  {cepValidado === false
                    ? cidades.map((e, i) => (
                        <option key={i} value={e.id}>
                          {e.nome}{" "}
                        </option>
                      ))
                    : null}
                </select>
              </div>
            </div>

            <h4>Configuração de acesso </h4>

            <div className="row">
              <div className="col-12 form-outline mb-3">
                <label className="form-label m0">Senha</label>
                <input
                  className="form-control"
                  type={revelar1 ? "text" : "password"}
                  id="password"
                  autoComplete="new-password"
                  onChange={(e) => {
                    e.persist();
                    setClienteNovo((prev) => ({
                      ...prev,
                      password: e.target.value,
                    }));
                  }}
                />

                <div className="olhoVerSenha" onClick={() => fnRevelar1()}>
                  {!revelar1 && (
                    <img src={verSenha} width="100%" alt="ver senha" />
                  )}
                  {revelar1 && (
                    <img src={naoVerSenha} width="100%" alt="ver senha" />
                  )}
                </div>

                {/* <div className="row col-12 justify-content-end m0">
                                <img className=" position-relative" src={olho} alt='eye' />
                            </div> */}
              </div>
              <p className="col-12">
                <strong>Força da sua senha </strong>
              </p>
              <div className="col-12 d-flex justify-content-between">
                <div
                  className={
                    count >= 1 ? "forcaSenha1 forcaSenhaAtiva" : "forcaSenha1"
                  }
                ></div>
                <div
                  className={
                    count >= 2 ? "forcaSenha2 forcaSenhaAtiva" : "forcaSenha2"
                  }
                ></div>
                <div
                  className={
                    count >= 3 ? "forcaSenha3 forcaSenhaAtiva" : "forcaSenha3"
                  }
                ></div>
                <div
                  className={
                    count >= 4 ? "forcaSenha4 forcaSenhaAtiva" : "forcaSenha4"
                  }
                ></div>
              </div>
              <div>
                <br />

                <p className="mb-0">• Conter no mínimo 8 caracteres</p>
                <p className="mb-0 mt-0">• Conter números</p>
                <p className="mb-0 mt-0">
                  • Conter letras maiúsculas e minúsculas
                </p>
                <p className="mt-0">• Conter caracteres especiais</p>
                <br />
              </div>
              <div className="col-12 form-outline mb-3">
                <label className="form-label m0">Confirmar senha</label>
                <input
                  className="form-control"
                  type={revelar2 ? "text" : "password"}
                  id="confirmPassword"
                  autoComplete="new-password"
                  onChange={(e) => {
                    alertaCampo.confirmPassword.status = "d-none";
                    e.persist();
                    setClienteNovo((prev) => ({
                      ...prev,
                      confirmPassword: e.target.value,
                    }));
                  }}
                />

                <div className="olhoVerSenha" onClick={() => fnRevelar2()}>
                  {!revelar2 && (
                    <img src={verSenha} width="100%" alt="ver senha" />
                  )}
                  {revelar2 && (
                    <img src={naoVerSenha} width="100%" alt="ver senha" />
                  )}
                </div>

                <p className={alertaCampo.confirmPassword.status}>
                  <em>{alertaCampo.confirmPassword.alerta}</em>
                </p>
              </div>

              <div>
                {/* <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="checkboxContainer">
                      <Checkbox
                        checked={
                          Storage.getUser() !== null
                            ? true
                            : clienteNovo.regulamentoSorteio
                        }
                        onChange={(e) => {
                          e.persist();

                          setClienteNovo((prev) => ({
                            ...prev,
                            regulamentoSorteio: e.target.checked,
                          }));
                        }}
                      />

                      <p>
                        Li e aceito o
                        <RegulamentoSorteio />
                      </p>
                    </div>
                  </div>
                </div> */}
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="checkboxContainer">
                      <Checkbox
                        checked={
                          Storage.getUser() !== null
                            ? true
                            : clienteNovo.regulamento
                        }
                        onChange={(e) => {
                          e.persist();

                          setClienteNovo((prev) => ({
                            ...prev,
                            regulamento: e.target.checked,
                          }));
                        }}
                      />

                      <p>
                        Li e aceito o
                        <Regulamento />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 d-flex justify-content-center ">
                <button
                  className={`btn ${
                    liberarBtSalvar
                      ? "btn-outline-secondary"
                      : "btn-outline-primary"
                  } py-2 px-5 mt-4 mb-5`}
                  disabled={liberarBtSalvar}
                  onClick={() => {
                    setLiberarBtSalvar(true);
                    fnBtSalvar();
                  }}
                >
                  SALVAR
                </button>
                {usuarioCadastrado == true && <Redirect to="/login" />}
                {usuarioAtualizado == true && <Redirect to="/" />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (store) => ({
  snackbarState: store.snackbarState,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ showMsgS, showMsgW, showMsgE }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Cadastro);
