import React, { useState, useRef, useEffect } from "react";

import { useHistory } from "react-router-dom";

import "./styles.cadastro.scss";
import ApiServices from "../../services/apiServices";

import Mask from "../../services/mask";
import CardContent from "@material-ui/core/CardContent";
import Utils from "../../services/serviceUtils";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import imgNf from "../../assets/img/numeroNF.png";
import ValorTotal from "../../assets/img/valor01.png";
import imgEmissao from "../../assets/img/dataEmissao.png";
import imgCnpj from "../../assets/img/Cnpj.png";
import imgInfoMeuCNPJ from "../../assets/img/imgInfoMeuCNPJ.png";
import imgRazao from "../../assets/img/razaoSocial.png";
import imgChave from "../../assets/img/imgChave.png";
import imgInfo from "../../assets/img/info.png";
import DigitarManualmente from "../../assets/img/DigitarManualmente.png";
import AnexarArquivo from "../../assets/img/AnexarArquivo.png";
import ScannearCupom from "../../assets/img/ScannearCupom.png";
import * as moment from "moment";
import EmptyImage from "../../assets/img/emptyImage.PNG";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Worker } from "@react-pdf-viewer/core";

import { Viewer } from "@react-pdf-viewer/core";
import iconeAnexar from "../../assets/img/icons/iconeAnexar.png";
import iconeArquivo from "../../assets/img/icons/iconeArquivo.png";
import {
  showMsgS,
  showMsgW,
  showMsgE,
} from "../../store/actions/snackbarActions.js";
import DocViewer from "@cyntler/react-doc-viewer";

import olho from "../../assets/img/eye.svg";
import QrCode from "../../components/QR-CODE";
import QrReader from "react-qr-reader";

function CadastroNotasFiscais(props) {
  var anexarPdf = useRef(null);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [verPdf, setVerPdf] = useState(false);

  const [liberarBtSalvar, setLiberarBtSalvar] = useState(false);
  const history = useHistory();
  const [imageUploaded, setImageUploaded] = useState(0);
  const [OpenNumeroNf, setOpenNumeroNf] = React.useState(false);
  const [file, setFile] = useState(null);
  //const [selectedFile, setSelectedFile] = useState(null);
  const [pdfFile, setPdfFile] = useState("");
  const [mainState, setMainState] = useState("initial"); // initial, uploaded
  const [modalQrCode, setModalQrCode] = useState(false);
  const [modalDigitar, setModalDigitar] = useState(false);
  const [modalAnexarArquivo, setModalAnexarArquivo] = useState(false);
  const [modalScannearQrCode, setModalScannearQrCode] = useState(false);
  const [modalDigitarManualmente, setModalDigitarManualmente] = useState(false);
  const [campoChave, setCampoChave] = useState("");
  const modalOpenNumeroNf = () => {
    setOpenNumeroNf(true);
  };
  const modalCloseNumeroNf = () => {
    setOpenNumeroNf(false);
  };

  const [OpenDataEmissao, setOpenDataEmissao] = React.useState(false);
  const modalOpenDataEmissao = () => {
    setOpenDataEmissao(true);
  };
  const modalCloseDataEmissao = () => {
    setOpenDataEmissao(false);
  };

  const [OpenCnpj, setOpenCnpj] = React.useState(false);
  const modalOpenCpnj = () => {
    setOpenCnpj(true);
  };

  const [openCnpjMeu, setOpenCnpjMeu] = React.useState(false);
  const modalOpenCpnjMeu = () => {
    setOpenCnpjMeu(!openCnpjMeu);
  };

  const modalCloseCnpj = () => {
    setOpenCnpj(false);
  };

  const [OpenRazao, setOpenRazao] = React.useState(false);
  const modalOpenRazao = () => {
    setOpenRazao(true);
  };
  const modalCloseRazao = () => {
    setOpenRazao(false);
  };

  const [openChave, setOpenChave] = React.useState(false);
  const modalChave = () => {
    setOpenChave(!openChave);
  };

  const [alertaCampo, setAlertaCampo] = useState({
    numeroNF: {
      status: "d-none",
      alerta: "Número Nf inválido",
    },
    dataEmissaoNF: {
      status: "d-none",
      alerta: "Data emissão NF inválido",
    },
    cnpjPrestador: {
      status: "d-none",
      alerta: "Cpnj inválido",
    },
    razaoSocialPrestador: {
      status: "d-none",
      alerta: "Data inválido",
    },
    valorTotalNF: {
      status: "d-none",
      alerta: "Valor total NF inválido",
    },
    seuCnpj: {
      status: "d-none",
      alerta: "Cpnj inválido",
    },
    chaveAcesso: {
      status: "d-none",
      alerta: "Cpnj inválido",
    },
  });

  const [notaFical, setNotaFical] = useState({
    numeroNF: "",
    dataEmissaoNF: "",
    cnpjPrestador: "",
    razaoSocialPrestador: "",
    valorTotalNF: "",
    seuCnpj: "",
    chaveAcesso: "",
    urlDocumentoNF: "",
  });

  const [open, setOpen] = React.useState(false);

  const modalOpen = () => {
    setOpen(true);
  };

  const modalClose = () => {
    setOpen(false);
  };

  function pesquisaCnpj(cnpj) {
    var objenvio = Mask.unMask(cnpj);
    console.log("objenvio", objenvio);
    if (objenvio.length == 14) {
      ApiServices.apiGet("participantes/dealerPorCnpj/" + objenvio).then(
        (data) => {
          console.log("---------------", data);
          if (data.data) {
            setNotaFical((prev) => ({
              ...prev,
              razaoSocialPrestador: data?.data?.nome,
            }));
          } else {
            msg("w", "Concessionária não encontrada ");
          }
        }
      );
    }
  }

  function fnBtSalvar(e) {
    const castroNotaFiscal = {
      numeroNF: notaFical.numeroNF,
      dataEmissaoNF: moment(notaFical.dataEmissaoNF, "DD-MM-YYYY").format(
        "YYYY-MM-DD"
      ),
      cnpjPrestador: Mask.unMask(notaFical.cnpjPrestador),
      razaoSocialPrestador: notaFical.razaoSocialPrestador,
      valorTotalNF: notaFical.valorTotalNF
        .replaceAll(".", "")
        .replaceAll(",", "."),
      seuCnpj: Mask.unMask(notaFical.seuCnpj),
      urlDocumentoNF: notaFical.urlDocumentoNF,
      status: 1,
      chaveAcesso: Mask.unMask(notaFical.chaveAcesso),
    };

    ApiServices.apiPostWithToken("notasfiscais", castroNotaFiscal).then(
      (data) => {
        if (data.success === true) {
          msg("s", "Cadastro realizado com sucesso!");
          history.push("notas-fiscais");
        } else {
          msg("w", data.errors[0]);
        }
        setTimeout(() => {
          setLiberarBtSalvar(false);
        }, 2000);
      }
    );
  }

  const showEmptyImageDesktop = () => {
    if (imageUploaded == 0 && notaFical.numeroNF == "") {
      return (
        <div className="d-flex justify-content-center empty-image">
          <img className="image" src={EmptyImage} />
        </div>
      );
    }
  };

  function msg(tipo, text) {
    if (tipo == "s") props.showMsgS(text);
    if (tipo == "w") props.showMsgW(text);
    if (tipo == "e") props.showMsgE(text);
  }

  const allowedFiles = ["application/pdf"];

  const handleFile = (e) => {
    let selectedFile = e.target.files[0];
    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB em bytes
    console.log("selectedFileselectedFile", selectedFile);

    if (selectedFile.size < MAX_FILE_SIZE) {
      if (selectedFile) {
        let reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onloadend = (e) => {
          setPdfFile(e.target.result);
        };

        setMainState("uploaded");
        //setSelectedFile(e.target.files[0]);
        setImageUploaded(1);
        // setFile(e.target.files[0]);

        const documentoNotaFiscal = new FormData();
        documentoNotaFiscal.append("files", e.target.files[0]);

        ApiServices.apiUpload(
          "genericos/enviar/notafiscal",
          documentoNotaFiscal
        ).then((data) => {
          if (data.success === true) {
            setNotaFical((prev) => ({
              ...prev,
              urlDocumentoNF: data.data.filesUploaded[0],
            }));
          } else {
            msg("w", data.errors[0]);
          }
        });
      } else {
        msg("w", "Por favor, selecionar um arquivo no formtado PDF.");
        setPdfFile("");
        setFile("");
      }
    } else {
      msg("w", "O arquivo selecionado excede o limite de 5 MB.");
      e.target.value = null;
      return;
    }
  };

  function fnVerPdf() {
    if (verPdf == false) {
      setVerPdf(true);
    } else {
      setVerPdf(false);
    }
  }

  const [scanResultFile, setScanResultFile] = useState("");
  const qrRef = useRef(null);
  const ref = useRef(null);

  const handleErrorFile = (error) => {
    msg("w", "Erro no Qr-Code");
  };
  const handleScanFile = (data, origem) => {
    if (data) {
      // O 'data' é o conteúdo do QR code
      setModalQrCode(false);
      setScanResultFile(data);
      const qrCodeParts = data.split("|");

      const dataQR = qrCodeParts[1].substr(0, 8);
      const valorQR = qrCodeParts[2];

      const year = dataQR.slice(0, 4);
      const month = dataQR.slice(4, 6);
      const day = dataQR.slice(6, 8);

      const formattedDate = `${day}${month}${year}`;

      if (formattedDate.length === 8) {
        setNotaFical((prev) => ({
          ...prev,
          dataEmissaoNF: Mask.date(formattedDate),
        }));

        setNotaFical((prev) => ({
          ...prev,
          valorTotalNF: Mask.valor(valorQR),
        }));
      }

      console.log("data", data);

      const regex = /\d{44}/;
      const match = data.match(regex);

      if (match) {
        setNotaFical((prev) => ({
          ...prev,
          chaveAcesso: applyMask(match[0]),
        }));

        setNotaFical((prev) => ({
          ...prev,
          cnpjPrestador: Mask.cnpj(Mask.unMask(match[0]).substr(6, 14)),
        }));

        pesquisaCnpj(Mask.unMask(match[0]).substr(6, 14));

        msg("s", "Chave de acesso scanneada com sucesso!");
      } else {
        msg("w", "Qr-Code inválido");
      }
    }
    if (data == null && origem == "arquivo") {
      msg("w", "Não foi possivel identificar um Qr-Code na imagem");
    }
  };

  const [mirror, setMirror] = useState(false); // To control mirroring
  const [availableCameras, setAvailableCameras] = useState([]); // To store available cameras
  const [selectedCamera, setSelectedCamera] = useState(""); // To manage the selected camera

  useEffect(() => {
    // Function to fetch and set available cameras here (see Step 2)
    fetchAvailableCameras();
  }, []);

  // Function to handle the mirroring button click
  const handleMirrorButtonClick = () => {
    setMirror((prevMirror) => !prevMirror);
  };

  // Function to fetch and set available cameras
  const fetchAvailableCameras = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const cameras = devices.filter((device) => device.kind === "videoinput");
      if (cameras.length > 0) {
        setAvailableCameras(cameras);
        setSelectedCamera(cameras[0].deviceId); // Set the default selected camera to the first available camera
      }
    } catch (error) {
      console.error("Error fetching available cameras: ", error);
    }
  };

  // Function to handle camera selection from the dropdown
  const handleCameraChange = (event) => {
    setSelectedCamera(event.target.value);
  };

  // Function to handle error while scanning QR code

  function applyMask(numero) {
    // Remover traços e espaços do número, se houver
    const numeroLimpo = numero.replace(/[-\s]/g, "");

    // Inserir traços a cada 4 dígitos
    const partes = [];
    for (let i = 0; i < numeroLimpo.length; i += 4) {
      partes.push(numeroLimpo.slice(i, i + 4));
    }

    // Juntar as partes com traços
    const numeroFormatado = partes.join("-");
    return numeroFormatado;
  }
  const docs = [
    {
      uri: "https://www.caceres.mt.gov.br/fotos_institucional_downloads/2.pdf",
    }, // Remote file
  ];

  function validateKey(key) {
    // Split the key into 13 digits.
    var digits = key.split("-");

    // Calculate the check digit.
    var checkDigit = 0;
    for (var i = 0; i < 12; i++) {
      checkDigit += digits[i] * (13 - i);
    }
    checkDigit %= 11;
    if (checkDigit == 10) {
      checkDigit = 0;
    }

    // Check if the check digit matches.
    return digits[12] == checkDigit;
  }

  useEffect(() => {
    console.log(
      "3333333333333333333333333",
      validateKey("3523-0361-4121-1007-6135-6505-3000-0285-1711-4176-2038")
    );
  }, [notaFical.chaveAcesso]);

  return (
    <>
      {/* <QrCode /> */}
      <>
        <QrReader
          ref={qrRef}
          delay={300}
          style={{ width: "100%" }}
          onError={handleErrorFile}
          onScan={(data) => {
            handleScanFile(data, "arquivo");
          }}
          legacyMode
          className="d-none "
        />
      </>

      <div className="container">
        <div className="titulo">
          <span>
            Cadastro de <strong>Notas Fiscais </strong>
          </span>
          <p>
            Notas e cupons ficais aceitos a partir de 07/08/2023.
            <br />
            Por gentileza, enviar o PDF original (eletrônico) e não escaneado.
            Imagens ilegíveis serão reprovadas.
            <br />
            Confira se todas as informações estão de acordo com a NF,
            especialmente o número da nota e valor total da nota.
            <br />
            <br />
            Recibos provisórios não serão aceitos. Somente nota e cupom fiscal.
          </p>
        </div>
        <br />
        <div>
          <span className="span-1">1. CHAVE DE ACESSO (Opcional)</span>
          <label className="form-label m0">
            Para facilitar a leitura de todos os itens da sua NF ou Cupom
            Fiscal, insira aqui a CHAVE DE ACESSO, escolha qual a melhor forma
            de inserção abaixo:
            <strong style={{ cursor: "pointer" }} onClick={modalChave}>
              {props.textoBtn ? (
                props.textoBtn
              ) : (
                <img src={imgInfo} width="15" className="mx-2" alt="" />
              )}
            </strong>
          </label>
          <Dialog
            open={openChave}
            maxWidth={"lg"}
            onClose={modalClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <IconButton aria-label="close" onClick={modalChave}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <img width={"100%"} src={imgChave} />
            </DialogContent>
          </Dialog>
        </div>
        <div className="row border">
          <div className="col-lg-3  my-3">
            <label className="form-label m0">Veja como carregar</label>
            <strong
              style={{ cursor: "pointer" }}
              onClick={() => {
                setModalAnexarArquivo(true);
              }}
            >
              {props.textoBtn ? (
                props.textoBtn
              ) : (
                <img src={imgInfo} width="15" className="mx-2" alt="" />
              )}
            </strong>
            <Dialog
              open={modalAnexarArquivo}
              maxWidth={"lg"}
              onClose={() => {
                setModalAnexarArquivo(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    setModalAnexarArquivo(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <img width={"100%"} src={AnexarArquivo} />
              </DialogContent>
            </Dialog>
            <button
              className="form-control"
              onClick={() => {
                qrRef.current.openImageDialog();
              }}
            >
              Carregar cupom fiscal
            </button>
          </div>

          <div className="col-lg-1 d-flex align-items-center  justify-content-center ">
            <b className="text-center w-100 mt-4">ou</b>
          </div>

          <div className="col-lg-4 my-3">
            <label className="form-label m0">
              Scannear com a câmera do dispositivo
            </label>
            <strong
              style={{ cursor: "pointer" }}
              onClick={() => {
                setModalScannearQrCode(true);
              }}
            >
              {props.textoBtn ? (
                props.textoBtn
              ) : (
                <img src={imgInfo} width="15" className="mx-2" alt="" />
              )}
            </strong>
            <Dialog
              open={modalScannearQrCode}
              maxWidth={"lg"}
              onClose={() => {
                setModalScannearQrCode(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    setModalScannearQrCode(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <img width={"100%"} src={ScannearCupom} />
              </DialogContent>
            </Dialog>
            <button
              onClick={() => {
                setModalQrCode(true);
              }}
              className="form-control "
            >
              Scannear QR-Code
            </button>
          </div>

          <div className="col-lg-1 d-flex align-items-center  justify-content-center ">
            <b className="text-center w-100 mt-4">ou</b>
          </div>

          <div className="col-lg-3 my-3">
            <label className="form-label m0">Digite a chave aqui </label>
            <strong
              style={{ cursor: "pointer" }}
              onClick={() => {
                setModalDigitarManualmente(true);
              }}
            >
              {props.textoBtn ? (
                props.textoBtn
              ) : (
                <img src={imgInfo} width="15" className="mx-2" alt="" />
              )}
            </strong>
            <Dialog
              open={modalDigitarManualmente}
              maxWidth={"lg"}
              onClose={() => {
                setModalDigitarManualmente(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    setModalDigitarManualmente(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <img width={"100%"} src={DigitarManualmente} />
              </DialogContent>
            </Dialog>
            <button
              onClick={() => {
                setModalDigitar(true);
              }}
              className="form-control"
            >
              Digitar Manualmente
            </button>
          </div>

          
          <div className=" col-md-12 form-outline my-3">
            <label className="form-label m0">
              Verifique se inseriu corretamente a chave de acesso.
            </label>
            <input
              style={{ fontSize: 13.5 }}
              className="form-control text-center  "
              type="text"
              id="cnpj"
              placeholder="0000-0000-0000-0000-0000-0000-0000-0000-0000-0000-0000"
              value={notaFical.chaveAcesso}
              disabled
            />
          </div>
        </div>
        <br></br>
        <br></br>
        <span className="span-1">2. DADOS - Nota ou Cupom Fiscal</span> <br />
        {/* <span style={{ color: "red" }}>* </span> */}
        <span className="span-2">
          Só serão aceitas Notas Fiscais que estejam em nome do participante
        </span>
        <br></br>
        <span style={{ color: "red" }}>* </span>
        <span className="span-2">
          Para saber onde fica localizado cada campo dentro da sua NF ou Cupom
          Fiscal, clique no ícone laranja
          <strong>
            {props.textoBtn ? (
              props.textoBtn
            ) : (
              <img src={imgInfo} width="15" className="mx-2" alt="" />
            )}
          </strong>
          ao lado do nome do campo.
        </span>
        <Dialog
          open={OpenNumeroNf}
          maxWidth={"lg"}
          onClose={modalClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <IconButton aria-label="close" onClick={modalCloseNumeroNf}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <img width={"100%"} src={imgNf} />
          </DialogContent>
        </Dialog>
        <div className="row border">
          <div className="col-lg-3 my-3">
            <label className="form-label m0">Seu CNPJ (Opcional)</label>
            <strong style={{ cursor: "pointer" }} onClick={modalOpenCpnjMeu}>
              {props.textoBtn ? (
                props.textoBtn
              ) : (
                <img src={imgInfo} width="15" className="mx-2" alt="" />
              )}
            </strong>
            <input
              className="form-control"
              type="text"
              id="cnpj"
              placeholder="00.000.000/0000-00"
              value={Mask.cnpj(notaFical.seuCnpj)}
              onChange={(e) => {
                alertaCampo.seuCnpj.status = "d-none";
                e.persist();
                setNotaFical((prev) => ({
                  ...prev,
                  seuCnpj: Mask.cnpj(e.target.value),
                }));
              }}
            />
            <p className={alertaCampo.seuCnpj.status}>
              <em>{alertaCampo.seuCnpj.alerta}</em>
            </p>
            <Dialog
              open={openCnpjMeu}
              maxWidth={"lg"}
              onClose={modalClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <IconButton aria-label="close" onClick={modalOpenCpnjMeu}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <img width={"100%"} src={imgInfoMeuCNPJ} />
              </DialogContent>
            </Dialog>
          </div>

          <div className=" col-lg-3 my-3">
            <label className="form-label m0">Número da NF*</label>
            <strong style={{ cursor: "pointer" }} onClick={modalOpenNumeroNf}>
              {props.iconBtn ? (
                props.iconBtn
              ) : (
                <img src={imgInfo} width="15" className="mx-2" alt="" />
              )}
            </strong>

            <input
              className="form-control"
              type="text"
              id="numeroNF"
              placeholder="Insira o número da Nota Fiscal eletrônica ou e Cupom Fiscal"
              value={notaFical.numeroNF}
              onChange={(e) => {
                alertaCampo.numeroNF.status = "d-none";
                e.persist();
                setNotaFical((prev) => ({
                  ...prev,
                  numeroNF: e.target.value.replace(/[^0-9]/g, ""),
                }));
              }}
            />

            <p className={alertaCampo.numeroNF.status}>
              <em>{alertaCampo.numeroNF.alerta}</em>
            </p>
            <Dialog
              open={OpenDataEmissao}
              maxWidth={"lg"}
              onClose={modalClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <IconButton aria-label="close" onClick={modalCloseDataEmissao}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <img width={"100%"} src={imgEmissao} />
              </DialogContent>
            </Dialog>
          </div>

          <div className=" col-lg-3 my-3">
            <label className="form-label m0">Data de emissão*</label>
            <strong
              style={{ cursor: "pointer" }}
              onClick={modalOpenDataEmissao}
            >
              {props.textoBtn ? (
                props.textoBtn
              ) : (
                <img src={imgInfo} width="15" className="mx-2" alt="" />
              )}
            </strong>
            <input
              className="form-control"
              type="text"
              id="data"
              placeholder="dd/mm/aaaa"
              value={notaFical.dataEmissaoNF}
              onChange={(e) => {
                alertaCampo.dataEmissaoNF.status = "d-none";
                e.persist();
                setNotaFical((prev) => ({
                  ...prev,
                  dataEmissaoNF: Mask.date(e.target.value),
                }));
              }}
            />
            <p className={alertaCampo.dataEmissaoNF.status}>
              <em>{alertaCampo.dataEmissaoNF.alerta}</em>
            </p>
            <Dialog
              open={OpenCnpj}
              maxWidth={"lg"}
              onClose={modalClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <IconButton aria-label="close" onClick={modalCloseCnpj}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <img width={"100%"} src={imgCnpj} />
              </DialogContent>
            </Dialog>
          </div>
          <div className="col-lg-3 my-3">
            <label className="form-label m0">Valor total da NF*</label>
            <strong style={{ cursor: "pointer" }} onClick={modalOpen}>
              {props.textoBtn ? (
                props.textoBtn
              ) : (
                <img src={imgInfo} width="15" className="mx-2" alt="" />
              )}
            </strong>
            <input
              className="form-control"
              type="text"
              id="valorTotal"
              placeholder="R$ 0.000,00"
              value={Mask.valor(notaFical.valorTotalNF)}
              onChange={(e) => {
                alertaCampo.valorTotalNF.status = "d-none";
                e.persist();
                setNotaFical((prev) => ({
                  ...prev,
                  valorTotalNF: Mask.valor(e.target.value),
                }));
              }}
            />
            <p className={alertaCampo.valorTotalNF.status}>
              <em>{alertaCampo.valorTotalNF.alerta}</em>
            </p>
          </div>

          <div className="col-lg-3 mb-3">
            <label className="form-label m0">CNPJ do prestador*</label>
            <strong style={{ cursor: "pointer" }} onClick={modalOpenCpnj}>
              {props.textoBtn ? (
                props.textoBtn
              ) : (
                <img src={imgInfo} width="15" className="mx-2" alt="" />
              )}
            </strong>
            <input
              className="form-control"
              type="text"
              id="cnpj"
              placeholder="00.000.000/0000-00"
              value={Mask.cnpj(notaFical.cnpjPrestador)}
              onChange={(e) => {
                alertaCampo.cnpjPrestador.status = "d-none";
                e.persist();
                setNotaFical((prev) => ({
                  ...prev,
                  cnpjPrestador: Mask.cnpj(e.target.value),
                }));
                pesquisaCnpj(e.target.value);
              }}
            />
            <p className={alertaCampo.cnpjPrestador.status}>
              <em>{alertaCampo.cnpjPrestador.alerta}</em>
            </p>
            <Dialog
              open={OpenRazao}
              maxWidth={"lg"}
              onClose={modalClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <IconButton aria-label="close" onClick={modalCloseRazao}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <img width={"100%"} src={imgRazao} />
              </DialogContent>
            </Dialog>
          </div>

          <div className="col-lg-9 mb-3">
            <label className="form-label m0">Razão social do prestador*</label>
            <strong style={{ cursor: "pointer" }} onClick={modalOpenRazao}>
              {props.textoBtn ? (
                props.textoBtn
              ) : (
                <img src={imgInfo} width="15" className="mx-2" alt="" />
              )}
            </strong>
            <input
              className="form-control"
              type="text"
              id="razaoSocial"
              placeholder="Esse campo tem o preenchimento automático"
              value={notaFical.razaoSocialPrestador}
              disabled={true}
              onChange={(e) => {
                alertaCampo.razaoSocialPrestador.status = "d-none";
                e.persist();
                // setNotaFical((prev) => ({
                //   ...prev,
                //   razaoSocialPrestador: e.target.value,
                // }));
              }}
            />
            <p className={alertaCampo.razaoSocialPrestador.status}>
              <em>{alertaCampo.razaoSocialPrestador.alerta}</em>
            </p>
            <Dialog
              open={open}
              maxWidth={"lg"}
              onClose={modalClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <IconButton aria-label="close" onClick={modalClose}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <img width={"100%"} src={ValorTotal} />
              </DialogContent>
            </Dialog>
          </div>
        </div>
        <br></br>
        <br></br>
        <div>
          <span className="span-4">3. ARQUIVO - Nota ou Cupom Fiscal</span>
          <br />
          <span>
            Para assegurar a veracidade dos dados informados, por favor, faça o
            upload do arquivo original
          </span>
        </div>
        <div className="row border mb-5">
          <div className="col-md-12 d-flex flex-column w-100  ">
            <input
              type="file"
              ref={anexarPdf}
              className="botaoAnexar d-none"
              accept="application/pdf, .jpg, .jpeg, .png  "
              onChange={(e) => {
                handleFile(e);
              }}
            ></input>

            {pdfFile == "" && (
              <div
                className="imgIconeAnexar "
                onClick={() => {
                  anexarPdf.current.click();
                  // qrRef.current.openImageDialog();
                }}
              >
                <img className="mr-3" src={iconeAnexar} alt="icone anexar" />
                {"CARREGAR ARQUIVO"}
              </div>
            )}

            {pdfFile != "" && (
              <div className="d-flex justify-content-between w-100">
                <div
                  className="imgIconeAnexar "
                  onClick={() => {
                    fnVerPdf();
                  }}
                >
                  <img className="mr-3" src={iconeArquivo} alt="icone anexar" />
                  {verPdf ? "OCULTAR ARQUIVO" : "VISUALIZAR ARQUIVO"}{" "}
                </div>

                <span
                  className="imgIconeAnexar d-flex align-items-center"
                  onClick={() => {
                    verPdf && fnVerPdf();
                    anexarPdf.current.value = "";
                    setPdfFile("");
                    setFile("");
                    setNotaFical((prev) => ({
                      ...prev,
                      urlDocumentoNF: "",
                    }));
                  }}
                >
                  <span className="imgIconeAnexar m-0 p-0">
                    DELETAR ARQUIVO
                  </span>{" "}
                  <CloseIcon style={{ fontSize: "40px" }} />
                </span>
              </div>
            )}

            <br />

            {verPdf && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {/* <div style={{border:}}></div> */}
                <DocViewer
                  documents={[{ uri: pdfFile }]}
                  style={{
                    width: "100%",
                  }}
                />
                {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
                    <Viewer
                    fileUrl={pdfFile}
                    plugins={[defaultLayoutPluginInstance]}
                    ></Viewer>
                  </Worker> */}
              </div>
            )}

            <div className="row justify-content-center  ">
              <div className="col-md-3 m-3 ">
                <button
                  className="btn btn-secondary p-3 w-100 "
                  onClick={() => history.push(`/notas-fiscais`)}
                >
                  CANCELAR
                </button>
              </div>

              <div className="col-md-3 m-3 ">
                <button
                  className="btn btn-primary p-3 w-100 "
                  disabled={liberarBtSalvar || notaFical.urlDocumentoNF == ""}
                  onClick={(e) => {
                    setLiberarBtSalvar(true);
                    fnBtSalvar(e);
                  }}
                >
                  ENVIAR
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* DIGITAR */}
      {/* DIGITAR */}
      {/* DIGITAR */}
      {/* DIGITAR */}
      {/* DIGITAR */}
      {/* DIGITAR */}
      <Dialog
        open={modalDigitar}
        maxWidth={"lg"}
        onClose={() => {
          setModalDigitar(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <IconButton
            aria-label="close"
            onClick={() => {
              setModalDigitar(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <div
            className="input-group d-flex flex-column "
            style={{ maxWidth: 550, width: "100vw" }}
          >
            <label className="form-label m0">
              Inserir 44 digitos da chave de acesso.
            </label>
            <div className="d-flex ">
              <input
                type="text"
                className="form-control"
                placeholder="0000-0000-0000-0000-0000-0000-0000-0000-0000-0000-0000"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                value={campoChave}
                maxLength={54}
                onChange={(e) => {
                  alertaCampo.chaveAcesso.status = "d-none";
                  e.persist();
                  setCampoChave(applyMask(e.target.value.replace(/\D/g, "")));
                }}
              />

              <div className="input-group-append">
                <span className="input-group-text" id="basic-addon2">
                  {Mask.unMask(campoChave).length} / 44
                </span>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center mt-4 ">
            <button
              style={{ maxWidth: "200px" }}
              onClick={() => {
                setModalDigitar(false);
                setNotaFical((prev) => ({
                  ...prev,
                  chaveAcesso: campoChave,
                }));
                setNotaFical((prev) => ({
                  ...prev,
                  cnpjPrestador: Mask.cnpj(
                    Mask.unMask(campoChave).substr(6, 14)
                  ),
                }));

                pesquisaCnpj(Mask.unMask(campoChave).substr(6, 14));
              }}
              disabled={Mask.unMask(campoChave).length < 44}
              className="form-control my-3"
            >
              Confirmar
            </button>
          </div>
        </DialogContent>
      </Dialog>
      {/* CAMERA */}
      {/* CAMERA */}
      {/* CAMERA */}
      {/* CAMERA */}
      {/* CAMERA */}
      {/* CAMERA */}
      <Dialog
        open={modalQrCode}
        maxWidth={"lg"}
        onClose={() => {
          setModalQrCode(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <IconButton
            aria-label="close"
            onClick={() => {
              setModalQrCode(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="d-flex align-items-center justify-content-center flex-column  ">
          {/* Add the mirroring button */}
          <div className="row d-flex border justify-content-between p-3 ">
            <div className="col-md-5 m-0 p-0">
              <div>
                <label className="form-label m0">
                  Invereter no sentido horizontal
                </label>
                <button
                  style={{ minWidth: 200, height: 42 }}
                  className="form-control"
                  onClick={handleMirrorButtonClick}
                >
                  {!mirror ? "Espelhar" : "Não Espelhar"}
                </button>
              </div>
            </div>

            <div className="col-md-5 m-0 p-0 mb-3 ">
              <div>
                <label className="form-label m0">Selecionar Câmera</label>

                <select value={selectedCamera} onChange={handleCameraChange}>
                  {availableCameras.map((camera) => (
                    <option key={camera.deviceId} value={camera.deviceId}>
                      {camera.label || `Camera ${camera.deviceId}`}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <QrReader
              delay={300}
              style={{
                width: "100%",
                // transform: mirror ? "scaleX(-1)" : "none",
              }} // Apply mirroring based on the "mirror" state
              onError={handleErrorFile}
              onScan={(data) => {
                handleScanFile(data, "scan");
              }}
              legacyMode={!modalQrCode}
              facingMode={mirror ? "user" : "environment"} // Specify the camera facing mode based on the selected camera
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

const mapStateToProps = (store) => ({
  snackbarState: store.snackbarState,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ showMsgS, showMsgW, showMsgE }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CadastroNotasFiscais);
