import React from "react";
import "./footer.scss";

import { Link } from "react-router-dom";

import facebook from "../../assets/img/icons/facebook.png";
import youtube from "../../assets/img/icons/youtube.png";
//import googleplus from '../../assets/img/icons/googleplus.png';
import twitter from "../../assets/img/icons/twitter-2.png";
import instagram from "../../assets/img/icons/instagram.png";
import ibama from "../../assets/img/icons/selo_ibama.png";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3>
              <strong>Siga a Chevrolet</strong>
            </h3>
            <div className="footerSocialMedia">
              <a
                href="https://pt-br.facebook.com/chevroletbrasil/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebook} alt="Chevrolet" />
              </a>
              <a
                href="https://twitter.com/chevroletbrasil"
                target="_blank"
                rel="noopener noreferrer"
              >
               <img src={twitter} alt="Chevrolet" style={{ width: '32px', height: '32px', border:'2px solid black', borderRadius:'5px' }} />
              </a>
              <a
                href="https://www.youtube.com/chevroletbrasil"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={youtube} alt="Chevrolet" />
              </a>
              <a
                href="https://www.instagram.com/chevroletbr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={instagram} alt="Chevrolet" />
              </a>
              {/* <a href="/" target="_blank" rel="noopener noreferrer">
                                <img src={googleplus} alt="Chevrolet" />
                            </a> */}
            </div>
          </div>

          <div className="col-12 footerContent">
            <img src={ibama} alt="ibama" />

            <p>
              No trânsito, escolha a vida. Promoção Peça Premiada: Compre Peças
              GM Genuine Parts em nossa Rede de Concessionárias Chevrolet
              durante o período de 07.08.2023 a 31.10.2023 e se cadastre para
              concorrer a brindes instantâneos, conforme os termos da Promoção.
              Consulte o regulamento aprovado com todas as condições de
              participação e elegibilidade em 
              <a
                href="https://www.pecapremiada.com.br"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                www.pecapremiada.com.br. 
              </a>
              <br></br>
              PROMOÇÃO AUTORIZADA ATRAVÉS DO CERTIFICADO DE AUTORIZAÇÃO SRE/ME
              Nº 05.028814/2023 (Vale-Brinde). Imagens meramente ilustrativas.{" "}
              
            </p>
          </div>

          <div className="col-12">
            <ul>
              <li>Copyright Chevrolet</li>
              <li>
                <a
                  href="https://www.chevrolet.com/worldwide-websites"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Chevrolet Worldwide
                </a>
              </li>
              <li>
                <Link to="/politica-de-privacidade">Informações legais</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
