import React, { useEffect } from "react";

/////////////////////////////
// redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  showMsgS,
  showMsgW,
  showMsgE,
} from "../../store/actions/snackbarActions.js";

import { trackPromise } from "react-promise-tracker";
import ApiServices from "../../services/apiServices";

import "./faq.scss";
import Faqs from "../../components/Faqs/Faqs";

import qrcode from "../../assets/img/qrcode.jpg";

import iconArrow from "../../assets/img/icons/arrow-L.png";
import { Link, Redirect } from "react-router-dom";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
  useGoogleReCaptcha,
  withGoogleReCaptcha,
} from "react-google-recaptcha-v3";

const Faq = (props) => {
  const pathname = () => {
    return window.location.pathname;
  };

  function handleVerify(valor) {
    // console.log(valor);
    props.handleVerify(valor);
  }

  // const [faqlist, setFaqlist] = React.useState([]);
  // const [load, setLoad] = React.useState(false);

  // useEffect(() => {
  //     trackPromise(
  //         ApiServices.apiGet('faqs')
  //         .then(res => {
  //             if (res.success) {
  //                 setFaqlist(res.data)
  //             } else {
  //                 props.showMsgW(`Erro ao carregar Faqs`)
  //             }
  //             setLoad(true)
  //         }).catch(res => {
  //             setLoad(true)
  //             props.showMsgW(`Erro ao carregar Faqs`)
  //         })
  //     )
  // },[]);

  const [faqlist, setFaqlist] = React.useState([
    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 0,
      id: 0,
      status: true,
      tipoFaq: 1,
      Titulo: "O que é a Campanha “PEÇA PREMIADA CHEVROLET”?",
      Texto:
        "<p style='text-align: justify;'>A presente Promoção aprovada pela SRE/ME na modalidade Assemelhado a Vale-Brinde tem como objetivo o Aumento das vendas de peças originais GM <i>Genuine Parts</i>, através da distribuição de brindes por meio da Brincadeira composta pela ilustração lúdica de uma “roleta da sorte” para os clientes que realizarem a compra de peças e cumprirem com os demais requisitos dispostos no regulamento da Campanha. Para participar, os clientes que realizarem a compra de peças durante o período de 07 de agosto de 2023 a 31 de Outubro de 2023, devem se cadastrar na Promoção, conforme detalhado neste FAQ e regulamento da campanha, inserir notas fiscais e/ou cupons fiscais e atingir o Acúmulo.</p>",
    },
    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 1,
      id: 1,
      status: true,
      tipoFaq: 1,
      Titulo: "Qual a premiação da presente Campanha?  ",
      Texto: `
      <p style='text-align: justify;'>A campanha irá distribuir 500 brindes durante o período da promoção ao longo das semanas, dentre os brindes: </p>
      <br/>
      <p style='text-align: justify;'>- Cafeteira 110v ou 220v </p>
      <p style='text-align: justify;'>- Alexa Echo Pop</p>
      <p style='text-align: justify;'>- Caixinha de som portátil JBL</p>
      <p style='text-align: justify;'>- Carregador portátil de celular</p>
      <p style='text-align: justify;'>- Fones de ouvido JBL </p>
      <br/> 
      `,
    },
    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 2,
      id: 2,
      status: true,
      tipoFaq: 1,
      Titulo: "Quantas vezes posso ganhar?",
      Texto: `<p style='text-align: justify;'>Cada participante tem o direito de participar da Brincadeira que distribui brindes por meio das chances quantas vezes fazer jus, contudo, poderá ganhar apenas 1 (uma) vez, ou seja, 1 (um) brinde. </p>`,
    },
    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 3,
      id: 3,
      status: true,
      tipoFaq: 1,
      Titulo: "Quem pode participar?",
      Texto: `<p style='text-align: justify;'>Poderão participar desta Campanha empresário ou microempreendedores individuais (EI ou MEI), pessoa jurídica enquadrada como Sociedade Limitada Unipessoal (SLU) com sede em território nacional e inscritas e com Cadastro Nacional De Pessoa Jurídica (CNPJ) ativo perante o Ministério da Fazenda, e, pessoas físicas maiores de 18 (dezoito) anos, inscritas e com o cadastro ativo no Cadastro de Pessoas Físicas (CPF) do Ministério da Fazenda, que adquirirem peças originais, ou seja, todas as Peças que são da marca “GM <i>Genuine Parts</i>” (“Peças”) disponibilizados nas Lojas Oficiais, Físicas ou Digitais (“Lojas”) da MANDATÁRIA, durante o Período de Participação na Ação, durante o Período de Participação, compreendido de 07/08/2023 até 31/10/2023, realizarem o cadastro pessoal na Promoção e cumprirem com todos os requisitos e regras disponíveis no regulamento.</p>`,
    },
    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 4,
      id: 4,
      status: true,
      tipoFaq: 1,
      Titulo: "Como faço para participar?  ",
      Texto: `<p style='text-align: justify;'>

      Para participar da Promoção o cliente elegível interessado deve, durante o período de participação:
      <br/>
      <br/>
      1. Acessar o Hotsite da Promoção durante o período de participação da Promoção;
      <br/>
      2. Realizar a sua inscrição na plataforma, seguindo o passo-a-passo;
      <br/> 
      3. Realizar o upload de notas fiscais ou cupons fiscais relativos às peças GM <i>Genuine Parts</i> adquiridos nas Concessionárias para o atingimento do Acúmulo; e,
      <br/>
      4. A cada Acúmulo atingido, receberá 1 (uma) chance para participar da Brincadeira para concorrer aos brindes da Promoção. <br/>
      <br/>
      Para que não fique nenhuma dúvida, relacionamos abaixo o significado de cada termo que utilizamos neste FAQ (e no regulamento aprovado):
      <br/>
      <br/>
      <b>•  Acúmulo –</b>  compras de “Peças”GM <i>Genuine Parts</i> realizadas nas Concessionárias, a partir de R$ 150,00 (cento e cinquenta reais) contabilizado por Nota Fiscal ou Cupom Fiscal único ou soma do valor de todas as Notas (ou cupons) Fiscais cadastradas no Hotsite da promoção durante o Período de Participação da Ação.
      <br/> 
      <br/>
      <b>•	Chances –</b> atribuídas ao Cliente Participante a cada Acúmulo atingido para participar da Brincadeira. 
      <br/>
      <br/>
      <b>•	Brincadeira –</b> é a ilustração lúdica de uma roleta disponível na <i>landing page</i> da área logada do Hotsite, em que o Cliente Participante irá clicar para girá-la e concorrer aos Brindes distribuídos nessa Promoção
    

      <br/>
      <br/>
      Exemplo de Acúmulos atingidos e a quantidade de chances adquiridas:
      <br/>
      <br/>
      <div align="center" style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
    <table style="border-collapse:collapse;border:none;">
        <tbody>
            <tr>
                <td style="border: 3pt solid rgb(209, 209, 209);background: white;padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-family:  "Verdana",sans-serif;color:black;'>   FAIXA DE VALORES ACUMULADOS   </span></strong></p>
                </td>
                <td style="border-top: 3pt solid rgb(209, 209, 209);border-right: 3pt solid rgb(209, 209, 209);border-bottom: 3pt solid rgb(209, 209, 209);border-image: initial;border-left: none;background: white;padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-family:  "Verdana",sans-serif;color:black;'>   QUANTIDADE DE CHANCES   </span></strong></p>
                </td>
            </tr>
            <tr>
                <td style="border-right: 3pt solid rgb(209, 209, 209);border-bottom: 3pt solid rgb(209, 209, 209);border-left: 3pt solid rgb(209, 209, 209);border-image: initial;border-top: none;background: white;padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><span style='font-family:  "Verdana",sans-serif;color:black;'>De R$0,01 a R$ 149,99</span></p>
                </td>
                <td style="border-top: none;border-left: none;border-bottom: 3pt solid rgb(209, 209, 209);border-right: 3pt solid rgb(209, 209, 209);background: white;padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><span style='font-family:  "Verdana",sans-serif;color:black;'>0 Chance</span></p>
                </td>
            </tr>
            <tr>
                <td style="border-right: 3pt solid rgb(209, 209, 209);border-bottom: 3pt solid rgb(209, 209, 209);border-left: 3pt solid rgb(209, 209, 209);border-image: initial;border-top: none;background: white;padding: 0.75pt;height: 9.85pt;vertical-align: top;">
                    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><span style='font-family:  "Verdana",sans-serif;color:black;'>De R$ 150,00 a R$ 299,99</span></p>
                </td>
                <td style="border-top: none;border-left: none;border-bottom: 3pt solid rgb(209, 209, 209);border-right: 3pt solid rgb(209, 209, 209);background: white;padding: 0.75pt;height: 9.85pt;vertical-align: top;">
                    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><span style='font-family:  "Verdana",sans-serif;color:black;'>1 Chances</span></p>
                </td>
            </tr>
            <tr>
                <td style="border-right: 3pt solid rgb(209, 209, 209);border-bottom: 3pt solid rgb(209, 209, 209);border-left: 3pt solid rgb(209, 209, 209);border-image: initial;border-top: none;background: white;padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><span style='font-family:  "Verdana",sans-serif;color:black;'>De R$ 300,00 a R$ 449,99</span></p>
                </td>
                <td style="border-top: none;border-left: none;border-bottom: 3pt solid rgb(209, 209, 209);border-right: 3pt solid rgb(209, 209, 209);background: white;padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><span style='font-family:  "Verdana",sans-serif;color:black;'>2 Chances</span></p>
                </td>
            </tr>
            <tr>
                <td style="border-right: 3pt solid rgb(209, 209, 209);border-bottom: 3pt solid rgb(209, 209, 209);border-left: 3pt solid rgb(209, 209, 209);border-image: initial;border-top: none;background: white;padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><span style='font-family:  "Verdana",sans-serif;color:black;'>De R$ 450,00 a R$ 599,99</span></p>
                </td>
                <td style="border-top: none;border-left: none;border-bottom: 3pt solid rgb(209, 209, 209);border-right: 3pt solid rgb(209, 209, 209);background: white;padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><span style='font-family:  "Verdana",sans-serif;color:black;'>3 Chances</span></p>
                </td>
            </tr>
            <tr>
                <td style="border-right: 3pt solid rgb(209, 209, 209);border-bottom: 3pt solid rgb(209, 209, 209);border-left: 3pt solid rgb(209, 209, 209);border-image: initial;border-top: none;background: white;padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><span style='font-family:  "Verdana",sans-serif;color:black;'>Acima de R$ 4.500,00</span></p>
                </td>
                <td style="border-top: none;border-left: none;border-bottom: 3pt solid rgb(209, 209, 209);border-right: 3pt solid rgb(209, 209, 209);background: white;padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><span style='font-family:  "Verdana",sans-serif;color:black;'>30 Chances</span></p>
                </td>
            </tr>
     
        </tbody>
    </table>
</div> 
      </p>`,
    },
    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 5,
      id: 5,
      status: true,
      tipoFaq: 1,
      Titulo: "Quem não pode participar?",
      Texto: `<p style='text-align: justify;'> 
      <p>Não serão contemplados na Campanha:</p>
      </br>
      <p>
      a)	Pessoas Jurídicas não enquadradas como Sociedade Limitada Unipessoal ou como Microempreendedores Individuais;
      </p>
      <p>
      b)	Pessoas físicas que não atenderem aos requisitos exigidos no Regulamento;
      </p>
      <p>
      c)	Os membros do corpo diretivo, conforme respectivos documentos societários da MANDATÁRIA;
      </p>
      <p>
      d)	Todos os empregados e colaboradores da MANDATÁRIA que estejam envolvidos em qualquer aspecto desta Promoção; 
      </p>
      <p>
      e)	Todos os empregados e colaboradores das Lojas participantes;
      </p>
      <p>
      f)	Todos os empregados e colaboradores da assessoria jurídica Focaccia, Amaral e Lamonica Sociedade de Advogados;
      </p>
      <p>
      g)	Qualquer indivíduo que não goze de sua capacidade legal.
      </p>
      
             `,
    },

    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 6,
      id: 6,
      status: true,
      tipoFaq: 1,
      Titulo: "Onde ocorrerá a campanha? Qual a abrangência? ",
      Texto: `<p style='text-align: justify;'>Esta Promoção será realizada em todo o Território Nacional Brasileiro. 
      </p> `,
    },
    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 7,
      id: 7,
      status: true,
      tipoFaq: 1,
      Titulo: "Qual o período de participação?",
      Texto: `<p style='text-align: justify;'>A presente Promoção terá seu período de participação das 11h de 07/08/2023 até às 18h de 31/10/2023.
      <br/>
      E serão consideradas Notas e cupons Fiscais de Peças GM <i>Genuine Parts</i> adquiridos durante o período de participação desta Promoção. </p>`,
    },
    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 8,
      id: 8,
      status: true,
      tipoFaq: 1,
      Titulo: "Qual peça é válida para a campanha? ",
      Texto: `
        <p style='text-align: justify;'>Toda e qualquer peça GM <i>Genuine Parts</i> vendida pela rede de concessionária Chevrolet, no período da campanha, que tenha uma nota ou cupom fiscal da concessionária, é válida para a campanha desde que devidamente cadastrada conforme os prazos e regras da campanha. Serão aceitas somente notas de Peças GM <i>Genuine Parts</i> da rede de concessionária Chevrolet.

        <br/>
        <br/>
        Após realizar a compra na concessionária, o cliente receberá sua NFe (Nota Fiscal Eletrônica) no e-mail, e deverá usar esse arquivo para seu cadastro na promoção através do hotsite da campanha
        
        <a href="https://www.pecapremiada.com.br" target="_blank" rel="noopener noreferrer"> www.pecapremiada.com.br. </a>
         
<br/>
<br/>

Para isso deverá durante o Período de Participação acessar o Hotsite da Promoção <a href="https://www.pecapremiada.com.br" target="_blank" rel="noopener noreferrer"> www.pecapremiada.com.br</a>, realizar sua inscrição/cadastro pessoal, através do preenchimento de formulário, com as seguintes informações: Nome Completo; E-mail; CPF/ME; e Telefone. No mesmo momento, realizar o Opt-In, que é o “aceite” nas normas e regulamento da campanha. Após finalizar o cadastro, realizar o upload das Notas Fiscais ou Cupons Fiscais relativos as peças adquiridas. E a cada Acúmulo atingido, receberá 1 (uma) chance de rodar a Roleta para concorrer a 1 (um) dos brindes distribuídos nesta promoção. 
<br/>
<br/>
Será enviado um e-mail de confirmação da participação, e dentro do hotsite da campanha o participante pode acompanhar todas as etapas e consultar suas chances, resultado da brincadeira (roleta), e esclarecer dúvidas. 

        </p>
        `,
    },

    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 9,
      id: 9,
      status: true,
      tipoFaq: 1,
      Titulo: "Como ganho a chance para rodar a Roleta?",
      Texto: `<p style='text-align: justify;'> 


      A Chance para rodar a Roleta é gerada no hotsite da campanha <a href="https://www.pecapremiada.com.br" target="_blank" rel="noopener noreferrer"> www.pecapremiada.com.br</a> somente após finalizado o Cadastro, o “aceite” na promoção, o upload da(s) NF(s) ou Cupons Fiscais, a validação das NF(s) ou Cupons Fiscais pela Empresa Promotora e atingido o Acúmulo, conforme regras disponíveis nos regulamentos.  
        <br/>
        <br/>
         <strong>Acúmulo –</strong> compras de Peças GM <i>Genuine Parts</i> realizadas nas Concessionárias, a partir de R$ 150,00 (cento e cinquenta reais) contabilizado por Nota Fiscal ou Cupom Fiscal única ou soma do valor de todas as Notas Fiscais (ou Cupons) cadastrados no Hotsite da promoção durante o Período de Participação da Ação.
        <br/>
        <br/> 
        <br/> 
        Exemplo:
        <br/> 
        <div align="center" style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
        <table style="border-collapse:collapse;border:none;">
        <tbody>
            <tr>
                <td style="border: 3pt solid rgb(209, 209, 209);background: white;padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-family:  "Verdana",sans-serif;color:black;'>   FAIXA DE VALORES ACUMULADOS   </span></strong></p>
                </td>
                <td style="border-top: 3pt solid rgb(209, 209, 209);border-right: 3pt solid rgb(209, 209, 209);border-bottom: 3pt solid rgb(209, 209, 209);border-image: initial;border-left: none;background: white;padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-family:  "Verdana",sans-serif;color:black;'>   QUANTIDADE DE CHANCES   </span></strong></p>
                </td>
            </tr>
            <tr>
                <td style="border-right: 3pt solid rgb(209, 209, 209);border-bottom: 3pt solid rgb(209, 209, 209);border-left: 3pt solid rgb(209, 209, 209);border-image: initial;border-top: none;background: white;padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><span style='font-family:  "Verdana",sans-serif;color:black;'>De R$0,01 a R$ 149,99</span></p>
                </td>
                <td style="border-top: none;border-left: none;border-bottom: 3pt solid rgb(209, 209, 209);border-right: 3pt solid rgb(209, 209, 209);background: white;padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><span style='font-family:  "Verdana",sans-serif;color:black;'>0 Chance</span></p>
                </td>
            </tr>
            <tr>
                <td style="border-right: 3pt solid rgb(209, 209, 209);border-bottom: 3pt solid rgb(209, 209, 209);border-left: 3pt solid rgb(209, 209, 209);border-image: initial;border-top: none;background: white;padding: 0.75pt;height: 9.85pt;vertical-align: top;">
                    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><span style='font-family:  "Verdana",sans-serif;color:black;'>De R$ 150,00 a R$ 299,99</span></p>
                </td>
                <td style="border-top: none;border-left: none;border-bottom: 3pt solid rgb(209, 209, 209);border-right: 3pt solid rgb(209, 209, 209);background: white;padding: 0.75pt;height: 9.85pt;vertical-align: top;">
                    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><span style='font-family:  "Verdana",sans-serif;color:black;'>1 Chances</span></p>
                </td>
            </tr>
            <tr>
                <td style="border-right: 3pt solid rgb(209, 209, 209);border-bottom: 3pt solid rgb(209, 209, 209);border-left: 3pt solid rgb(209, 209, 209);border-image: initial;border-top: none;background: white;padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><span style='font-family:  "Verdana",sans-serif;color:black;'>De R$ 300,00 a R$ 449,99</span></p>
                </td>
                <td style="border-top: none;border-left: none;border-bottom: 3pt solid rgb(209, 209, 209);border-right: 3pt solid rgb(209, 209, 209);background: white;padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><span style='font-family:  "Verdana",sans-serif;color:black;'>2 Chances</span></p>
                </td>
            </tr>
            <tr>
                <td style="border-right: 3pt solid rgb(209, 209, 209);border-bottom: 3pt solid rgb(209, 209, 209);border-left: 3pt solid rgb(209, 209, 209);border-image: initial;border-top: none;background: white;padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><span style='font-family:  "Verdana",sans-serif;color:black;'>De R$ 450,00 a R$ 599,99</span></p>
                </td>
                <td style="border-top: none;border-left: none;border-bottom: 3pt solid rgb(209, 209, 209);border-right: 3pt solid rgb(209, 209, 209);background: white;padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><span style='font-family:  "Verdana",sans-serif;color:black;'>3 Chances</span></p>
                </td>
            </tr>
            <tr>
                <td style="border-right: 3pt solid rgb(209, 209, 209);border-bottom: 3pt solid rgb(209, 209, 209);border-left: 3pt solid rgb(209, 209, 209);border-image: initial;border-top: none;background: white;padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><span style='font-family:  "Verdana",sans-serif;color:black;'>Acima de R$ 4.500,00</span></p>
                </td>
                <td style="border-top: none;border-left: none;border-bottom: 3pt solid rgb(209, 209, 209);border-right: 3pt solid rgb(209, 209, 209);background: white;padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><span style='font-family:  "Verdana",sans-serif;color:black;'>30 Chances</span></p>
                </td>
            </tr>
     
        </tbody>
    </table>
  </div> 
        </p>
        `,
    },
    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 10,
      id: 10,
      status: true,
      tipoFaq: 1,
      Titulo: "Onde serão divulgados os resultados? ",
      Texto:
        "<p style='text-align: justify;'>Os resultados de cada chance na Roleta serão apresentados na hora, através de um POP-UP que indica se o participante foi contemplado ou não com o Brinde, disponível no hotsite da campanha <b>após</b> a roleta parar de girar. Os resultados também serão divulgados por meio de e-mail, junto com as orientações.</p>",
    },
    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 11,
      id: 11,
      status: true,
      tipoFaq: 1,
      Titulo: "Ganhei o brinde, e agora?",
      Texto: `<p style='text-align: justify;'>
        A Chevrolet ou parceiro Chevrolet podem entrar em contato com o ganhador para validar a participação e solicitar as informações e documentos necessários para realizar a entrega da premiação àquele que fazer jus, conforme as regras dos Regulamentos. Além disso, toda a instrução será enviada ao e-mail cadastrado na Promoção.  
<br/>
<br/>
Para o recebimento do prêmio o Contemplado, deverá enviar: (i) cópia legível do seu RG; (ii) cópia legível do CPF/ME; e (iii) cópia legível de seu comprovante de residência, com data de no máximo 180 (cento e oitenta) dias anteriores à data da divulgação dos resultados, em seu nome ou em nome de algum parente ou cônjuge, acompanhado de outros documentos que comprovem seu grau de parentesco ou relação, consoante as instruções que serão fornecidas por meio de correspondência eletrônica e/ou via telefone. Neste ato, o Contemplado deverá ainda, <b>assinar o recibo de entrega</b> de prêmio que constituirá prova de entrega do prêmio.

        </p>`,
    },
    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 12,
      id: 12,
      status: true,
      tipoFaq: 1,
      Titulo: "Posso Rodar a Roleta em qualquer momento?",
      Texto: `<p style='text-align: justify;'>A cada chance gerada, por meio do atingimento do Acúmulo no valor de R$ 150,00 reais como é mencionado nesta FAQ e Regulamento, você ganha a chance de rodar a Roleta. Você pode utilizar a sua Chance para girar a Roleta durante todo o período de participação da Promoção. Para girar, basta clicar em “testar minha sorte”, disponível na área logada no Hotsite da Promoção. Os resultados ficam registrados e os participantes podem consultar a sua participação a qualquer momento na área logada no Hotsite da Promoção. </p>
        `,
    },
    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 13,
      id: 13,
      status: true,
      tipoFaq: 1,
      Titulo: "Fui contemplado, em quanto tempo vou receber meu prêmio?",
      Texto: `<p style='text-align: justify;'>O <b>Brinde</b> será entregue, livre e desembaraçado de qualquer ônus no prazo máximo de 30 (trinta) dias, no endereço cadastrado no site. 
      </br>
      </br>
      Para recebimento, o contemplado deverá apresentar os documentos solicitados pela Mandatária, sendo que este será entregue desde que atendidos todos os requisitos apresentados nos Regulamentos, disponíveis no hotsite da Promoção. 
      
      </p>`,
    },
    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 14,
      id: 14,
      status: true,
      tipoFaq: 1,
      Titulo: "Onde posso fazer o acompanhamento da promoção? ",
      Texto: `<p style='text-align: justify;'>O acompanhamento da promoção deve ser feito no hotsite da campanha, disponível em <a href="https://www.pecapremiada.com.br" target="_blank" rel="noopener noreferrer"> www.pecapremiada.com.br</a>. Caso queira consultar sua participação individual, acesse a área logada com os dados do seu Cadastro na Promoção.
       </p>`,
    },
    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 15,
      id: 15,
      status: true,
      tipoFaq: 1,
      Titulo: "Posso ser desclassificado da promoção?",
      Texto: `<p style='text-align: justify;'>Os Participantes poderão ser excluídos automaticamente da Campanha em caso de tentativa de fraude, falsidade ou qualquer ato de má fé comprovado, não preenchimento dos requisitos previamente determinados e/ou em decorrência de informações incompletas, incorretas ou equivocadas, de acordo com as regras previstas no Regulamento, disponível no hotsite da campanha <a href="https://www.pecapremiada.com.br" target="_blank" rel="noopener noreferrer"> www.pecapremiada.com.br</a>. 
      </p>`,
    },
    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 16,
      id: 16,
      status: true,
      tipoFaq: 1,
      Titulo: "Tenho algum custo para poder usufruir da minha premiação? ",
      Texto: `<p style='text-align: justify;'>Não. Os brindes serão entregues prontos para uso, sem ônus aos Contemplados. Os Brindes não poderão ser distribuídos ou convertidos, total ou parcialmente em dinheiro de acordo com o art. 15, § 5° do Decreto n°70.951/72. Ademais, o Brinde não poderá ser trocado, alterado, complementado ou substituído por outro Brinde.
       </p>`,
    },
    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 17,
      id: 17,
      status: true,
      tipoFaq: 1,
      Titulo: "Em caso de dúvidas, com quem posso entrar em contato? ",
      Texto: `<p style='text-align: justify;'>Para qualquer tipo de dúvidas, ou auxílio, entre em contato com o SAC da promoção através do nosso Atendimento telefônico 11 – 5593–5139, de segunda a sexta das 9h às 18h ou com SAC Chevrolet 0800 702 4200 de segunda a sexta, das 08h às 20h e aos sábados das 08h às 14h. Para atendimento Chevrolet via whatsapp, clique  <a
      href="https://api.whatsapp.com/send/?phone=5511998828157&text=Fale%20Aqui%20com%20Atendimento%20Chevrolet%20no%20WhatsApp"
      target="_blank"
      rel="noopener noreferrer"
      className="texto-aqui"
    > aqui</a>.</p>`,
    },
  ]);

  const [faqlistsorteio, setFaqlistsorteio] = React.useState([
    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 1,
      id: 1,
      status: true,
      tipoFaq: 1,
      Titulo:
        "O que é a campanha PEÇA PREMIADA CHEVROLET” - Assemelhada a SORTEIO?",
      Texto:
        "<p style='text-align: justify;'>A presente Campanha tem como objetivo o aumento do fluxo nas Concessionárias Chevrolet para a realização de serviços, através da realização de sorteios semanais (prêmios) e um sorteio final, de um carro 0km, para os clientes que realizarem serviços a partir de Julho de 2022 até Setembro de 2022, e se cadastrarem na campanha, conforme detalhado neste FAQ e regulamento da campanha.  </p>",
    },
    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 2,
      id: 2,
      status: true,
      tipoFaq: 1,
      Titulo: "Qual a premiação da presente Campanha - Assemelhada a SORTEIO? ",
      Texto: `<p style='text-align: justify;'>A campanha vai premiar 240 prêmios durante o período da promoção ao longo das semanas, dentre eles: 48 (quarenta e oito) TVs LED 42 polegadas, 84 (oitenta e quatro) Smartphones, 24 (vinte e quatro) Notebooks, 84 (oitenta e quatro) Bicicletas. <br/><br/>
        E no sorteio final, 1 (um) Novo Onix LT Turbo, 0km e com todos os custos pagos pela Chevrolet (emplacamento, licenciamento, documentação, IPVA).
        <br/><br/> Quantidade total de prêmios distribuídos ao longo da promoção: 241<br/><br/>*Para informações sobre os vale-brindes consultar a outra FAQ e Regulamento disponíveis no hotsite da campanha.</p>`,
    },
    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 3,
      id: 3,
      status: true,
      tipoFaq: 1,
      Titulo: "Quem pode participar? ",
      Texto: `<p style='text-align: justify;'>Poderão participar desta Campanha pessoas físicas maiores de 18 (dezoito) anos, inscritas e com o cadastro ativo no Cadastro de Pessoas Físicas (CPF) do Ministério da Economia, que adquirirem os Serviços (válidos também para peças ou acessórios linkados a um serviço da concessionária, apenas não são válidas peças de balcão - compra sem o serviço) disponibilizados nas lojas físicas (“concessionárias”) da Chevrolet durante o Período de Participação, e conforme explicado abaixo.<br/><br/>

      <strong>Compra Principal </strong> – compras realizadas nas Concessionárias, sendo no valor de R$ 0,01 (um centavo) à R$ 499,99 contabilizado por Nota Fiscal única ou soma do valor de todas as Notas Fiscais cadastradas no Hotsite da promoção durante o Período de Participação da Ação. Contabilizando a primeira chance.<br/><br/>
      <strong> Acúmulo </strong> – são os valores acumulados em múltiplos de R$ 250,00 (duzentos e cinquenta reais) contabilizado por Nota Fiscal única ou soma do valor de todas as Notas Fiscais cadastradas no Hotsite da promoção durante o Período de Participação da Ação. Contabilizando as demais chances.
      <br/><br/>
      <div align="center" style='margin:0cm;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'>
    <table style="width:356.0pt;background:white;border-collapse:collapse;border:none;">
        <tbody>
            <tr>
                <td style="border: 3pt solid rgb(209, 209, 209);padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin:0cm;line-height:normal;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><strong><span style='font-family:"Verdana",sans-serif;color:black;'>FAIXA DE VALORES ACUMULADOS</span></strong></p>
                </td>
                <td style="border-top: 3pt solid rgb(209, 209, 209);border-right: 3pt solid rgb(209, 209, 209);border-bottom: 3pt solid rgb(209, 209, 209);border-image: initial;border-left: none;padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin:0cm;line-height:normal;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><strong><span style='font-family:"Verdana",sans-serif;color:black;'>QUANTIDADE DE CHANCES</span></strong></p>
                </td>
            </tr>
            <tr>
                <td style="border-right: 3pt solid rgb(209, 209, 209);border-bottom: 3pt solid rgb(209, 209, 209);border-left: 3pt solid rgb(209, 209, 209);border-image: initial;border-top: none;padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin:0cm;line-height:normal;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-family:"Verdana",sans-serif;color:black;'>De R$0,01 a R$ 499,99</span></p>
                </td>
                <td style="border-top: none;border-left: none;border-bottom: 3pt solid rgb(209, 209, 209);border-right: 3pt solid rgb(209, 209, 209);padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin:0cm;line-height:normal;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-family:"Verdana",sans-serif;color:black;'>1 Chance</span></p>
                </td>
            </tr>
            <tr>
                <td style="border-right: 3pt solid rgb(209, 209, 209);border-bottom: 3pt solid rgb(209, 209, 209);border-left: 3pt solid rgb(209, 209, 209);border-image: initial;border-top: none;padding: 0.75pt;height: 9.85pt;vertical-align: top;">
                    <p style='margin:0cm;line-height:normal;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-family:"Verdana",sans-serif;color:black;'>De R$ 500,00 a R$ 749,99</span></p>
                </td>
                <td style="border-top: none;border-left: none;border-bottom: 3pt solid rgb(209, 209, 209);border-right: 3pt solid rgb(209, 209, 209);padding: 0.75pt;height: 9.85pt;vertical-align: top;">
                    <p style='margin:0cm;line-height:normal;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-family:"Verdana",sans-serif;color:black;'>2 Chances</span></p>
                </td>
            </tr>
            <tr>
                <td style="border-right: 3pt solid rgb(209, 209, 209);border-bottom: 3pt solid rgb(209, 209, 209);border-left: 3pt solid rgb(209, 209, 209);border-image: initial;border-top: none;padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin:0cm;line-height:normal;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-family:"Verdana",sans-serif;color:black;'>De R$ 750,00 a R$ 999,99</span></p>
                </td>
                <td style="border-top: none;border-left: none;border-bottom: 3pt solid rgb(209, 209, 209);border-right: 3pt solid rgb(209, 209, 209);padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin:0cm;line-height:normal;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-family:"Verdana",sans-serif;color:black;'>3 Chances</span></p>
                </td>
            </tr>
            <tr>
                <td style="border-right: 3pt solid rgb(209, 209, 209);border-bottom: 3pt solid rgb(209, 209, 209);border-left: 3pt solid rgb(209, 209, 209);border-image: initial;border-top: none;padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin:0cm;line-height:normal;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-family:"Verdana",sans-serif;color:black;'>De R$ 1.000,00 a R$ 1.249,99</span></p>
                </td>
                <td style="border-top: none;border-left: none;border-bottom: 3pt solid rgb(209, 209, 209);border-right: 3pt solid rgb(209, 209, 209);padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin:0cm;line-height:normal;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-family:"Verdana",sans-serif;color:black;'>4 Chances</span></p>
                </td>
            </tr>
            <tr>
                <td style="border-right: 3pt solid rgb(209, 209, 209);border-bottom: 3pt solid rgb(209, 209, 209);border-left: 3pt solid rgb(209, 209, 209);border-image: initial;border-top: none;padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin:0cm;line-height:normal;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-family:"Verdana",sans-serif;color:black;'>De R$ 1.250,00 a R$ 1.499,99</span></p>
                </td>
                <td style="border-top: none;border-left: none;border-bottom: 3pt solid rgb(209, 209, 209);border-right: 3pt solid rgb(209, 209, 209);padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin:0cm;line-height:normal;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-family:"Verdana",sans-serif;color:black;'>5 Chances</span></p>
                </td>
            </tr>
        </tbody>
    </table>
</div>
      <br/><br/>
      E que comprovadamente realizarem o serviço entre o período compreendido de 01/07/2022 até 30/09/2022, e se cadastrarem dentro do período de participação da campanha de 14/07/2022 até 30/09/2022, conforme FAQ e Regulamento.
       
      </p>`,
    },
    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 4,
      id: 4,
      status: true,
      tipoFaq: 1,
      Titulo: "Quem não pode participar?",
      Texto: `<p style='text-align: justify;'>Não serão contemplados na Campanha, e consecutivamente nos sorteios, Pessoas Jurídicas; Pessoas físicas que não atenderem aos requisitos exigidos neste Regulamento;	Os membros do corpo diretivo, conforme respectivos documentos societários, e todos os empregados e colaboradores da Chevrolet e Rede de Concessionárias; Todos os empregados e colaboradores da assessoria jurídica Focaccia, Amaral e Lamonica Sociedade de Advogados; e qualquer indivíduo que não goze de sua capacidade legal.</p>`,
    },
    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 5,
      id: 5,
      status: true,
      tipoFaq: 1,
      Titulo: "Onde ocorrerá a campanha? Qual a abrangência? ",
      Texto: `<p style='text-align: justify;'> Esta Campanha será realizada em todo o Território Nacional Brasileiro. </p>
            `,
    },

    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 6,
      id: 6,
      status: true,
      tipoFaq: 1,
      Titulo: "Qual o período de participação?",
      Texto: `<p style='text-align: justify;'>A presente Campanha terá seu período de participação de 14/07/2022 a 30/09/2022.<br/><br/>
      E serão consideradas Notas Fiscais de serviços adquiridos durante o período de vigência desta Promoção, bem como os serviços adquiridos a partir de 01/07/2022.
      
      </p> `,
    },
    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 7,
      id: 7,
      status: true,
      tipoFaq: 1,
      Titulo:
        "Qual o serviço realizado em concessionária que é válido para a campanha? ",
      Texto: `<p style='text-align: justify;'> Qualquer serviço realizado gerando uma Nota Fiscal da Concessionária é válido para a campanha. Válidos também para peças ou acessórios linkados a um serviço da concessionária. As NFs tanto de serviços, quanto de Peças e Acessórios (linkadas a um serviço) devem ser cadastras na promoção (haverá a validação se as NFs de Peças e Acessórios estão linkadas a um serviço, NFs sem estarem ligadas a um serviço serão descartadas). <br/><br/>
      Apenas não são válidas peças de balcão, que é a compra da peça sem o serviço por parte da Concessionária.<br/><br/>
      Após realizado o serviço na concessionária, o cliente receberá sua NFe (Nota Fiscal Eletrônica) no e-mail, e deverá usar esse arquivo para seu cadastro na promoção através do hotsite da campanha <a href= "https://www.pecapremiada.com.br" target="_blank" rel="noopener noreferrer"> www.pecapremiada.com.br </a>. <br/><br/>
      Para isso deverá durante o Período de Participação acessar o Hotsite da Promoção <a href= "https://www.pecapremiada.com.br" target="_blank" rel="noopener noreferrer"> www.pecapremiada.com.br </a>, realizar sua inscrição/cadastro pessoal, através do preenchimento de formulário, com as seguintes informações: Nome Completo; E-mail; CPF/ME; e Telefone. No mesmo momento, realizar o Opt-In, que é o “aceite” nas normas e regulamento da campanha. Realizar o <i>upload</i> das Notas Fiscais relativas aos serviços adquiridos. E na compra principal e em cada Acúmulo atingido, receberá 1 (um) Número da Sorte para concorrer aos prêmios distribuídos nesta promoção.<br/><br/>
      Será enviado um e-mail de confirmação da participação e dentro do hotsite da campanha o participante pode acompanhar todas as etapas e consultar suas chances, prêmios etc. 
      
</p>
            `,
    },

    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 9,
      id: 9,
      status: true,
      tipoFaq: 1,
      Titulo: "Como é gerado meu número da sorte?  ",
      Texto: `<p style='text-align: justify;'> O número da sorte é gerado no hotsite da campanha <a href= "https://www.pecapremiada.com.br" target="_blank" rel="noopener noreferrer"> www.pecapremiada.com.br </a> após o cadastro em até 3 horas (úteis), o “aceite” na promoção, o <i>upload</i> da(s) NF(s), da validação, e conforme detalhado abaixo:<br/><br/>

      <strong>Até R$ 499,99 reais o participante tem 1 chance garantida </strong> (ou seja, qualquer valor igual ou inferior recebe um número da sorte para participar). Essa é a única etapa em que o participante tem direito a uma chance sem acumular o valor de R$ 250,00 reais, necessariamente.<br/><br/>
Para as demais chances, o cliente deve acumular R$ 250 referente a uma chance e assim consecutivamente.<br/><br/>

<strong>Compra Principal</strong> – compras realizadas nas Concessionárias, sendo no valor de R$ 0,01 (um centavo) à R$ 499,99 contabilizado por Nota Fiscal única ou soma do valor de todas as Notas Fiscais cadastradas no Hotsite da promoção durante o Período de Participação da Ação. Contabilizando a primeira chance.<br/><br/>

<strong> Acúmulo </strong>– são os valores acumulados em múltiplos de R$ 250,00 (duzentos e cinquenta reais) contabilizado por Nota Fiscal única ou soma do valor de todas as Notas Fiscais cadastradas no Hotsite da promoção durante o Período de Participação da Ação. Contabilizando as demais chances.
<br/><br/>

<div align="center" style='margin:0cm;line-height:115%;font-size:15px;font-family:"Arial",sans-serif;'>
    <table style="width:356.0pt;background:white;border-collapse:collapse;border:none;">
        <tbody>
            <tr>
                <td style="border: 3pt solid rgb(209, 209, 209);padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin:0cm;line-height:normal;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><strong><span style='font-family:"Verdana",sans-serif;color:black;'>FAIXA DE VALORES ACUMULADOS</span></strong></p>
                </td>
                <td style="border-top: 3pt solid rgb(209, 209, 209);border-right: 3pt solid rgb(209, 209, 209);border-bottom: 3pt solid rgb(209, 209, 209);border-image: initial;border-left: none;padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin:0cm;line-height:normal;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><strong><span style='font-family:"Verdana",sans-serif;color:black;'>QUANTIDADE DE CHANCES</span></strong></p>
                </td>
            </tr>
            <tr>
                <td style="border-right: 3pt solid rgb(209, 209, 209);border-bottom: 3pt solid rgb(209, 209, 209);border-left: 3pt solid rgb(209, 209, 209);border-image: initial;border-top: none;padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin:0cm;line-height:normal;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-family:"Verdana",sans-serif;color:black;'>De R$0,01 a R$ 499,99</span></p>
                </td>
                <td style="border-top: none;border-left: none;border-bottom: 3pt solid rgb(209, 209, 209);border-right: 3pt solid rgb(209, 209, 209);padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin:0cm;line-height:normal;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-family:"Verdana",sans-serif;color:black;'>1 Chance</span></p>
                </td>
            </tr>
            <tr>
                <td style="border-right: 3pt solid rgb(209, 209, 209);border-bottom: 3pt solid rgb(209, 209, 209);border-left: 3pt solid rgb(209, 209, 209);border-image: initial;border-top: none;padding: 0.75pt;height: 9.85pt;vertical-align: top;">
                    <p style='margin:0cm;line-height:normal;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-family:"Verdana",sans-serif;color:black;'>De R$ 500,00 a R$ 749,99</span></p>
                </td>
                <td style="border-top: none;border-left: none;border-bottom: 3pt solid rgb(209, 209, 209);border-right: 3pt solid rgb(209, 209, 209);padding: 0.75pt;height: 9.85pt;vertical-align: top;">
                    <p style='margin:0cm;line-height:normal;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-family:"Verdana",sans-serif;color:black;'>2 Chances</span></p>
                </td>
            </tr>
            <tr>
                <td style="border-right: 3pt solid rgb(209, 209, 209);border-bottom: 3pt solid rgb(209, 209, 209);border-left: 3pt solid rgb(209, 209, 209);border-image: initial;border-top: none;padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin:0cm;line-height:normal;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-family:"Verdana",sans-serif;color:black;'>De R$ 750,00 a R$ 999,99</span></p>
                </td>
                <td style="border-top: none;border-left: none;border-bottom: 3pt solid rgb(209, 209, 209);border-right: 3pt solid rgb(209, 209, 209);padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin:0cm;line-height:normal;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-family:"Verdana",sans-serif;color:black;'>3 Chances</span></p>
                </td>
            </tr>
            <tr>
                <td style="border-right: 3pt solid rgb(209, 209, 209);border-bottom: 3pt solid rgb(209, 209, 209);border-left: 3pt solid rgb(209, 209, 209);border-image: initial;border-top: none;padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin:0cm;line-height:normal;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-family:"Verdana",sans-serif;color:black;'>De R$ 1.000,00 a R$ 1.249,99</span></p>
                </td>
                <td style="border-top: none;border-left: none;border-bottom: 3pt solid rgb(209, 209, 209);border-right: 3pt solid rgb(209, 209, 209);padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin:0cm;line-height:normal;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-family:"Verdana",sans-serif;color:black;'>4 Chances</span></p>
                </td>
            </tr>
            <tr>
                <td style="border-right: 3pt solid rgb(209, 209, 209);border-bottom: 3pt solid rgb(209, 209, 209);border-left: 3pt solid rgb(209, 209, 209);border-image: initial;border-top: none;padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin:0cm;line-height:normal;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-family:"Verdana",sans-serif;color:black;'>De R$ 1.250,00 a R$ 1.499,99</span></p>
                </td>
                <td style="border-top: none;border-left: none;border-bottom: 3pt solid rgb(209, 209, 209);border-right: 3pt solid rgb(209, 209, 209);padding: 0.75pt;height: 10.3pt;vertical-align: top;">
                    <p style='margin:0cm;line-height:normal;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-family:"Verdana",sans-serif;color:black;'>5 Chances</span></p>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<br/><br/>
Além da compra principal, em cada acúmulo o participante recebe seu número da sorte correspondente, que fica disponível para consulta no hotsite da campanha.


</p>
            `,
    },

    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 10,
      id: 10,
      status: true,
      tipoFaq: 1,
      Titulo: "Quais serão as datas dos sorteios? ",
      Texto: `<p style='text-align: justify;'>Serão realizados onze (11) sorteios, conforme tabela abaixo:
      <table style="width:100.0%;border-collapse:collapse;border:none;">
    <tbody>
        <tr>
            <td style="width:43.04%;border:solid windowtext 1.0pt;background:#B4C6E7;padding:0cm 5.4pt 0cm 5.4pt;">
                <p style='margin:0cm;line-height:12.0pt;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><strong><span style='font-size:13px;font-family:"Verdana",sans-serif;color:black;'>Data do Sorteio Loteria Federal</span></strong></p>
            </td>
            <td style="width:56.96%;border:solid windowtext 1.0pt;border-left:  none;background:#B4C6E7;padding:0cm 5.4pt 0cm 5.4pt;">
                <p style='margin:0cm;line-height:12.0pt;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><strong><span style='font-size:13px;font-family:"Verdana",sans-serif;color:black;'>Data da</span></strong></p>
                <p style='margin:0cm;line-height:12.0pt;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><strong><span style='font-size:13px;font-family:"Verdana",sans-serif;color:black;'>Apura&ccedil;&atilde;o</span></strong></p>
            </td>
        </tr>
        <tr>
            <td style="width:43.04%;border:solid windowtext 1.0pt;border-top:  none;padding:0cm 5.4pt 0cm 5.4pt;">
                <p style='margin:0cm;line-height:12.0pt;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-size:13px;font-family:"Verdana",sans-serif;color:black;'>23/07/2022</span></p>
            </td>
            <td style="width:56.96%;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;">
                <p style='margin:0cm;line-height:12.0pt;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-size:13px;font-family:"Verdana",sans-serif;color:black;'>25/07/2022</span></p>
            </td>
        </tr>
        <tr>
            <td style="width:43.04%;border:solid windowtext 1.0pt;border-top:  none;padding:0cm 5.4pt 0cm 5.4pt;">
                <p style='margin:0cm;line-height:12.0pt;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-size:13px;font-family:"Verdana",sans-serif;color:black;'>30/07/2022</span></p>
            </td>
            <td style="width:56.96%;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;">
                <p style='margin:0cm;line-height:12.0pt;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-size:13px;font-family:"Verdana",sans-serif;color:black;'>01/08/2022</span></p>
            </td>
        </tr>
        <tr>
            <td style="width:43.04%;border:solid windowtext 1.0pt;border-top:  none;padding:0cm 5.4pt 0cm 5.4pt;">
                <p style='margin:0cm;line-height:12.0pt;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-size:13px;font-family:"Verdana",sans-serif;color:black;'>06/08/2022</span></p>
            </td>
            <td style="width:56.96%;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;">
                <p style='margin:0cm;line-height:12.0pt;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-size:13px;font-family:"Verdana",sans-serif;color:black;'>08/08/2022</span></p>
            </td>
        </tr>
        <tr>
            <td style="width:43.04%;border:solid windowtext 1.0pt;border-top:  none;padding:0cm 5.4pt 0cm 5.4pt;">
                <p style='margin:0cm;line-height:12.0pt;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-size:13px;font-family:"Verdana",sans-serif;color:black;'>13/08/2022</span></p>
            </td>
            <td style="width:56.96%;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;">
                <p style='margin:0cm;line-height:12.0pt;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-size:13px;font-family:"Verdana",sans-serif;color:black;'>15/08/2022</span></p>
            </td>
        </tr>
        <tr>
            <td style="width:43.04%;border:solid windowtext 1.0pt;border-top:  none;padding:0cm 5.4pt 0cm 5.4pt;">
                <p style='margin:0cm;line-height:12.0pt;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-size:13px;font-family:"Verdana",sans-serif;color:black;'>20/08/2022</span></p>
            </td>
            <td style="width:56.96%;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;">
                <p style='margin:0cm;line-height:12.0pt;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-size:13px;font-family:"Verdana",sans-serif;color:black;'>22/08/2022</span></p>
            </td>
        </tr>
        <tr>
            <td style="width:43.04%;border:solid windowtext 1.0pt;border-top:  none;padding:0cm 5.4pt 0cm 5.4pt;">
                <p style='margin:0cm;line-height:12.0pt;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-size:13px;font-family:"Verdana",sans-serif;color:black;'>27/08/2022</span></p>
            </td>
            <td style="width:56.96%;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;">
                <p style='margin:0cm;line-height:12.0pt;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-size:13px;font-family:"Verdana",sans-serif;color:black;'>29/08/2022</span></p>
            </td>
        </tr>
        <tr>
            <td style="width:43.04%;border:solid windowtext 1.0pt;border-top:  none;padding:0cm 5.4pt 0cm 5.4pt;">
                <p style='margin:0cm;line-height:12.0pt;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-size:13px;font-family:"Verdana",sans-serif;color:black;'>03/09/2022</span></p>
            </td>
            <td style="width:56.96%;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;">
                <p style='margin:0cm;line-height:12.0pt;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-size:13px;font-family:"Verdana",sans-serif;color:black;'>05/09/2022</span></p>
            </td>
        </tr>
        <tr>
            <td style="width:43.04%;border:solid windowtext 1.0pt;border-top:  none;padding:0cm 5.4pt 0cm 5.4pt;">
                <p style='margin:0cm;line-height:12.0pt;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-size:13px;font-family:"Verdana",sans-serif;color:black;'>10/09/2022</span></p>
            </td>
            <td style="width:56.96%;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;">
                <p style='margin:0cm;line-height:12.0pt;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-size:13px;font-family:"Verdana",sans-serif;color:black;'>12/09/2022</span></p>
            </td>
        </tr>
        <tr>
            <td style="width:43.04%;border:solid windowtext 1.0pt;border-top:  none;padding:0cm 5.4pt 0cm 5.4pt;">
                <p style='margin:0cm;line-height:12.0pt;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-size:13px;font-family:"Verdana",sans-serif;color:black;'>17/09/2022</span></p>
            </td>
            <td style="width:56.96%;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;">
                <p style='margin:0cm;line-height:12.0pt;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-size:13px;font-family:"Verdana",sans-serif;color:black;'>19/09/2022</span></p>
            </td>
        </tr>
        <tr>
            <td style="width:43.04%;border:solid windowtext 1.0pt;border-top:  none;padding:0cm 5.4pt 0cm 5.4pt;">
                <p style='margin:0cm;line-height:12.0pt;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-size:13px;font-family:"Verdana",sans-serif;color:black;'>24/09/2022</span></p>
            </td>
            <td style="width:56.96%;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;">
                <p style='margin:0cm;line-height:12.0pt;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-size:13px;font-family:"Verdana",sans-serif;color:black;'>26/09/2022</span></p>
            </td>
        </tr>
        <tr>
            <td style="width:43.04%;border:solid windowtext 1.0pt;border-top:  none;padding:0cm 5.4pt 0cm 5.4pt;">
                <p style='margin:0cm;line-height:12.0pt;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-size:13px;font-family:"Verdana",sans-serif;color:black;'>01/10/2022</span></p>
            </td>
            <td style="width:56.96%;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;">
                <p style='margin:0cm;line-height:12.0pt;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-size:13px;font-family:"Verdana",sans-serif;color:black;'>03/10/2022</span></p>
            </td>
        </tr>
    </tbody>
</table>
              </p>`,
    },

    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 11,
      id: 11,
      status: true,
      tipoFaq: 1,
      Titulo: "Onde serão divulgados os resultados? ",
      Texto: `<p style='text-align: justify;'>Os resultados de cada sorteio serão divulgados no hotsite da campanha, disponível em  <a href= "https://www.pecapremiada.com.br" target="_blank" rel="noopener noreferrer"> www.pecapremiada.com.br </a>, no prazo de até 10 (dez) dias úteis da data da apuração do sorteio. Os resultados também poderão ser divulgados por meio de e-mail marketing </p>`,
    },
    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 12,
      id: 12,
      status: true,
      tipoFaq: 1,
      Titulo: "Ganhei o sorteio, e agora? ",
      Texto: `<p style='text-align: justify;'>A Chevrolet ou parceiro Chevrolet entrará em contato com cada ganhador para pegar as informações necessárias para realizar a premiação.<br/><br/>
      Para o recebimento do prêmio o Contemplado, deverá enviar: (i) cópia legível do seu RG; (ii) cópia legível do CPF/ME; e (iii) cópia legível de seu comprovante de residência, com data de no máximo 180 (cento e oitenta) dias anteriores à data da divulgação dos resultados, em seu nome ou em nome de algum parente ou cônjuge, acompanhado de outros documentos que comprovem seu grau de parentesco ou relação, consoante as instruções que serão fornecidas por meio de correspondência eletrônica e/ou via telefone. Neste ato, o Contemplado deverá ainda, assinar o recibo de entrega de prêmio que constituirá prova de entrega do prêmio.
        
              </p>
            `,
    },

    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 13,
      id: 13,
      status: true,
      tipoFaq: 1,
      Titulo: "De qual sorteio vou participar? ",
      Texto: `<p style='text-align: justify;'>Cada número da sorte quando é gerado irá automaticamente concorrer no sorteio da semana seguinte. <br/><br/>
      Portanto fique atento aos prazos de cada sorteio no regulamento, disponível no hotsite da campanha. 
      
      </p>
            `,
    },

    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 14,
      id: 14,
      status: true,
      tipoFaq: 1,
      Titulo: "Se eu ganhei um dos sorteios, concorro nos demais?",
      Texto: `<p style='text-align: justify;'> 
      Você concorre corresponde aos seus números da sorte nos sorteios semanais, e depois no sorteio final.<br/><br/>
Ou seja, concorre uma vez na compra principal (na semana seguinte) e depois por cada “acúmulo” (se houver) de R$ 250 reais em NFs conforme detalhado nesta FAQ e Regulamento (também nas semanas seguintes correspondentes), e cada número da sorte equivale a uma chance no sorteio final do Carro 0km. 

      </p>
            `,
    },

    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 15,
      id: 15,
      status: true,
      tipoFaq: 1,
      Titulo: "Onde posso fazer o acompanhamento da promoção?",
      Texto: `<p style='text-align: justify;'>O acompanhamento da promoção deve ser feito no hotsite da campanha, disponível em  <a href= "https://www.pecapremiada.com.br" target="_blank" rel="noopener noreferrer"> www.pecapremiada.com.br </a>.  </p>
            `,
    },
    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 16,
      id: 16,
      status: true,
      tipoFaq: 1,
      Titulo: "Posso ser desclassificado da promoção? ",
      Texto: `<p style='text-align: justify;'>O acompanhamento da promoção deve ser feito no hotsite da campanha, disponível em <a href= "https://www.pecapremiada.com.br" target="_blank" rel="noopener noreferrer"> www.pecapremiada.com.br </a></p>
            `,
    },
    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 17,
      id: 17,
      status: true,
      tipoFaq: 1,
      Titulo: "Posso ser desclassificado da promoção?",
      Texto: `<p style='text-align: justify;'>Os Participantes poderão ser excluídos automaticamente da Campanha em caso de tentativa de fraude, falsidade ou qualquer ato de má fé comprovado, não preenchimento dos requisitos previamente determinados e/ou em decorrência de informações incompletas, incorretas ou equivocadas, de acordo com as regras previstas no Regulamento, disponível no hotsite da campanha <a href= "https://www.pecapremiada.com.br" target="_blank" rel="noopener noreferrer"> www.pecapremiada.com.br </a>. </p>
            `,
    },
    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 18,
      id: 18,
      status: true,
      tipoFaq: 1,
      Titulo: "Tenho algum custo para poder usufruir da minha premiação?",
      Texto: `<p style='text-align: justify;'>Não. Os prêmios serão entregues prontos para uso e sem custo do frete! O prêmio do Carro 0km também com todos os custos pagos pela Chevrolet, como emplacamento, licenciamento, documentação, IPVA e deslocamento até a cidade do ganhador.  </p>
            `,
    },
    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 19,
      id: 19,
      status: true,
      tipoFaq: 1,
      Titulo: "Ganhando o vale-brinde, em quanto tempo recebo meu prêmio?",
      Texto: `<p style='text-align: justify;'>O prêmio será entregue, livre e desembaraçado de qualquer ônus no prazo máximo de 30 (trinta) dias, a contar da data da apuração, de acordo com o artigo 5º do Decreto nº 70.951/72, desde que atendidos todos os requisitos apresentados no Regulamento, disponível no hotsite da campanha.</p>
            `,
    },
    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 20,
      id: 20,
      status: true,
      tipoFaq: 1,
      Titulo: "E sobre os VALE-BRINDES da campanha?",
      Texto: `<p style='text-align: justify;'>Os vale-brindes fazem parte da promoção que ocorre concomitantemente à presente Promoção assemelhada a Sorteio (desta FAQ), e com Regulamento e FAQ específico na modalidade assemelhada a vale-brinde, com regras próprias, devendo cada Promoção ser interpretada nos termos de seu regulamento específico. Para conferir todas as regras e dinâmica dos vale-brindes acessar Regulamento e FAQ específicos disponíveis no hotsite da campanha <a href= "https://www.pecapremiada.com.br" target="_blank" rel="noopener noreferrer"> www.pecapremiada.com.br </a>.</p>
            `,
    },
    {
      DataCadastro: "2021-04-02T12:00:29.0551555",
      Ordem: 21,
      id: 21,
      status: true,
      tipoFaq: 1,
      Titulo: "Em caso de dúvidas, com quem posso entrar em contato? ",
      Texto: `<div><p style='text-align: justify;'>Para qualquer tipo de dúvidas, ou auxílio, entre em contato com o SAC da Chevrolet através do nosso Atendimento Digital via WhatsApp, de segunda a sexta das 8h às 21h e aos sábados das 8h às 16h. 
      <br/><br/>
      Disponível no link: <a href= "http://bit.ly/3bFZQfC" target="_blank" rel="noopener noreferrer"> bit.ly/3bFZQfC </a>
      <br/><br/>
      Ou via QR Code:
      <br/><br/>
      </p>`,
    },
  ]);

  const [load, setLoad] = React.useState(false);

  return (
    <>
      {/* <div className="FaqTitle">
        <div className="container">
          <div className="row">
            <div className="col-12 FaqTitleContent">
              <button onClick={() => props.history.goBack()}>
                <img src={iconArrow} alt="voltar" /> Voltar
              </button>
              <div>
                <h2>
                  Campanha: SEU CHEVROLET SEGURO
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="container contentDefault">
        <div className="row">
          <div className="col-12">
            <h1>FAQ - CAMPANHA PEÇA PREMIADA CHEVROLET</h1>
          </div>
        </div>
        {/* 
        <div className="menuItens ">
          <ul className=" col-12 p-4 d-flex justify-content-around">
            <li
              className={`${pathname() === "/faq/vale-combustivel" ? "active" : ""}`}
            >
              <Link to="/faq/vale-combustivel">
                {" "}
                <span className="h4">Vale-Combustível</span>
              </Link>
            </li>

            <li className={`${pathname() === "/faq/sorteio" ? "active" : ""}`}>
              <Link to="/faq/sorteio"> <span className="h4">Sorteio</span> </Link>
            </li>
          </ul>
        </div> */}

        <GoogleReCaptcha onVerify={handleVerify} />
        <div className="row">
          <div className="col-12 mt30">
            {/* <DocPolitica></DocPolitica> */}

            {pathname() === "/faq" && <Faqs faqs={faqlist} load={load} />}
            {/* {pathname() === "/faq/vale-combustivel" && (
              <Faqs faqs={faqlist} load={load} />
            )}
            {pathname() === "/faq/sorteio" && (
              <Faqs faqs={faqlistsorteio} load={load} />
            )} */}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (store) => ({
  snackbarState: store.snackbarState,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ showMsgS, showMsgW, showMsgE }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
