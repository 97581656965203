import React, { useEffect } from "react";
import * as moment from "moment";
import { Link } from "react-router-dom";
import bannerHome from "../../assets/img/banners/servico-premiado/banner-home.jpg";

import "./valecombustivel.scss";

import StatusRecall from "../../components/StatusRecall/StatusRecall";
import { useHistory } from "react-router-dom";
import Mask from "../../services/mask";
//import Utils from '../../services/serviceUtils';

import { trackPromise } from "react-promise-tracker";
import ApiServices from "../../services/apiServices";

/////////////////////////////
// redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  showMsgS,
  showMsgW,
  showMsgE,
} from "../../store/actions/snackbarActions.js";

//import { saveAs } from 'file-saver';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import HelpOutline from "@material-ui/icons/HelpOutline";

import Regulamento from "../../components/Docs/RegulamentoBrinde";
import BannerInicial from "../../assets/black/bannerPrincipal.png";
import bannerMobile from "../../assets/black/bannerMobilePrincipal.png";

import volante from "../../assets/img/volante-line.png";

import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
  useGoogleReCaptcha,
  withGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { useState } from "react";

const Recall = (props) => {
  //const dateNow = new Date().toISOString()
  const history = useHistory();
  const [openModal, setOpenModal, setModalContent, modalContent, purify] =
    React.useState(false);

  return (
    <>
      <DialogContent>
        z
        {props.type === "pendente" && (
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <h3>
                Chassi <strong>{props.chassi} </strong>
                envolvido no Recall de Airbags Takata.
              </h3>
            </div>
            <div className="col-md-12 col-sm-12 mt30">
              <StatusRecall status={false} />
              <p className="big bold">Data de atualização: {props.date}</p>
            </div>
            <div className="col-md-12 col-sm-12 mt30">
              <h3>Agende agora seu Recall de Airbags Takata</h3>
            </div>
            <div className="col-md-8 offset-md-2 col-sm-12 text-center mt30">
              <a
                href="https://www.chevrolet.com.br/servicos/recalls/airbag-takata/solicitar-troca"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary btn-block btn-lg"
              >
                Clique e agende
              </a>
            </div>
          </div>
        )}
        {props.type === "naoEnviado" && (
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <h3>
                Chassi <strong>{props.chassi} </strong>
                não envolvido no Recall de Airbags Takata.
              </h3>
            </div>
            <div className="col-md-12 col-sm-12 mt30">
              <p className="big bold">
                Data de atualização: {props.date}
                {/* {moment(dateNow).format('DD/MM/YYYY')} */}
              </p>
            </div>
            <div className="col-md-12 col-sm-12 mt30">
              <h3>
                Saiba se seu veículo está participando de algum outro Recall da
                Chevrolet.
              </h3>
            </div>
            <div className="col-md-8 offset-md-2 col-sm-12 text-center mt30">
              <a
                href="https://www.chevrolet.com.br/servicos/recalls"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary btn-block btn-lg"
              >
                Consulte
              </a>
            </div>
          </div>
        )}
        {props.type === "executado" && (
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <h3>
                Chassi <strong>{props.chassi} </strong>
                envolvido no Recall de Airbags Takata.
              </h3>
            </div>
            <div className="col-md-12 col-sm-12 mt30">
              <StatusRecall status={true} />
              <p className="big bold text-center">
                Serviço realizado em: {props.servico}
              </p>
              <p className="big bold">Data de atualização: {props.date}</p>
            </div>

            {/* <div className="col-md-8 offset-md-2 col-sm-12 text-center mt30">
                          <button className="btn btn-primary btn-block btn-lg">
                              Conheça o regulamento do sorteio
                          </button>
                      </div> */}
          </div>
        )}
        {/* {props.type !== "pendente" &&
            props.type !== "naoEnviado" &&
            props.type !== "executado" && (
              <div className="row">
                <div className="col-md-12 col-sm-12">Status não encontrado</div>
              </div>
            )} */}
      </DialogContent>
    </>
  );
};
const ModalCpf = (props) => {
  return (
    <DialogContent>
      {props.status === "disponivel" && (
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <h3>Registro - CPF Localizado</h3>
            <p className="big bold">Data de atualização: {props.date}</p>

            <p className="big mt30">Crédito disponível para uso.</p>
          </div>
        </div>
      )}

      {props.status === "localizado" && (
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <h3>Registro - CPF Localizado</h3>
            <p className="big bold">Data de atualização: {props.date}</p>

            <p className="big mt30">
              Aguardando processamento e validação da documentação.
            </p>
          </div>
        </div>
      )}

      {props.status === "naoLocalizado" && (
        <div className="row">
          <div className="col-md-4 col-sm-3 col-5 mb20">
            <img
              src={volante}
              alt="volante"
              className="responsive"
              style={{ marginLeft: "-60px" }}
            />
          </div>
          <div className="col-md-8 col-sm-12">
            <h3>Registro - CPF não localizado</h3>
            <p className="big">Data de atualização: {props.date}</p>

            <h3 className="mt30">Já fez o Recall?</h3>
            <p>
              Caso você tenha efetuado o Recall de Airbags Takata do Celta (ano
              de fabricação 2013 a 2016) ou Classic (ano de fabricação 2012 a
              2016) na validade da Campanha, contate a Concessionaria Chevrolet
              responsável pelo seu Recall e solicite o seu cadastro. O
              processamento pode demorar até 5 dias após a realização do Recall.
            </p>

            <Regulamento textoBtn={"Conheça o regulamento da Campanha"} />
            {/* styleBtn={'container'}  <button className="btn btn-primary btn-block btn-lg mt30">
                            Conheça o regulamento do sorteio
                        </button> */}
          </div>
        </div>
      )}

      {props.status !== "disponivel" &&
        props.status !== "localizado" &&
        props.status !== "naoLocalizado" && (
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <p>Status não localizado</p>
            </div>
          </div>
        )}
    </DialogContent>
  );
};

function ValeCombustivel(props) {
  const [cpf, setCpf] = React.useState("");
  const [cpfResponse, setCpfResponse] = React.useState("");
  const [cpfData, setCpfData] = React.useState("");

  const [open, setOpen] = React.useState(false);
  const [openCpf, setOpenCpf] = React.useState(false);
  const history = useHistory();
  const modalOpenCpf = () => {
    setOpenCpf(true);
  };
  const modalCloseCpf = () => {
    setOpenCpf(false);
  };

  const [chassi, setChassi] = React.useState("");
  const [chassiResponse, setChassiResponse] = React.useState("");
  const [chassiData, setChassiData] = React.useState("");
  const [dataServico, setdataServico] = React.useState("");

  // const [usuarioLogado, setUsuarioLogado] = React.useState([]);

  const modalOpenRecall = () => {
    setOpen(true);
  };
  const modalCloseRecall = () => {
    setOpen(false);
  };

  const changeCPF = (event) => {
    setCpf(Mask.cpf(event.target.value));
  };

  const sendChassi = () => {
    if (chassi.length === 17) {
      trackPromise(
        //ApiServices.apiPost('valeCombustivel/buscarChassi', {chassi})
        ApiServices.apiGet(`valeCombustivel/statusChassi?chassi=${chassi}`)
          .then((res) => {
            if (res.success) {
              if (res.data.statusCodido === "1") {
                setChassiResponse("naoEnviado");
              } else if (res.data.statusCodido === "2") {
                setChassiResponse("executado");
              } else if (res.data.statusCodido === "3") {
                setChassiResponse("pendente");
              }

              setChassiData(res.data.dataAtualizacao);
              setdataServico(res.data.dataServico);

              modalOpenRecall();
            } else {
              props.showMsgW(res.errors);
            }
          })
          .catch((res) => {
            props.showMsgW(`Erro ao buscar chassi`);
          })
      );
    } else {
      props.showMsgW(`Chassi inválido`);
    }
  };

  const changeChassi = (event) => {
    if (event.target.value.length <= 17) {
      setChassi(event.target.value);
    }
  };

  const [openNew, setOpenNew] = React.useState(false);
  const modalOpenNew = () => {
    setOpenNew(true);
  };
  const modalCloseNew = () => {
    setOpenNew(false);
  };

  const sendCpf = () => {
    setCpfResponse("localizado");
    setCpfData("2011-10-05T14:48:00.000Z");

    //if (Utils.validaCPF(cpf)) {
    trackPromise(
      ApiServices.apiGet(`valeCombustivel/statusCPF?cpf=${Mask.unMask(cpf)}`)
        .then((res) => {
          if (res.success) {
            if (res.data.statusCodido === "1") {
              setCpfResponse("naoLocalizado");
            } else if (res.data.statusCodido === "2") {
              setCpfResponse("localizado");
            } else if (res.data.statusCodido === "3") {
              setCpfResponse("disponivel");
            }
            setCpfData(res.data.dataAtualizacao);
            modalOpenCpf();
          } else {
            props.showMsgW(res.errors);
          }
        })
        .catch((res) => {
          //alert(JSON.stringify(res));
          props.showMsgW(`Erro ao buscar cpf`);
        })
    );
    //} else { props.showMsgW(`CPF inválido`) }
  };

  // const downloadReg = () => {
  //     saveAs(fileRegulamento, 'regulamento-vale-combustivel.pdf')
  // }

  useEffect(() => {
    modalOpenNew();
  }, []);

  function handleVerify(valor) {
    props.handleVerify(valor);
  }

  const [fecharModal, setFecharModal] = useState(true);
  return (
    <>
      {fecharModal && (
        <div className="modalFimCampanha">
          <div className="conteudoModalFimCampanha">
            <div
              className="fecharModalCampanha"
              onClick={() => {
                setFecharModal(!fecharModal);
              }}
            >
              <CloseIcon fontSize="large" />
            </div>
            <b>Campanha encerrada.</b>
          </div>
        </div>
      )}

      <GoogleReCaptcha onVerify={handleVerify} />
      {/* <Banner imgTitulo={bannerTitle} imgTituloMd={bannerTitleMd} imgMain={bannerImg} igmBg={bannerBg} type={'phone'} />  */}

      <div className="bgMainFeature">
        {/* <a href={fileRegulamento} target="_blank" rel="noopener noreferrer" ><img src={bannerSiglePage} alt="banner" className="d-none d-md-block d-lg-block responsive mAuto" /></a>
        <a href={fileRegulamento} target="_blank" rel="noopener noreferrer" ><img src={bannerSiglePageMobile} alt="banner" className="d-block d-md-none d-lg-none responsive mAuto" /></a> */}

        <a href="/como-funciona" target="_blank" rel="noopener noreferrer">
          <img
            src={BannerInicial}
            alt="banner"
            className="d-none d-md-block responsive mAuto"
          />
          <img
            src={bannerMobile}
            alt="banner"
            className="d-md-none d-block responsive mAuto"
          />
        </a>
      </div>

      <div className="container contentDefaultInicio">
        <div className="row">
          <div className="col-12 text-center text-md-left">
            <i>
              <h2 className="titulo-inicial">
                Promoção “Peça Premiada Chevrolet"
              </h2>
            </i>
          </div>

          <div className="col-12">
            <p className="big">
              Adquira Peças Chevrolet e concorra a um brinde instantâneo.
            </p>
            <br></br>
            <div className="w-100 d-flex justify-content-center pb-5">
              <a
                href="https://www.pecachevrolet.com.br"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  style={{ width: "300px" }}
                  className="btn btn-primary btn-lg"
                >
                  ADQUIRA SUAS PEÇAS AQUI
                </button>
              </a>
            </div>
            <p className="text-justify big">
              Promoção exclusiva para os clientes que realizarem compras de
              peças, a partir de 07 de Agosto de 2023 até 31 de Outubro de 2023,
              seguindo os critérios de elegibilidade e se cadastrarem na
              campanha, conforme detalhado no{" "}
              <Link to={"/regulamento/vale-brinde"}>Regulamento</Link> e{" "}
              <Link to={"/faq"}>FAQ</Link> disponíveis.
            </p>
            <br></br>
            <p className="text-justify big">
              <b>Lembrando: </b>Consulte as condições de participação e demais
              informações no regulamento da modalidade Vale-Brinde (SRE/ME Nº
              05.028814/2023) disponível 
              <Link to={"/regulamento/vale-brinde"}>aqui </Link>
            </p>
            <br></br>
            <p className="big">
              Clique em “Saiba Mais” para saber como participar e cadastre-se!
            </p>
          </div>
        </div>
      </div>
      {/* <div className="col-md-12 text-center ">
        <div style={{ marginBottom: "30px" }}>
          <button
            style={{ width: "300px" }}
            className="btn btn-primary btn-lg"
            onClick={() => {
              history.push("/cadastro");
            }}
          >
            CADASTRE-SE
          </button>
        </div>
      </div> */}
      <div className="faixaTelefone px-4">
        Dúvidas, entrar em contato pelo SAC: 0800 702 4200 ou (11)
        5593-5139, para whatsapp, clique{" "}
        <a
          href="https://api.whatsapp.com/send/?phone=5511998828157&text=Fale%20Aqui%20com%20Atendimento%20Chevrolet%20no%20WhatsApp"
          target="_blank"
          rel="noopener noreferrer"
          className="texto-aqui"
        >
          aqui
        </a>
        .
      </div>
      <div className="col-md-12 text-center ">
        <div className="mt30 " style={{ marginBottom: "30px" }}>
          <Link to="como-funciona">
            <button style={{ width: "300px" }} className="btn btn-gray btn-lg">
              SAIBA MAIS
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (store) => ({
  snackbarState: store.snackbarState,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ showMsgS, showMsgW, showMsgE }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ValeCombustivel);
