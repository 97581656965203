import React from "react";

import { saveAs } from "file-saver";

import imgRegA from "../../../assets/img/docs/regulamento-1.PNG";
import print from "../../../assets/img/icons/print.png";
// import fileRegulamento from "../../../assets/img/docs/Regulamento-Voucher.pdf";
import fileRegulamento from "../../../components/RegulametosPDF/RegulamentoPecaPremiada.pdf";
import pdfRegulamento from "../../../components/RegulametosPDF/RegulamentoPecaPremiada.pdf";

// import pdfRegulamento from "../Texto/Regulamento_Aditamento_Autorizado.pdf";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import DocViewer from "@cyntler/react-doc-viewer";

const RegulamentoConteundo = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const downloadReg = () => {
    saveAs(fileRegulamento, "regulamento-vale-combustivel.pdf");
  };

  return (
    <>
      {/* <embed width='100%' height='500px' className='m0' src={pdfRegulamento}/>  */}
      <div style={{ height: "700px", width: "100%" }}>
        <DocViewer
          documents={[{ uri: pdfRegulamento }]}
          style={{
            width: "100%",
            border: "solid #ddd 1px",
          }}
        />
      </div>
    </>

    // <div className="row">

    //     <div className="col-12">
    //         <h3>Campanha Recall AirbagTakata</h3>
    //         <h4>“Seu Chevrolet Seguro”</h4>
    //         <br/>

    //         <p className="bold">
    //             REGULAMENTO GERAL
    //         </p>
    //         <p>
    //             Empresa Promotora: <strong>GENERAL MOTORS DO BRASIL LTDA.</strong>, com sede na Avenida Goiás, 1805, São Caetano
    //             do Sul – SP, CNPJ nº 59.275.792/0001-50 (doravante <strong>Promotora</strong> ou <strong>GMB</strong>).
    //         </p>
    //         <p>
    //             A presente Campanha tem como alvo a realização do maior número possível de serviços de recall dos airbags Takata nos veículos
    //             Chevrolet modelos Celta e Classic através da distribuição de crédito, dentro de nosso Catálogo de Prêmios Exclusivo (“Catálogo”)
    //             para a campanha, aos clientes destes modelos que realizarem o serviço de recall conforme as regras do regulamento aqui descritas
    //             sendo válida para todo território nacional.
    //         </p>

    //         <br/>
    //         <p className="bold">1. DO PRAZO E LOCAL DA CAMPANHA DE ENGAJAMENTO</p>
    //         <p>
    //             <strong>1.1.</strong> A presente Campanha será executada no período de 01 de Abril de 2022 até 30 de Setembro de 2022, podendo ser prorrogada.
    //         </p>

    //         <br/>
    //         <p className="bold">2. ELEGIBILIDADE DE PARTICIPAÇÃO:</p>
    //         <p>
    //             <strong> 2.1.</strong> Poderão participar desta Campanha todos os proprietários (ou condutores, de acordo com a cláusula abaixo) dos veículos
    //             Chevrolet modelos Celta (ano de fabricação 2013 a 2016) e Classic (ano de fabricação 2012 a 2016), que comprovadamente realizarem o serviço do
    //             Recall dos airbags, no período de vigência da campanha, solicitando o crédito em nosso Catálogo por meio de termo de aceite disponibilizado na
    //             Concessionária responsável, desde que atendam a todas as condições a seguir:
    //         </p>

    //         <ol type="a">
    //             <li>
    //                 Proprietários pessoas físicas. Não serão elegíveis proprietários que sejam pessoas jurídicas.
    //             </li>
    //             <li>
    //                 Que sejam proprietários do veículo na data em que foi realizado o serviço – a propriedade do veículo na data do serviço deverá ser
    //                 comprovada por meio da apresentação do documento CRLV (através do Certificado de Registro e de Licenciamento dedo Veículo) e válido no
    //                 momento do Recall pelo DETRAN da localidade de emplacamento antes da disponibilidade do crédito.
    //             </li>
    //             <li>
    //                 Caso a premiação precise ser direcionada para o Condutor (em caso do proprietário não puder ser contemplado), será aceito apenas quando o
    //                 documento original do veículo (CRLV) for apresentado e quando a Ordem de Serviço realizada na concessionária for feita em nome do
    //                 Condutor (em seu CPF). Sendo utilizada a Ordem de Serviço também como documento de comprovação.
    //             </li>
    //             <li>
    //                 Que os veículos possuam os chassis dentre a seguinte série:
    //                 <br/>• Celta (Ano de Fabricação 2013 a 2016): chassi de DG124288 até GG100849
    //                 <br/>• Classic (Ano de Fabricação 2012 a 2016): chassi de CC174331 até GR160004
    //             </li>
    //         </ol>

    //         <br/>
    //         <p className="bold">3. MECÂNICA DA CAMPANHA</p>
    //         <p>
    //             <strong>3.1.</strong>A campanha vai premiar os proprietários (ou condutores) que atendam as condições citadas no item 2.1 e que realizarem o serviço de
    //             recall de substituição do airbag do lado do motorista dentro do período da campanha com crédito no valor de R$ 500,00 (quinhentos reais) reais, por veículo,
    //             a ser disponibilizado dentro de nosso Catálogo.
    //         </p>

    //         <p>
    //             <strong>3.2.</strong> Concluído o serviço na concessionária, a própria concessionária preencherá um Termo de Adesão em nome do proprietário
    //             (ou condutor nomeado na Ordem de Serviço da Concessionária) do veículo e solicitará cópia do Certificado de Registro de Licenciamento do Veículo
    //             (no caso do condutor, será a própria Ordem de Serviço em nome do condutor) e da CNH do proprietário para verificar o atendimento do
    //             quesito de elegibilidade promoção conforme item 2.1.
    //         </p>
    //         <p>
    //             <strong>3.3.</strong> Os documentos necessários no item 3.2 serão, portanto:
    //         </p>

    //         <p className="ml30">
    //             <strong>3.3.1.</strong> Caso quem tenha levado o veículo para realização do serviço seja o proprietário: CRLV do veículo
    //             (Certificado de Registro e Licenciamento de Veículo), CNH e Termo de Adesão.
    //         </p>

    //         <p className="ml30">
    //             <strong>3.3.2.</strong> Caso não tenha sido o proprietário, o condutor poderá indicar o proprietário para participar da
    //             promoção mas apenas o proprietário receberá o prêmio. Serão necessários igualmente o CRLV do veículo
    //             (Certificado de Registro e Licenciamento de Veículo), CNH do proprietário e Termo de Adesão.
    //         </p>

    //         <p className="ml30">
    //             <strong>3.3.3.</strong>Caso o proprietário esteja impossibilitado de receber a premiação por qualquer motivo, o
    //             condutor que abriu a Ordem de Serviço na Concessionaria poderá receber a premiação no lugar do proprietário.
    //         </p>

    //         <br/>
    //         <p className="bold">4. DISPONIBILIZAÇÃO DO PRÊMIO</p>
    //         <p>
    //             <strong>4.1.</strong> O participante poderá consultar seu status (disponibilização do crédito) hot site da campanha
    //              <a href="https://www.seuchevroletseguro.com.br" target="_blank" rel="noopener noreferrer">www.seuchevroletseguro.com.br </a>
    //              que deverá ser liberado em até 05 dias úteis contados a partir do preenchimento do Termo de Adesão.
    //              Após confirmação, o seu crédito já estará disponibilizado no Catálogo da promoção Seu Chevrolet Seguro em:
    //              <a href="https://seuchevroletseguro.premmiar.io/" target="_blank" rel="noopener noreferrer">https://seuchevroletseguro.premmiar.io/. </a>
    //         </p>
    //         <p>
    //             <strong>4.2.</strong> Assim que liberado, o participante receberá via e-mail cadastrado toda a orientação e
    //             dados de acesso para logar no Catálogo e assim resgatar sua premiação desejada.
    //         </p>
    //         {/* <p className="ml30">
    //             IOS - <a href="https://apps.apple.com/br/app/shell-box-pague-e-ganhe/id1037433060?l=en" target="_blank" rel="noopener noreferrer">https://apps.apple.com/br/app/shell-box-pague-e-ganhe/id1037433060?l=en</a>
    //         </p>
    //         <p className="ml30">
    //             ANDROID - <a href="https://play.google.com/store/apps/details?id=com.raizen.acelera" target="_blank" rel="noopener noreferrer">https://play.google.com/store/apps/details?id=com.raizen.acelera</a>
    //         </p> */}

    //         <p>
    //             <strong>4.3.</strong> O acesso ao Catálogo da promoção Seu Chevrolet Seguro
    //             (<a href="https://seuchevroletseguro.premmiar.io/" target="_blank" rel="noopener noreferrer">https://seuchevroletseguro.premmiar.io/ </a>)
    //             deverá ocorrer mediante cadastro através da orientação e informações disponibilizadas no e-mail enviado após liberação do crédito.
    //         </p>
    //         <p>
    //             <strong>4.4.</strong> Dentro do Catálogo o participante pode resgatar seu prêmio em vale combustível ou qualquer outro produto/serviço disponível.
    //             Para prêmios de valores superiores, basta o participante completar o valor usando seu método de pagamento desejado.
    //             O resgate funciona como qualquer outra compra na internet.
    //         </p>

    //         <p>
    //             <strong>4.5.</strong> O prazo máximo para uso do crédito é de 6 (seis) meses, contados a partir da data de sua disponibilização no Catálogo.
    //             Após este período, os créditos expirarão automaticamente, sem direito a reembolso, não cabendo ao Participante o direito de compensação ou indenização de qualquer natureza.
    //         </p>

    //         <p>
    //             <strong>4.6.</strong> O crédito é virtual e intransferível, apenas o Participante que for proprietário (ou o condutor contemplado) do veículo poderá receber a premiação.
    //         </p>
    //         <p>
    //             <strong>4.7.</strong> A Promotora não se responsabiliza pela utilização ou não da premiação, ou por eventuais danos/prejuízos físicos, materiais e/ou morais que o
    //             ganhador e/ou terceiros venham a sofrer pelo usufruto do mesmo, isentando-se de quaisquer responsabilidades sobre tal.
    //         </p>

    //         <br/>
    //         <p className="bold">DISPOSIÇÕES GERAIS</p>
    //         <p>
    //             <strong>5.1.</strong> Os Participantes poderão ser excluídos automaticamente da Campanha em caso de tentativa de fraude,
    //             falsidade ou qualquer ato de má fé comprovado, não preenchimento dos requisitos previamente determinados e/ou em decorrência de informações incompletas,
    //             incorretas ou equivocadas, de acordo com as regras previstas neste Regulamento.
    //         </p>
    //         <p>
    //             <strong>5.2.</strong> A Promotora poderá, a seu exclusivo critério, solicitar ao Participante que demonstre, no prazo de 05 (cinco) dias úteis,
    //             a exatidão e/ou veracidade das informações declaradas pelo respectivo Participante. Caso não se verifique a exatidão e/ou veracidade das informações,
    //             o Participante será excluído da Campanha, independente do envio de qualquer notificação.
    //         </p>
    //         <p>
    //             <strong>5.3.</strong> Desde o “aceite” os contemplados autorizam a Promotora e/ou qualquer empresa do grupo General Motors a utilizar suas imagens,
    //             voz, e nomes em materiais publicitários, no que se referir à conquista do prêmio, veiculados em mídia eletrônica, fotos, cartazes, anúncios
    //             em jornais e revistas ou em qualquer outra mídia, com abrangência global, sem qualquer ônus à Promotora e por prazo indeterminado.
    //         </p>
    //         <p>
    //             <strong>5.4.</strong>A Promotora se reserva no direito de efetuar qualquer cancelamento/alteração no presente Regulamento, sem prévio aviso.
    //         </p>
    //         <p>
    //             <strong>5.5.</strong> Os Participantes por si ou por meio de seus representantes legais autorizam a coleta, armazenamento,
    //             tratamento e utilização, de seus endereços físicos, eletrônicos, telefones e demais dados informados, única e exclusivamente
    //             para utilização, pela Promotora, com o propósito de operacionalização e divulgação da Promoção, formação de cadastro,
    //             integrando o banco de dados da Promotora, reforço de mídia publicitária e divulgação desta Promoção, entrega dos prêmios,
    //             nos limites do código de defesa do consumidor e lei de proteção de dados pessoais, sem nenhum ônus para a Promotoras,
    //             as quais poderão compartilhar referidos dados com as empresas responsáveis pela entrega do crédito de combustível.
    //         </p>
    //         <p>
    //             <strong>5.6.</strong> Casos omissos neste Regulamento serão submetidos à avaliação da Promotora.
    //         </p>
    //         <p>
    //             <strong>5.7.</strong> A participação nesta Campanha caracteriza a aceitação de todos os termos e
    //             condições constantes deste Regulamento, servindo, ainda, como declaração de que o Participante
    //             contemplado não possui impedimento de ordem fiscal, legal ou de qualquer outra natureza, que o impeça
    //             de receber e/ou usufruir os prêmios distribuídos, bem como que todas as informações prestadas em razão de sua participação são verdadeiras.
    //         </p>
    //         <p>
    //              <strong>5.8.</strong> A Promotora será responsável por acompanhar todo o andamento da Campanha.
    //         </p>
    //     </div>

    //     <div className="col-12 text-center">
    //         <button className="btn btn-primary" onClick={downloadReg}>
    //             <img src={print} alt="icon download" style={{width: "30px"}}/> Download
    //         </button>
    //     </div>
    // </div>
  );
};

export default RegulamentoConteundo;
