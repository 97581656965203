import React, { Component } from 'react';

import RegulamentoConteundo from '../../components/Docs/Texto/RegulamentoConteudo';
 
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha,
    useGoogleReCaptcha,
    withGoogleReCaptcha
  } from 'react-google-recaptcha-v3';

class RegulamentoPage extends Component {

    //componentDidMount(props){
    //    this.handleVerify = props.handleVerify;
    //}

    render() {
        function handleVerify(valor){
            
            this.props.handleVerify(valor);
        }
        return (
            <div className="container contentDefault">
                <GoogleReCaptcha onVerify={handleVerify} />
                <div className="row">
                    <div className="col-12">
                        {/* <DocPolitica></DocPolitica> */}
                        <RegulamentoConteundo />
                    </div>
                </div>
            </div>
        )
    }

}

export default RegulamentoPage;
