import React, { Fragment, useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { Link, Redirect, useHistory } from "react-router-dom";
import TableHeader from "../../components/Table/TableHeader";
import ApiServices from "../../services/apiServices"; 
import Storage from "../../services/storage";
import RRoleta from "../Roleta/Roleta";

const tabelaColunas = [
  "Data",
  "Premiação",
  // "Número da sorte",
  // "Status sorteio semanal",
  // "Premiação sorteio semanal",
  // "Pontos gastos",
];

const Premiacao = (props) => {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [pesquisa, setPesquisa] = useState("");
  const [paraRoleta, setParaRoleta] = useState(false);

  const [premiacoes, setPremiacao] = useState({
    page: 1,
    per_page: 5,
    rows: [],
    pesquisa: "",
    total: 0,
    total_pages: 0,
  });

  const listarPremiacao = () => {
    ApiServices.apiPostWithTokenPremiacao(
      "sorteios/listagem/portal",
      page,
      perPage,
      pesquisa
    ).then((data) => {
      if (data.success == true) {
        setPremiacao(data.data);
      }
    });
  };

  const changePage = () => {
    if (perPage < premiacoes.total) {
      ApiServices.apiPostWithTokenPremiacao(
        "sorteios/listagem/portal",
        page,
        perPage + 5,
        pesquisa
      ).then((data) => {
        if (data.success == true) {
          setPerPage(perPage + 5);
          setPremiacao(data.data);
        }
      });
    }
  };

 

  useEffect(() => { 
    listarPremiacao();
    ApiServices.apiRoletaGet(
      "participante/autenticarParticipante/" +
        Storage.getUser(`DEVP_USER_INFOS`).userClaims.id
    ).then((data) => { 
      Storage.del("DEVP_USER_INFOS_ROLETA");
      Storage.set("DEVP_USER_INFOS_ROLETA", data); 
    });
  }, []);

  return (
    <Fragment>
      <br />
      <div className="container">
        <h1 className="text-center">
        <strong>Peça Premiada</strong>
        </h1>
        <h4 className="text-center">
          Aperte o botão abaixo para ver suas chances e girar a roleta.
        </h4>
        <h1 className="text-center">Boa sorte!</h1>
      </div>
      <br />

      {/* <div className="bgFeature2">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div>
                <div className={styles.tamanhoFontePontos}>Total de pontos</div>
                <h4>Ganhos</h4>
                <div className={styles.horizontal}></div>
                <div className={styles.tamanhoFonteNumero}>4.500</div>
              </div>
            </div>
            <div className="col-md-4">
              <div>
                <div className={styles.tamanhoFontePontos}>Total de pontos</div>
                <h4>Utilizado</h4>
                <div className={styles.horizontal}></div>
                <div className={styles.tamanhoFonteNumero}>4.500</div>
              </div>
            </div>
            <div className="col-md-4">
              <div>
                <div className={styles.saldo}></div>
                <h4>Saldo</h4>
                <div className={styles.horizontal}></div>
                <div className={styles.tamanhoFonteNumero}>4.500</div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="container contentDefault">
        <div className="row justify-content-center">
        
          <div className="col-5 text-center ">
            <button
              className="btn btn-primary btn-lg"
              onClick={() => {
                // fnParaRoleta();
                setParaRoleta(true) 
              }}>
              TESTAR A MINHA SORTE
            </button>
            {paraRoleta && <Redirect to='/roleta'></Redirect>}
          </div>
        </div>
      </div> */}

      <div>
        <hr />
        <div className="row">
          <div className="col-12 mt20">
            <div className=" container table-responsive">
              <h3>Histórico</h3>
              <br />
              <table className="table">
                <TableHeader columns={tabelaColunas} />
                <tbody>
                  {premiacoes?.rows === 0 && (
                    <tr>
                      <td colSpan="7"> Sem Registros </td>
                    </tr>
                  )}
                    

                  {premiacoes?.rows &&
                    premiacoes?.rows?.map((premiacao, key) => (
                      <tr key={key}>
                        <td>{premiacao.dataCadastro} </td>
                        <td>{premiacao.Premiação} </td>


                        {/* <td>{premiacao.numeroSorteio}</td> */}
                        {/* <td>{premiacao.statusApuracao} </td>
                        <td>{premiacao.premiacaoSorteioSemanal} </td>
                        <td>{premiacao.pontosGastos} </td> */}

                      </tr>
                    ))}
                </tbody>
              </table>
              {/* <TablePagination data={premiacoes} changePage={changePage} /> */}
            </div>
          </div>
        </div>
      </div>
      <div className=" col-12 text-center">
        <button
          className="mt-4 btn btn-outline-primary btn-lg"
          onClick={() => changePage()}>
          VER MAIS
        </button>
      </div>
      <br />
      <br />
    </Fragment>
  );
};

export default Premiacao;
