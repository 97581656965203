import React, { Fragment, useEffect,useCallback, useState } from 'react';

//import '../Sorteio/Sorteio';
import Sorteio from '../../views/Sorteio/Sorteio';

import Premiacao from '../../views/Premiacao/Premiacao';

import FaqSorteio from '../../views/Faq/FaqSorteio';

import Politica from '../../views/Politica/Politica';

import RegulamentoPage from '../../views/Regulamento/RegulamentoPage';

import Storage from "../../services/storage";

import ValeCombustivel from "../../views/ValeCombustivel/ValeCombustivel";

import Home from '../../views/Home/Home';

import Faq from '../../views/Faq/Faq';

import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha,
    useGoogleReCaptcha
  } from 'react-google-recaptcha-v3';
 
import ComoFunciona from '../../views/ComoFunciona/ComoFunciona';
import NotasFiscais from '../../views/NotasFiscais';
import CadastroNf from '../../views/NotasFiscais/cadastro';
import Login from '../../views/Login/Login';
import Logout from '../../views/logout/Logout';
import EsqueciSenha from '../../views/EsqueciSenha/EsqueciSenha';
import RecuperarSenha from '../../views/RecuperarSenha/RecuperarSenha';
import RRoleta from '../../views/Roleta/Roleta';   
import globals from '../../globals'; 

function GeneralCaptcha(prop){

    const [direcionar,setDirecionar] = useState('');

    useEffect(() => {
       //var url = window.location.href;
       //url = url.replace(BASESITE,'');
       //alert(JSON.stringify(prop.location.pathname) );
       setDirecionar(prop.location.pathname);
    }, [prop]);
    
    function handleVerify(valor){
        Storage.set("captcha", valor);
    }

    return (
        <GoogleReCaptchaProvider reCaptchaKey={globals.REACT_APP_RECAPTCHA_KEY} >
            {
                (direcionar=='/')?
                    <ValeCombustivel handleVerify={handleVerify}  />:null
            }
            {
                (direcionar=='' || direcionar=='/home')?
                    <Home handleVerify={handleVerify}  />:null
            }
            {
                (direcionar=='/como-funciona')?
                    <ComoFunciona handleVerify={handleVerify}  />:null
            }
            {
                (direcionar=='/peca-premiada')?
                    <Premiacao handleVerify={handleVerify}  />:null
            }
            {
                (direcionar=='/roleta')?
                    <RRoleta handleVerify={handleVerify}  />:null
            }
            {
                (direcionar=='/sorteio')?
                    <Sorteio handleVerify={handleVerify}  />:null
            }
            {
                (direcionar=='/faq-sorteio')?
                    <FaqSorteio handleVerify={handleVerify}  />:null
            }
            {
                (direcionar=='/faq')?
                    <Faq handleVerify={handleVerify}  />:null
            }
            {/* {
                (direcionar=='/faq/vale-combustivel')?
                    <Faq handleVerify={handleVerify}  />:null
            }
            {
                (direcionar=='/faq/sorteio')?
                    <Faq handleVerify={handleVerify}  />:null
            } */}
            {
                (direcionar=='/politica-de-privacidade')?
                    <Politica handleVerify={handleVerify}  />:null
            }
            {
                (direcionar=='/regulamento/vale-brinde')?
                    <RegulamentoPage handleVerify={handleVerify}  />:null
            }
            {
                (direcionar=='/regulamento/sorteio')?
                    <RegulamentoPage handleVerify={handleVerify}  />:null
            }
            {
                (direcionar=='/regulamento')?
                    <RegulamentoPage handleVerify={handleVerify}  />:null
            }
             {
                (direcionar=='/cadastro-notas-fiscais')?
                    <CadastroNf handleVerify={handleVerify}  />:null
            }
            {
                (direcionar=='/notas-fiscais')?
                    <NotasFiscais handleVerify={handleVerify}  />:null
            }
            {
                (direcionar=='/login')?
                    <Login handleVerify={handleVerify}  />:null
            }
            {
                (direcionar=='/esqueci-senha')?
                    <EsqueciSenha handleVerify={handleVerify}  />:null
            }
            {
                (direcionar=='/recuperarSenha')?
                    <RecuperarSenha handleVerify={handleVerify}  />:null
            }
            {
                (direcionar=='/logout')?
                    <Logout handleVerify={handleVerify}  />:null
            }
            
        </GoogleReCaptchaProvider>
    )
}
 

export default GeneralCaptcha;