import React from "react";
import Background from "../../assets/black/Bg.png";
import Roleta from "../../assets/black/roleta.png";
import Seta from "../../assets/black/seta.png";
import Botao from "../../assets/black/botao.png";
import "./style.scss";
import { useState } from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import btnRoletaFrente from "../../assets/black/btnRoletaFrente.gif";
import btnRoletaFundo from "../../assets/black/btnRoletaFundo.gif";
import Ganhou from "../../assets/black/ganhou.png";
import Perdeu from "../../assets/black/naoGanhou.png";
import somRoleta from "../../assets/mp3/roleta.mp3";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Realistic from "./Realistic";
import Fireworks from "./Fireworks";
import ApiServices from "../../services/apiServices";
import Storage from "../../services/storage";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { wait } from "@testing-library/react";

export default function RRoleta() {
  const [resultado, setResultado] = useState("");
  const [open, setOpen] = useState(false);
  const [campanhaAtiva, setCampanhaAtiva] = useState(true);
  const [tipoPremio, setTipoPremio] = useState(0);
  const [balao, setBalao] = useState(0);
  const [jogar, setJogar] = useState(99);
  const [ganhou, setGanhou] = useState(false);
  const [msgSemJogadas, setMsgSemJogadas] = useState(false);
  const audio = new Audio(somRoleta);
  audio.preload = "auto";

  const history = useHistory();
  const modalOpen = () => {
    setOpen(true);
  };
  const modalClose = () => {
    setOpen(false);
    setResultado("");
    setJogar(99);
    var roleta = document.querySelector(".roleta");
    roleta.classList.remove("girar");
    roleta.style.transform = `rotate(${0}deg)`;

    var seta = document.querySelector(".seta");
    seta.classList.remove("setaAnimada");
  };

  async function fnGanhouPerdeu() {
    await ApiServices.apiRoletaPostWithToken(
      "participante/cupom/resgatar"
    ).then((res) => {
      //    res = {
      //   balaoId: 0,
      //   cupomId: 0,
      //   cupomECommerceid: 0,
      //   cupomCode: 5,
      //   valor: 0,
      //   viaSoft: false,
      //   numeroDaSorteId: 0,
      //   ganhador: true,
      // };
      fnGrausRolera(res);

      fnConfirmar(res);
      setTipoPremio(res.valor);

      setGanhou(res.ganhador);

      console.log("-------11111------",res.ganhador )
      setTimeout(() => {
        setMsgSemJogadas(res.ganhador);
        console.log("------22222-------",res.ganhador )
      }, 7500);
    });
  }

  async function fnConfirmar(verificaGanhador) {
    await ApiServices.apiRoletaPostWithToken(
      "participante/cupom/confirmar"
    ).then((res) => {
      setTimeout(() => {
        if (verificaGanhador.ganhador == false) {
          setResultado("perdeu");
          modalOpen();
        }
        if (verificaGanhador.ganhador == true) {
          setResultado("ganhou");
          modalOpen();
        }
        fnResumo();
        setJogar(2);
      }, 6000);
    });
  }

  function fnGrausRolera(res) {
    var g = 0;
    if (res.ganhador) {
      // if (res.cupomCode == 0) {g = 0;}     // nao foi dessa vez
      if (res.valor == 5000) {
        g = -30;
      } // ALEXA
      if (res.valor == 2000) {
        g = 120;
      } // caixa de som portatil sem fio
      if (res.valor == 3000) {
        g = 60;
      } // carregador portatil de celular
      if (res.valor == 1000) {
        g = 30;
      } // cafeteira
      if (res.valor == 4000) {
        g = 150;
      } // Fone de ouvido sem fio
    } else {
      g = 0;
    }
    audio.volume = 0.5;
    audio.play();

    var roleta = document.querySelector(".roleta");
    roleta.classList.add("girar");
    roleta.style.transform = `rotate(${g + 3600}deg)`;

    var seta = document.querySelector(".seta");
    seta.classList.add("setaAnimada");
  }

  useEffect(() => {
    ApiServices.apiRoletaGet("participante/campanhaAtiva").then((res) => {
      setCampanhaAtiva(res);
    });
  }, []);

  async function fnResumo() {
    await ApiServices.apiRoletaGetWithToken("participante/resumo").then(
      (res) => {
        //   res = {
        //   balao: 2,
        //   saldo: 20,
        //   acessou: false,
        //   participante: {
        //     Participanteid: 36401,
        //     cpf: "21853019852",
        //     nome: "Ricardo Zuqueto",
        //     celular: "99999999999",
        //     email: "ricardozuqueto@gmail.com",
        //     aceiteRegulamento: true,
        //     idCliente: "00306cd7-7260-45f0-86d2-8cf239350165",
        //     ABCDOG: false,
        //     estadoCliente: "",
        //     Acessou: false,
        //     dataCriacao: "2022-11-04T13:33:06.643",
        //   },
        // };
        if (res.balao < 1) {
          setJogar(0);
        }
        setBalao(res.balao);
      }
    );
  }

  async function fnGanhouNaoGanhou() {
    await ApiServices.apiGet("sorteios/jaganhou").then((res) => {
      setGanhou(res.data);
      setMsgSemJogadas(res.data);
    });
  }

  async function fnConfirmarFake() {
    await ApiServices.apiRoletaPostWithToken(
      "participante/cupom/jaganhou/confirmar"
    ).then((res) => {
      setTimeout(() => {
        setResultado("perdeu");
        fnResumo();

        modalOpen();

        setJogar(2);
      }, 6000);
    });
  }

  async function fnGanhouFake() {
    await ApiServices.apiRoletaPostWithToken(
      "participante/cupom/resgatar/jaganhou"
    ).then((res) => {
      console.log(ganhou);
      var resFake = {
        valor: 0,
        ganhador: false,
      };

      fnGrausRolera(resFake);

      fnConfirmarFake();
    });
  }

  useEffect(() => {
    fnGanhouNaoGanhou();
    fnResumo();
  }, []);

  return (
    <div className="d-flex align-items-center flex-column">
      <Dialog
        open={!campanhaAtiva}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <p className="m-5 p-5">ESTA CAMPANHA NÃO ESTA MAIS ATIVA !</p>
        <button
          className="btn btn-primary"
          onClick={() => history.push("/peca-premiada")}
        >
          VOLTAR
        </button>
      </Dialog>

      <Dialog
        open={open}
        maxWidth={"sm"}
        onClose={modalClose}
        // disableBackdropClick={true}
        // disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <CloseIcon className="botaoFechar" onClick={modalClose} />

        {resultado == "perdeu" && <img src={Perdeu} alt="" />}
        {resultado == "ganhou" && (
          <>
            <img src={Ganhou} alt="" />{" "}
            <div className="numeroDaSorte">
              {tipoPremio == 1000 ? "Cafeteira Espresso TRES Passione" : null}
              {tipoPremio == 2000 ? "Caixa de Som Portátil JBL GO 3" : null}
              {tipoPremio == 3000 ? "Carregador Portátil" : null}
              {tipoPremio == 4000 ? "Fone de ouvido bluetooth JBL" : null}
              {tipoPremio == 5000
                ? "Alexa (Echo Dot) - assistente pessoal virtual"
                : null}
            </div>
          </>
        )}
      </Dialog>

      {resultado == "ganhou" && <Realistic />}
      {resultado == "ganhou" && <Fireworks />}

      {balao < 1 ? (
        <h3 className="legendaRoleta my-5">
          Você não possui jogadas disponíveis!
        </h3>
      ) : msgSemJogadas ? (
        <h3 className="legendaRoleta my-5"> </h3>
      ) : (
        <h3 className="legendaRoleta my-5">
          Você ainda tem {balao < 1 ? "0" : balao} chance(s) para ganhar
        </h3>
      )}

      <div className="tudo">
        <img src={Background} alt="" className="back" />
        <div className="divRoleta">
          {/* <img src={Roleta} alt="" className="roleta" /> */}
          <img src={Roleta} alt="" className="roleta" />
        </div>
        <img src={Seta} alt="" className="seta" />

        {jogar == 99 && balao >= 1 && !ganhou ? (
          <img
            onClick={() => {
              // setBalao(balao -1);
              !ganhou ? fnGanhouPerdeu() : fnGanhouFake();
              setJogar(2);
            }}
            src={btnRoletaFrente}
            alt=""
            className="botaoJogar pointer"
          />
        ) : !msgSemJogadas ? (
          <img src={btnRoletaFundo} alt="" className="botaoJogar" />
        ) : (
          <div className="botaoJogarBloqueio">
            Você já foi contemplado com um brinde.
            <br></br>
            Giros desativados.
          </div>
        )}
      </div>
    </div>
  );
}
